// styles.scss
@use "@angular/material" as mat;
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

@import "../configuration/login";

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@import "../configuration/variables";

@import "../css/platform";

@import "../css/controls";

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import "../project-custom/project";

/*html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}*/