//Import of fonts
@import "../fonts/roboto/stylesheet.css";
@import "../fonts/asap/font-asap.css";

//Main color, sidebar
$primaryColor: white; //#b9e1f6;
//Secondary color, navigation header
$secondaryColor: white; //#3891c1;
//Highlight color of tabs, menu items
$highlightColor: #6fad33; //#e34c4c;//#1a80b6;
$navigationHeaderBackgroundColor: white;
//Background color of section titles
$sectionTitleBackgroundColor: rgba(111, 173, 51, 0.15);
//Body background color
$bodyBackgroundColor: #f8f8f8;
//Main content background color
$mainContentBackground: #f8f8f8;
$heroContainerBackground: #f0f0f0;

//Hero component
$heroComponentValueColor: #6fad33; //#cc922f
$buttonColor: #6fad33;

$popupColor: #6fad33;

$lightBottomBorder: #e5eaed;
$inputBackground: #e7ebee;
$boxContainerBackground: #ccd2d5;
$boxContainerBackgroundII: #f5f7f8;
$underline: #81898c;
$containerHolderColor: #ffffff;
$inputBackgroundII: #e5eaed;
$modulesBackgroundColor: #8f8e8d;
$statusActive: #8bbe23;
$statusIdle: #f7b422;
$statusInActive: #e34c4c;
$mainInputText: #000;
$alertWarning: #fcee1f;
$selectControlText: #333;
$sectionHolderBackground: #f4f8fb;
$matDialogContainerAfter: #6fad33; //#e34c4c;//#1873b1;
$dialogInputPlaceholder: #3d464d;
$selectAllInputAfter: #f8f8f8;
$tableHeader: #6fad33;
$headerTitle: #6fad33;
$matOptionColor: #6fad33; //#e34c4c;//#5bc0eb;
$selectPanelBackground: white;
$reminderRedColor: red;
$reminderGreen: #00ff90;
$nonEditable: #f5f5f5;
$redirectBtns: #6fad33; //#1a80b6;
$boxBackground: #ccc;
$versionDialog: #f9f9f9;
$listSelected: #bddbf5;
$activeColor: #6fad33;
$tooltipGridColumn: #0f0f0f;
$tableChartHeader: #cceeff;

//Text color
$breadCrumbsTextColor: #282828;
$generalParagraphTextColor: #282828;
$mainFieldsColor: #000000;
$textI: #313541;
$linkColor: #0000FF;
$disabledFieldColor: rgba(0, 0, 0, 0.38);

//Regular font family
$regularFontFamily: 'robotoregular';
$italicFontFamily: 'robotoitalic';
$mediumFontFamily: 'robotomedium';
$boldFontFamily: 'robotobold';

$secRegularFontFamily: 'asapregular';
$secItalicFontFamily: 'asapitalic';
$secMediumFontFamily: 'asapmedium';
$secBoldFontFamily: 'asapbold';

//Table colors
$tableHeaderBackgroundColor: rgba(0,0,0,0.08); //'rgba(117, 200, 245, 0.48)';
$tableNBackgroundColor: rgba(0,0,0,0.04); //'rgba(117, 200, 245, 0.2)';

// color of hover text left menu
$leftMenuHoverTextColor: #6fad33;
$loaderColor: #cc922f;

$errorFontColor: red;
