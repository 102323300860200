/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 22, 2018 */



@font-face {
    font-family: 'asapbold';
    src: url('asap-bold-webfont.eot');
    src: url('asap-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('asap-bold-webfont.woff2') format('woff2'),
         url('asap-bold-webfont.woff') format('woff'),
         url('asap-bold-webfont.ttf') format('truetype'),
         url('asap-bold-webfont.svg#asapbold') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'asapbold_italic';
    src: url('asap-bolditalic-webfont.eot');
    src: url('asap-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('asap-bolditalic-webfont.woff2') format('woff2'),
         url('asap-bolditalic-webfont.woff') format('woff'),
         url('asap-bolditalic-webfont.ttf') format('truetype'),
         url('asap-bolditalic-webfont.svg#asapbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'asapitalic';
    src: url('asap-italic-webfont.eot');
    src: url('asap-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('asap-italic-webfont.woff2') format('woff2'),
         url('asap-italic-webfont.woff') format('woff'),
         url('asap-italic-webfont.ttf') format('truetype'),
         url('asap-italic-webfont.svg#asapitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'asapmedium';
    src: url('asap-medium-webfont.eot');
    src: url('asap-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('asap-medium-webfont.woff2') format('woff2'),
         url('asap-medium-webfont.woff') format('woff'),
         url('asap-medium-webfont.ttf') format('truetype'),
         url('asap-medium-webfont.svg#asapmedium') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'asapmediumitalic';
    src: url('asap-mediumitalic-webfont.eot');
    src: url('asap-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('asap-mediumitalic-webfont.woff2') format('woff2'),
         url('asap-mediumitalic-webfont.woff') format('woff'),
         url('asap-mediumitalic-webfont.ttf') format('truetype'),
         url('asap-mediumitalic-webfont.svg#asapmediumitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'asapregular';
    src: url('asap-regular-webfont.eot');
    src: url('asap-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('asap-regular-webfont.woff2') format('woff2'),
         url('asap-regular-webfont.woff') format('woff'),
         url('asap-regular-webfont.ttf') format('truetype'),
         url('asap-regular-webfont.svg#asapregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'asapsymbol';
    src: url('asap-symbol-webfont.eot');
    src: url('asap-symbol-webfont.eot?#iefix') format('embedded-opentype'),
         url('asap-symbol-webfont.woff2') format('woff2'),
         url('asap-symbol-webfont.woff') format('woff'),
         url('asap-symbol-webfont.ttf') format('truetype'),
         url('asap-symbol-webfont.svg#asapsymbol') format('svg');
    font-weight: normal;
    font-style: normal;

}