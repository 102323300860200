.example-tree-invisible {
  display: none;
}

.example-tree {

  ul,
  li {
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: none;
  }
}

.mat-tree .mat-icon-button {
  display: block !important;
}

.btn-redirect{
  background-image: url('../css/images/platform/icon-collapse@3x.png');
}

.linked-data-dialog {
  overflow-x: hidden;
  min-height: 300px;
  max-height: 600px;

  .compare-label {
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 15px;
  }

  .compare-btns {
    padding-top: 20px;
    padding-bottom: 20px;

    button {
      object-fit: contain;
      border-radius: 3px;
      border: solid 1px $buttonColor;
      background-color: transparent;
      font-family: "asapregular";
      font-size: 15px;
      letter-spacing: 1.3px;
      color: $buttonColor;
      text-transform: uppercase;
      cursor: pointer;
    }

    .compare-cancel-btn {
      margin-left: 20px;
    }
  }

  .simple-changes {

    .prop-val {
      margin-bottom: 10px;
    }

    .prop-val-name {
      font-weight: bold;
      margin-bottom: 5px;
    }

    .simple-change-before {
      display: inline-block;
    }

    .simple-change-after {
      padding-left: 5px;
      display: inline-block;
    }
  }
}

.confirmation-dialog {
  min-height: 170px;
}

.m2o-po-vseh-input {
  border-radius: 3px;
  border: $boxContainerBackground solid 1px;
  height: 25px;
  margin-top: 6px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $textI;
  vertical-align: middle;
  margin-bottom: 22px;
  background-color: transparent;
  z-index: 99;
  cursor: pointer;
  display: block;
  outline-color: $boxContainerBackground !important;
}

.control-help-m2o:hover+ {

  .tooltip-text-searchfield,
  .tooltip-text-povseh {
    visibility: visible;
    margin-top: 10px;
  }
}

.search-link:hover+.tooltip-text-searchm2o {
  visibility: visible;
}

.linkicon:hover+.tooltip-text-link {
  visibility: visible;
  margin-top: 10px;
}

.control-help-m2o {
  display: inline-block;
  background-image: url("/assets/css/images/platform/help-icon.png");
  background-size: 15px 15px;
  background-repeat: no-repeat;
  float: right;
  width: 15%;
  height: 15px;
  background-position: center;
  cursor: pointer;
}

.from-to-label {
  margin-right: 10px;
  font-family: "asapregular" !important;
}

.grid-table .table th {
  font-weight: bold;
}

tbody tr td:last-child {
  padding: 0;
  text-align: center;
  margin: auto;
  padding-right: 0 !important;
}

input.sem-text-box {
  margin-bottom: 12px !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-infix {
  border-top: 0 !important;
}

p {
  font-family: $regularFontFamily;
}

.grid-table-row {
  cursor: pointer;
}

.table-footer {
  .left {
    padding-right: 25px !important;
  }

  p {
    padding-right: 5px;
  }
}

.info img {
  left: 70px;
  top: 40px;
}

.added-search-fields {

  ul,
  li {
    display: block !important;
  }
}

.search-control .wrapper {
  padding-bottom: 15px !important;
}

.info img {
  position: relative;
  height: 12px;
  width: 12px;
}

.left-container {
  width: 35%;
  display: table-cell;
  left: 20px;
  position: relative;
  max-width: 275px;

  label {
    font-family: $regularFontFamily;
    font-size: 15px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $mainFieldsColor;
    text-transform: none !important;
    float: left;
  }

  input {
    width: 277px;
    margin-top: 15px;
    height: 25px;
    border-width: 1px;
    border-radius: 3px;
    padding-left: 10px;
    border: $underline solid 0.5px;
  }

  .info {
    float: left;
    width: 100%;
  }
}

.info div {
  float: right;
  position: relative;
  top: -19px;
  right: 10px;
}

.all-search-fields {
  float: left;
  width: 100%;
  height: 335px !important;

  ul {
    text-align: left;
    width: 100%;
    border-radius: 3px;
    border: $underline solid 0.5px;
    margin-top: 15px;
    height: 100%;
  }
}

.added-search-fields ul {
  text-align: left;
}

.all-search-fields ul li,
.added-search-fields ul li {
  font-family: $regularFontFamily;
  font-size: 12px;
  font-weight: normal;
  height: 17px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.1px;
  padding-left: 9px;
  color: $mainFieldsColor;
  cursor: pointer;
  width: fit-content;
  white-space: nowrap;
}

.all-search-fields ul> :first-child,
.added-search-fields ul> :first-child {
  margin-top: 10px;
}

.all-search-fields ul> :last-child,
.added-search-fields ul> :last-child {
  margin-bottom: 10px;
}

.all-search-fields ul,
.added-search-fields ul {
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
}

.all-search-fields ul li {

  &.ng-star-inserted.active,
  &:hover {
    background-color: $underline;
    width: max-content;
  }
}

.added-search-fields ul li {

  &.ng-star-inserted.active,
  &:hover {
    background-color: $underline;
    width: max-content;
  }
}

.action-buttons {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 80px;
  margin: auto;
  height: 60px;
  width: 24px;
}

.right-container {
  display: table-cell;
  width: 40%;
  position: relative;
  right: 16px;
  vertical-align: top;

  label {
    font-family: $regularFontFamily;
    font-size: 15px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $mainFieldsColor;
    text-transform: none !important;
    position: relative;
  }
}

.action-buttons {

  .add,
  .remove {
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
  }
}

.search-control \: {
  &:-webkit-scrollbar {
    width: 27px;
  }

  &:-webkit-scrollbar-thumb {
    border: transparent solid 12px;
    background-clip: padding-box;
    background-color: rgba(0, 0, 0, 0.15);
  }

  &:-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &:-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

.footer {
  width: 100%;
  height: 25px;
}

.left-footer {
  width: 113px;
  height: 24px;
  border-radius: 3px;
  border: $matDialogContainerAfter solid 1px;
  font-family: $regularFontFamily;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.9px;
  text-align: center;
  color: $matDialogContainerAfter;
  text-transform: uppercase;
  left: 20px;
  top: 52px;
  position: relative;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 2px;

  span {
    position: relative;
    top: -16px;
  }
}

.right-footer {
  display: table;
  float: right;
  top: 30px;
  position: relative;

  .apply,
  .save {
    height: 25px;
    border-radius: 3px;
    font-family: $regularFontFamily;
    font-size: 11px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: $matDialogContainerAfter;
    border: $matDialogContainerAfter solid 1px;
    cursor: pointer;
    position: relative;
    right: 20px;
    text-transform: uppercase;
  }

  .cancel {
    height: 25px;
    border-radius: 3px;
    font-family: $regularFontFamily;
    font-size: 11px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: $matDialogContainerAfter;
    border: $matDialogContainerAfter solid 1px;
    cursor: pointer;
    position: relative;
    right: 20px;
    border: $underline solid 1px;
    color: $underline;
    width: 75px;
    text-transform: uppercase;
  }

  .save,
  .apply {
    width: 60px;
    margin-right: 20px;
  }

  .cancel,
  .save,
  .apply {
    display: inline;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .cancel span {
    padding-left: 16px;
    padding-right: 16px;
  }

  .save span,
  .apply span {
    padding-left: 13px;
    padding-right: 13px;
  }
}

.sem-text-box {
  z-index: 0 !important;
}

.added-search-fields li:hover .searchfield-tooltip-text,
.all-search-fields li:hover .searchfield-tooltip-text {
  visibility: visible;
}


.added-search-fields {
  border-radius: 3px;
  border: $underline solid 0.5px;
  height: 375px;
  position: relative;
  width: 274px;
  display: block;
  margin-top: 15px;
}

.added-search-fields-div {
  display: block;
}

.example-list {
  width: 500px;
  max-width: 100%;
  border: $boxBackground solid 1px;
  min-height: 60px;
  display: block;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

.example-box {
  padding: 20px 10px;
  border-bottom: 1px solid $boxBackground;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
}

.sem-tree-invisible {
  display: none;
}

.sem-tree {
  overflow-y: scroll;
  max-height: 450px;

  ul {
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: none;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
  }

  li {
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: none;
  }
}

.searchin-form-field {
  width: 100px;
}

.mat-mdc-form-field {
  display: inline;
  position: static;
  text-align: start;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-wrapper {
  border-bottom: 1px solid $boxContainerBackground;
}

.mat-tree-node a:hover {
  background-color: $sectionHolderBackground;
}

.SemTextBoxControl {}

input:focus {
  border: $boxContainerBackground solid 1px !important;
}

.version-dialog {
  padding: 0;
  background-color: $versionDialog;
  position: relative;
  height: 100%;
}

.ver-dialog-content {
  position: absolute;
  top: 120px;
  left: 24px;
  right: 24px;
  bottom: 24px;
}

.version-dialog * {
  font-family: $regularFontFamily;
}

.header {
  position: absolute;
  height: 100px;
  top: 24px;
  left: 24px;
  right: 24px;

  h3 {
    font-family: $mediumFontFamily;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 2px;
    color: $mainFieldsColor;
    text-transform: uppercase;
    position: absolute;
    bottom: 28px;
  }

  .header-title label {
    font-size: 11px;
    margin-left: 3px;
  }

  .close-icon {
    position: absolute;
    top: 10px;
    right: 0;
    cursor: pointer;
  }
}

.search-form {
  margin-bottom: 15px;
}

.comparision {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.version-list {
  flex: 30%;
  border-top: 1px solid transparent;
  overflow: auto;
}

.selected-version {
  flex: 70%;
  max-height: 600px;
  border: $boxContainerBackground solid 1px;
}

.comparision {
  .version-list table {
    width: 100%;

    thead th,
    tbody td {
      padding: 16px 5px 16px 5px;
    }
  }

  .selected-version {
    table {
      width: 100%;
      height: 100%;

      thead th {
        padding: 16px 5px 16px 5px;
      }

      tbody td {
        padding: 12px 5px 12px 5px;
      }
    }

    .cur-ver-td {
      height: 100%;
      overflow: hidden;
      padding: 0 !important;
    }
  }
}

.scroll {
  overflow: auto;
  height: 100%;
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.curr-ver-title {
  padding-top: 25px;
  padding-bottom: 25px;
}

button {
  &.restore {
    width: 121px;
    height: 35px;
    object-fit: contain;
    border-radius: 3px;
    background-color: $highlightColor;
    border: $highlightColor solid 1px;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.3px;
    color: white;
    text-transform: uppercase;
    margin-right: 25px;
    cursor: pointer;
  }

  &.cancel {
    width: 87px;
    height: 35px;
    object-fit: contain;
    border-radius: 3px;
    border: $highlightColor solid 1px;
    background-color: white;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.3px;
    color: $highlightColor;
    text-transform: uppercase;
    cursor: pointer;
  }
}

.loading-comparision {
  text-align: center;
  padding: 24px;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  margin-top: 22px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  font-family: Helvetica;
  font-weight: normal !important;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
}

.checkmark {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 4px;
  border: $boxContainerBackground solid 1px;
}

.container {
  &:hover input~.checkmark {
    border-color: $matOptionColor;
  }

  input:checked~.checkmark {
    background-color: $matOptionColor;
    border-color: $matOptionColor;
  }
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container {
  input:checked~.checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 6px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: white solid;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

input.sem-text-box {
  border-radius: 3px;
  border: $boxContainerBackground solid 1px;
  height: 40px;
  width: 100%;
  margin-top: 6px;
  font-family: $regularFontFamily;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $textI;
  vertical-align: middle;
  margin-bottom: 22px;
  background-color: transparent;
  z-index: 99;
  cursor: pointer;
  display: block;
}

sem-dynamic-search-field {
  width: 100%;
  display: inline-block;
}

.sem-dynamic-field-content {
  margin-bottom: 15px;
  width: 100%;

  div {
    width: 70%;

    >* {
      width: 233px;
    }
  }

  label {
    display: inline-block;
    font-family: $regularFontFamily;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.92;
    letter-spacing: 0.8px;
    color: $textI;
    margin-right: 16px;
    vertical-align: middle;
  }

  .autocomplete-span:after {
    top: 11px;
  }
}

.search-range-item span {
  display: inline-block;
  font-family: $regularFontFamily;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.92;
  letter-spacing: 0.8px;
  color: $textI;
  margin-right: 16px;
  vertical-align: middle;
}

.sem-dynamic-field-content .search-range-item {
  vertical-align: middle;
  display: inline-block;
  position: relative;
  padding-right: 40px;

  sem-date-picker {
    display: inherit;
  }

  span {
    position: relative;
    margin-right: 5px;
    margin-left: 5px;
  }

  input {
    width: 50%;
  }
}

.dynamic-text-box {
  display: inline-block;
  vertical-align: middle !important;
  position: relative;
  padding-right: 40px;
}

.sem-dynamic-textbox {
  display: inline-block;
}

.sem-dynamic-checkbox {
  display: inline;
  padding-right: 40px !important;
  position: relative;
  left: -10px;
}

.search-item .sem-dynamic-checkbox {
  left: 0px !important;
}

input[type="checkbox"] {
  width: 20px !important;
}

.search-range-item input {
  height: 25px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.sem-dynamic-textbox input {
  height: 25px !important;
  position: relative;
}

.search-range-item .sem-date-picker {
  background: none !important;
}

.dynamic-checkbox {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin: 0 auto;
  background: $containerHolderColor;
  border: $lightBottomBorder solid 1px;
  border-radius: 5px;
  cursor: pointer;
  top: 7px;
}

.dynamic-checkbox-image-false {
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  width: 13px;
  position: relative;
  height: 13px;
  top: 6px;
  left: 6px;
}

.dynamic-checkbox-image-true {
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  width: 15px;
  position: relative;
  height: 15px;
  top: 8px;
  left: 5px;
}

.sem-dynamic-dropdown {
  display: inline-block;
  vertical-align: middle;
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    border: none !important;
  }

  .mdc-line-ripple{
    display: none;
  }
  .autocomplete-span:after {
    top: 15px !important;
}

  .mat-mdc-text-field-wrapper{
    padding: 0 !important;
  }

  .mat-mdc-form-field-infix{
    min-height: auto !important;
  }

  input {
    padding-left: 10px !important;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-infix {
    padding-left: 0 !important;
  }

  .mat-mdc-form-field-focus-overlay,.mat-mdc-form-field-subscript-wrapper{
    display: none;
  }
}

.file-explorer {
  z-index: 9999;
  background-color: white;
  box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.5);
  font-family: $mediumFontFamily;
  height: calc(100% - 50px);
}

.inner-wrap {
  position: relative;
  width: 100%;
  height: 100%;
}

.top-bar {
  height: 50px;
  background-color: white;
  width: 100%;
  border-bottom: 1px solid $lightBottomBorder;
  z-index: 200;
}

.bottom-bar {
  height: 50px;
  background-color: white;
  width: 100%;
  border-top: 1px solid $lightBottomBorder;
  z-index: 200;
  display: inline-table;
  table-layout: fixed;
  position: relative;

  >* {
    display: table-cell;
    vertical-align: middle;
    box-sizing: border-box;
    height: 50px;
  }

  >div:first-of-type,
  >div:last-of-type {
    width: 196px;
  }

  >div:first-of-type {
    border-right: 1px solid $lightBottomBorder;
  }

  >div:last-of-type {
    border-left: 1px solid $lightBottomBorder;
  }

  .submit-cmds {

    button {
      background-color: white;
      text-transform: uppercase;
      border-radius: 3px;
      font-family: $mediumFontFamily;
      font-size: 11px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: center;
      height: 25px;
      padding-left: 15px;
      padding-right: 15px;
      cursor: pointer;
    }

    .save {
      border: $matDialogContainerAfter solid 1px;
      color: $matDialogContainerAfter;
      margin-right: 15px;
    }

    .cancel {
      border: $underline solid 1px;
      color: $underline;
    }
  }

  .drag-info {
    line-height: 40px;
    border-right: 1px solid $lightBottomBorder;
    width: 185px;
    padding-left: 10px;
    text-align: left;

    span {
      font-family: $secItalicFontFamily;
      font-size: 11px;
      font-weight: normal;
      font-style: italic;
      font-stretch: normal;
      line-height: 50px;
      letter-spacing: normal;
      color: $mainFieldsColor;
      height: 50px;
    }
  }
}

.file-preview {
  /*position: absolute;
  top: 50px;
  bottom: 40px;
  left: 195px;
  right: 190px;*/
  background-color: rgba(229, 234, 237, 0.5);
  width: 100%;
}

.file-list {
  //position: absolute;
  //width: 195px;
  //left: 0;
  //top: 50px;
  //bottom: 0;
  background-color: white;
  border-right: 1px solid $lightBottomBorder;
  width: 195px;

  .add-file {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  ul {
    padding: 0 0 10px 0;

    li {
      font-size: 12px;
      text-align: left;
      position: relative;
      padding-left: 21px;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      font-weight: 600;
      padding-top: 5px;
      padding-bottom: 7px;
      box-sizing: border-box;
      width: 100%;

      &.selected {
        background-color: rgba(26, 128, 182, 0.15);
      }

      &.search-notfound {
        display: none !important;
      }

      >* {
        display: inline-block;
        vertical-align: middle;
      }

      p {
        padding-left: 15px;
      }

    }
  }
}

.explorer-body {
  display: inline-table;
  width: 100%;
  table-layout: fixed;
  height: calc(100% - 50px);

  >div {
    display: table-cell;
    vertical-align: top;
    height: 100%;
  }
}

.file-info {
  /*position: absolute;
  width: 190px;
  right: 0;
  top: 50px;
  bottom: 40px;*/
  background-color: white;
  border-left: 1px solid $lightBottomBorder;
  width: 195px;

  ul {
    padding: 0;
    margin: 0;

    li {
      padding-left: 20px;
      padding-right: 20px;
      text-align: left;
      margin-bottom: 10px;

      label {
        font-size: 10px;
        text-align: left;
        margin-bottom: 3px;
        font-weight: 100;
      }

      input {
        width: 140px;
        border-radius: 3px;
        border: $boxContainerBackground solid 1px;
        padding: 5px !important;
        margin: 4px 0 0 0 !important;
        font-size: 12px;
        font-weight: 600;
        outline: none;
        font-family: $secRegularFontFamily;
      }
    }
  }
}

.input-notedit {
  width: 140px;
  border-radius: 3px;
  border: $boxContainerBackground solid 1px;
  padding: 5px !important;
  margin: 4px 0 0 0 !important;
  font-size: 12px;
  font-weight: 600;
  outline: none;
  font-family: $secRegularFontFamily;
}

.sec-title {
  font-family: $mediumFontFamily;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $mainFieldsColor;
  text-align: left;
  padding: 19px 10px 15px 20px;

  .toggle-holder {
    float: right;
  }

  p {
    font-weight: 600;
  }

  >* {
    display: inline-block;

    >* {
      display: inline-block;
      vertical-align: middle;
    }

    >div:first-of-type {
      background-image: url('../css/images/platform/img-icon-gray@3x.png');
    }

    >div:last-of-type {
      background-image: url('../css/images/platform/list-icon@3x.png');
    }

    div {
      background-size: contain;
      background-position: center;
      height: 12px;
      width: 12px;
      margin: 0 7px 0 7px;
      cursor: pointer;
    }
  }

  p {}
}

.div-separator {
  height: 25px !important;
  width: 2px !important;
  background-color: #e5eaed;
  vertical-align: middle;
}

.title {
  left: 0;
  top: 0;
  width: 195px;
  height: 50px;
  border-right: 1px solid $lightBottomBorder;

  h4 {
    font-family: $mediumFontFamily;
    font-size: 17px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 2.5px;
    color: $textI;
    line-height: 50px;
    text-align: left;
    padding-left: 49px;
    text-transform: uppercase;
  }

  svg {
    position: absolute;
    left: 21px;
    top: 50%;
    margin-top: -7px;
  }
}

.explorer-window-cmds {

  text-align: right;
  padding-right: 20px;
  box-sizing: border-box;

  svg {
    cursor: pointer !important;
    margin-left: 10px;
  }
}

.preview-cmds {
  text-align: center;

  ul {
    list-style: none;
    margin-top: 10px;

    li {
      display: inline-block;
      line-height: 25px;
      padding-left: 14px;
      padding-right: 14px;
      border-left: 1px solid $lightBottomBorder;
      cursor: pointer;

      &:first-child {
        border-left: 0;
      }

      &.cmd-search {
        position: relative;
        width: 145px;
        padding-left: 0;
        border-left: 0;
        margin-left: 10px;

        input {
          width: 145px;
          border-radius: 3px;
          border: $boxContainerBackground solid 1px;
          line-height: 25px;
          padding-left: 8px;
        }

        svg {
          position: absolute;
          right: 23px;
          top: 8px;
        }
      }
    }
  }
}

.preview-wrap {
  /*position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  overflow: hidden;
  overflow-y: auto;*/
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 25px;
  text-align: center;

  img {
    max-width: 100%;
    max-height: 80vh;
    margin: auto;
  }

  .pr-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .pr-text {
    text-align: left;
    font-weight: lighter;
    font-size: 12px;
    width: 100%;
    height: 100% !important;
    border: 0;
    margin: 0;
    padding: 0;
    background-color: transparent;
    position: absolute;
    left: 0;
    right: -20px;
    top: 0;
    bottom: 0;
  }
}

.file-cmds {

  * {
    vertical-align: middle;
  }

  text-align: center;

  .div-separator {
    margin: 0 17px 0 17px;
  }

  .add-file {
    float: left;
    padding-left: 24px;
    margin-top: 1px;
  }

  .man-file {
    margin: auto;
    text-align: center;

    >* {
      display: inline-block;
    }
  }

  >div {
    display: inline-block;
  }

  ul {
    list-style: none;

    li {
      display: inline-block;
      line-height: 25px;
      padding-left: 14px;
      padding-right: 14px;
      border-left: 1px solid $lightBottomBorder;
      cursor: pointer;

      &:first-child {
        border-left: 0;
      }
    }
  }
}

.no-marks {
  font-family: $secRegularFontFamily;
  font-size: 11px;
  color: $textI;
  padding: 11px 0 1px 0;
}

.annotations-view {
  margin: 0 20px 10px 20px;
}

.file-picker-container-button {
  border: $lightBottomBorder solid 1px;
  text-transform: uppercase;
  height: 25px;
  padding: 0 10px 0 10px;
  line-height: 25px;
  margin-left: 8px;
  border-radius: 3px;
  display: inline-block;
  font-family: 'asapregular';
  font-size: 12px;
  font-stretch: normal;
  letter-spacing: 0.8px;
  color: $underline;
}

.preview-wrap .left {
  background-image: none !important;
}

#chooseFile {
  display: none;
}

.file-picker-selected-files {
  table {
    th {
      padding-right: 5px;
      height: 30px;
      vertical-align: middle;
      font-family: 'asapmedium';
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $textI;
      border-right: 1px solid $lightBottomBorder;
    }

    td {
      padding-left: 17px;
      padding-right: 20px;
      height: 30px;
      vertical-align: middle;
      font-family: 'asapregular';
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $textI;
      position: relative;
      border-right: 1px solid $lightBottomBorder;
    }
  }

  .icon>div {
    left: -5px !important;
  }

  .remove-file {
    cursor: pointer;
  }
}

.linked-files-comp {
  th {
    border-bottom: 1px solid $lightBottomBorder;
  }

  td {
    border-bottom: 0 !important;
    border-top: 0 !important;
  }

  tr {
    border-bottom: 0 !important;
    cursor: pointer;
  }

  p {
    margin: 0 !important;
  }
}

.add:hover .fileupload-add,
.icon-collapse-condense:hover .fileupload-colapse,
.expand .expand-icon:hover + .fileupload-expand,
.download:hover .fileupload-download,
.delete:hover .fileupload-delete,
.setdefault-img:hover .fileupload-set-as-default{
  visibility: visible;
}

.fileupload-download,
.fileupload-search {
  top: 20px;
}

.fileupload-delete {
  top: 25px;
  left: -50px;
}

.fileupload-expand {
  top: 45px;
}

.fileupload-colapse {
  top: 20px;
  right: -50px;
}

.search:hover .fileupload-search {
  visibility: visible;
  left: -45px;
  top: 25px;
}

.collapsed-side-form {
  width: 150px !important;
  margin-left: 0 !important;
}

.p-file-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

.search-loader {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  z-index: 9999999;
  top: 0;
  left: 0;

  img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 100px;
  }
}

.axis {
  font: 10px sans-serif;

  path,
  line {
    fill: none;
    stroke: $mainFieldsColor;
    shape-rendering: crispEdges;
  }
}

.bar {
  fill: steelblue !important;

  &:hover {
    fill: brown !important;
  }
}

.group-repeater-container {
  padding-left: 2px;
  padding-bottom: 15px;
  display: table-cell;
}

.grouped-repeater-table-data {
  height: 33px !important;

  .mat-mdc-text-field-wrapper{
      display:inline-table !important;
  }

  .tbody-close-td {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}

.group-repeater-container .content-table {
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);

  td,
  th {
    padding: 12px;
  }

  .table-data:hover {
    cursor: pointer !important;
    background-color: rgba(24, 115, 177, 0.1);
  }
}

.repeater-checkboxes table {
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);
  border: rgba(26, 128, 182, 0.25) solid 1px;

  td,
  th {
    font-family: $regularFontFamily;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $textI;
    padding: 12px 12px 12px 12px;
  }
}

.checkbox-repeater {
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  margin: 0 auto;
  background: $containerHolderColor;
  border: $underline solid 0.5px;
  border-radius: 2px;
  float: left;
  cursor: pointer;

  >input[type="checkbox"] {
    visibility: hidden;
  }
}

.checkbox-repeater-checked {
  background-image: url("/assets/css/images/platform/ikona-kljukica.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position-x: 2px;
  background-position-y: 3px;
}

.repeater-checkboxes table {
  margin-bottom: 5px;
}

.header td:nth-last-child(2) {
  border-right: none;
}

.close-td {
  border-left: none;
}

.repeater-checkboxes {
  display: table-cell;
  padding-left: 30px;
  position: relative;
  top: 2px;
  padding-bottom: 15px;

  .close {
    cursor: pointer;
    background-image: url("/assets/css/images/platform/uncheck-icon.png");
    background-size: auto;
    background-repeat: no-repeat;
    background-position-x: 2px;
    background-position-y: 3px;
    height: 15px;
    width: 15px;
  }
}

.grouped-repeater-table-data .close {
  cursor: pointer;
  background-image: url("/assets/css/images/platform/uncheck-icon.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position-x: 2px;
  background-position-y: 3px;
  height: 15px;
  width: 15px;
}

.table-data td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.sem-image-container .actual-image {
  width: 90px;
  height: 90px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  background-position: center;
}

.o2m-additional-actions-tooltip {
  top: -50px;
  right: -20px;
}

.inner-manipulate-section:hover .o2m-additional-actions-tooltip {
  visibility: visible;
}

.listing-select-item {
  font-family: asapregular !important;
  font-size: 12px !important;
  padding-bottom: 15px !important;
}

.additional-custom-data-item {
  display: inline-block;
  width: 30%;
  margin-right: 3%;
  display: block;

  .sem-drop-down-additional-custom-data-item mat-form-field {
    width: 100% !important;
  }
}

.related-data-label-container p {
  font-family: $mediumFontFamily;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: $textI;
}

.related-data-table-container {
  border-top: 1px solid $lightBottomBorder;
  margin-top: 20px;
  display: table;
}

.related-data-table {
  thead th {
    font-family: $regularFontFamily;
    font-size: 15px;
    font-weight: 700;
    font-style: normal;
    padding: 10px 50px 10px 50px;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $textI;
  }

  tbody td {
    border-top: none !important;
    padding: 10px 50px 10px 50px;
    border-bottom: none !important;
  }
}

.item-link-img {
  cursor: pointer;
  position: relative;
  top: 4px;
}

.related-data-table tbody td {
  &:not(:first-child):not(:last-child) {
    border-left: 1px solid $boxContainerBackground;
    border-right: 1px solid $boxContainerBackground;
  }

  &:first-child {
    border-left: none !important;
    border-right: 1px solid $boxContainerBackground;
  }

  &:last-child {
    border-right: none !important;
    border-left: 1px solid $boxContainerBackground;
  }
}

.related-data-pagination {
  text-align: center;
  margin-top: 10px;

  p {
    display: inline;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 13px;
    cursor: pointer;
  }
}

.page-left,
.page-right {
  cursor: pointer;
}

input.sem-text-box:hover+.sem-text-box-tooltip {
  visibility: visible;
}

.label-regex-input {
  font-size: 14px;
  margin-bottom: 2px;
  margin-right: 2px;
  margin-left: 2px;
  z-index: 99;
  display: inline-block;
  vertical-align: middle;
}

.segmentsDiv {
  display: table-cell !important;
  vertical-align: middle;
}

.text-align-center {
  text-align: center;
}

.table-data-control-table {
  border: $boxContainerBackground solid 1px;
}

.table-data-table-manage {
  height: 15px !important;
  position: relative;
  top: 10px;
  left: -20px;

  p {
    font-size: 15px !important;
  }
}

.table-data-control-table td {
  cursor: pointer;
}

.table-data-pagination .active {
  color: $activeColor;
}

.table-data-control-table th,
.table-data-control-table td {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
}

.number-of-pages-setting {
  label {
    font-family: $regularFontFamily;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.92;
    letter-spacing: 0.8px;
    color: $textI;
    padding-right: 10px;
  }

  select {
    appearance: none;
    border: $lightBottomBorder solid 1px;
    border-radius: 5px;
    height: 25px;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $textI;
    font-family: $regularFontFamily;
    padding-left: 5px;
    cursor: pointer;
    padding-right: 35px;
    background: url("/assets/images/platform/arrow-down-s.png") 93% no-repeat;

    &::-ms-expand {
      display: none;
    }
  }
}

.table-container .table-controls .number-of-pages-setting {

  label,
  .select-items {
    display: table-cell;
    cursor: pointer;
  }
}

.drop-down-sorting-container {
  width: 165px;
  height: 100%;
  object-fit: contain;
  box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);
  background-color: white;
  position: absolute;
  height: 72px;
  z-index: 1;
  left: -150px;
  margin-top: 10px;
}

.table-data-table-controls {
  position: relative;
  top: -10px;
}

.drop-down-sorting-container {
  .drop-down-sorting-item {
    cursor: pointer;
    width: 100%;
    height: 36px;
    object-fit: contain;

    &:hover {
      background-color: rgba(91, 192, 235, 0.15);
    }
  }

  p {
    line-height: 36px !important;
    font-family: $regularFontFamily;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $textI;
  }
}

.drop-down-sorting-item img {
  margin-left: 15px;
  margin-right: 15px;
}

.data-grid-row-tooltip-text {
  left: 500px;
}

.grid-table-row:hover .data-grid-row-tooltip-text {
  visibility: visible;
}

.table-loader {
  position: absolute !important;
}

.table-data-pagination {

  .right,
  .left,
  .right-double,
  .left-double {
    padding-left: 25px;
  }
}

textarea.sem-text-area {
  border-radius: 3px;
  border: $boxContainerBackground solid 1px;
  height: 80px;
  width: 100%;
  margin-top: 6px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $textI;
  vertical-align: middle;
  margin-bottom: 12px;
  background-color: transparent !important;
  z-index: 99;
  cursor: pointer;
  outline: none !important;
  padding: 12px 10px 10px 16px;
}

sem-text-area {
  position: relative;
}

.ta-counter {
  position: absolute;
  right: 0;
  top: 5px;
}

.instruction-text {
  position: relative;
  top: 10px;
  font-size: 11px;
  font-style: italic;
}

.sem-text-box-tooltip {
  visibility: hidden;
  color: black;
  text-align: center;
  border-radius: 6px;
  z-index: 1;
  background-color: grey;
  font-size: 15px;
  width: auto;
  padding: 10px 10px;
  position: absolute;
}

input.sem-text-box:hover+.sem-text-box-tooltip {
  visibility: visible;
}

.form-title-breadcrumb {
  white-space: nowrap;
  display: inline-block;
}

.breadcrumb {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

h1 {
  font-family: $regularFontFamily;
  font-size: 20px;
  text-transform: uppercase;
  padding-left: 35px;
  line-height: 60px;
}

.breadcrumb-header-nav {
  display: inline-block;
}

header .wrapper {
  .notification-center-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("/assets/css/images/platform/alarm-copy@3x.png");
    background-size: 23px 23px;
    width: 58px;
    cursor: pointer;
    position: relative;
    display: table-cell;
    vertical-align: middle;
    height: 48px;
    padding-left: 10px;
    padding-right: 37px;
  }

  .notification-center-dot-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("/assets/css/images/platform/not-dot@3x.png");
    background-size: 23px 23px;
    width: 58px;
    cursor: pointer;
    position: relative;
    display: table-cell;
    vertical-align: middle;
    height: 48px;
    padding-left: 10px;
    padding-right: 37px;
  }
}

.tooltip-text-language {
  top: 5px;
  right: 10px;
}

.language-picker:hover .tooltip-text-language {
  visibility: visible;
}

header .wrapper .help-icon {
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/assets/css/images/platform/email-icon.png");
  background-size: 23px 23px;
  width: 58px;
  cursor: pointer;
  position: relative;
  display: table-cell;
  vertical-align: middle;
  height: 48px;
  padding-left: 40px;
}

.tooltip-text-user {
  top: 35px;
  left: -45px;
}

.user:hover .tooltip-text-user {
  visibility: visible;
}

.header-menu-custom-node:hover .tooltip-text-custom-node {
  visibility: visible;
  position: absolute;
  right: -30px;
  top: 40px;
}

.custom-header-menu-container {
  display: table-cell;
}

.custom-header-node-children {
  box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  box-sizing: border-box;

  .item-text {
    padding-top:15px;
    display: inline-block;
    height: 45px;
    padding-left: 12px;
    box-sizing: border-box;
    text-align: center;
    white-space: nowrap; /* Prevent text wrapping */

    p {      
      vertical-align: middle;
      font-family: 'asapregular';
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $textI;
      margin: 0;
      width:fit-content;
      margin-right:20px;
    }
  }
}

.child-item {
  border-left: 4px solid transparent;
}

.custom-header-node-children .child-item:hover {
  border-left-color: $activeColor !important;
  background-color: rgba(111, 173, 51, 0.15);
}

.user .top-menu-item p {
  margin: 0 !important;
}

.main-node-item:hover .tooltip-text {
  visibility: visible;
  left: -15px;
  top: 50px;
}

.left-menu-logo-img:hover .tooltip-text {
  visibility: visible;
  left: -40px;
  top: 50px;
}

.sub-sidebar {
  margin-left: 20px;
}

.img-holder-sub-sidebar img {
  height: 40px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.SemDateBoxControl {}

.grid-table .table th {
  font-weight: bold;
}

tbody tr td:last-child {
  padding: 0;
  text-align: center;
  margin: auto;
  padding-right: 0 !important;
}

.grid-table .table-controls .arrange .icon {
  position: relative !important;
}

.number-of-pages-setting select::-ms-expand {
  display: none;
}

input.sem-text-box {
  margin-bottom: 12px !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-infix {
  border-top: 0 !important;
}

.search-fields-settings label {
  background: none !important;
}

.grid-fields {
  margin-top: 15px !important;
}


/*.sorting-container-used {
  border: $textI solid 1px;
  padding-left: 2px;
  padding-right: 2px;
}*/

.drop-down-sorting-container {
  width: 135px;
  height: 100%;
  object-fit: contain;
  box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);
  background-color: white;
  position: absolute;
  height: 72px;
  z-index: 1;
  left: -70px;
  margin-top: 10px;

  .drop-down-sorting-item {
    cursor: pointer;
    width: 100%;
    height: 36px;
    object-fit: contain;

    &:hover {
      background-color: rgba(91, 192, 235, 0.15);
    }
  }
}


.drop-down-sorting-container p {
  line-height: 36px !important;
  font-family: $regularFontFamily;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $textI;
}

.drop-down-sorting-item img {
  margin-left: 15px;
  margin-right: 15px;
}

.grid-table-row {
  cursor: pointer;
}

.boolean-circle {
  float: left;
  width: 10px !important;
  height: 10px !important;
  border-radius: 10px;
  top: 4px;
  position: relative;
  margin-right: 10px;
}

.boolean-grid-data {
  text-align: left;
  width: 100%;
}

.true-circle {
  background-color: $statusActive;
}

.false-circle {
  background-color: $statusInActive;
}

.info img {
  left: 70px;
  top: 40px;
}

.checkbox-bulk {
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  margin: 0 auto;
  background: $containerHolderColor;
  border: $underline solid 0.5px;
  border-radius: 2px;
  float: left;
  cursor: pointer;

  >input[type="checkbox"] {
    visibility: hidden;
  }
}

.checkbox-bulk-checked {
  background-image: url("/assets/css/images/platform/ikona-kljukica.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position-x: 2px;
  background-position-y: 3px;
}

.quick-preview-container {
  width: 350px;
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);
  border: $boxContainerBackground solid 1px;
  background-color: white;
  position: fixed;
  right: 20px;
  top: 150px;
  z-index: 100;

  .quick-preview-header {
    height: 56px;
    border-bottom: 1px solid $boxContainerBackground;
    display: flex;
  }

  input.sem-text-box,
  .SemTextBoxControl {
    margin-top: 0;
  }

}

.quick-preview-header-controls {
  width: 50%;
  display: inline;
}

.quick-preview-header-title {
  width: 50%;
  display: inline;

  label {
    position: absolute;
    left: 20px;
    top: 21px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: $underline;
    background-color: white !important;
  }
}


.quick-preview-footer-right,
.quick-preview-footer-left {
  display: inline;
  width: 50%;
}

.quick-preview-header-controls .zoom-in-icon {
  cursor: pointer;
  vertical-align: middle;
}

.grid-additional-actions {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.tooltip-grid-columns {
  visibility: hidden;
  width: 120px;
  color: $tooltipGridColumn;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  display: block;
}

.arrange .list:hover+.tooltip-grid-columns {
  visibility: visible;
}

.actions-tooltip-text,
.grid-form-action-tooltip-text {
  visibility: hidden;
  width: 120px;
  color: $tooltipGridColumn;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  display: block;
  top: -5px;
}

.ti-edit:hover .actions-tooltip-text {
  visibility: visible;
}

.data-grid-row-tooltip-text {
  right: 510px;
}

.grid-table-row:hover .data-grid-row-tooltip-text,
.checkbox-bulk:hover .tooltip-text-checkbox {
  visibility: visible;
}

.checkbox-bulk:hover .tooltip-text-checkbox {
  top: 40px;
  left: -30px;
}

.quick-preview-header-controls {

  .zoom-in-icon:hover + .quickpreview-tooltip-text-redirect,
  .close-icon:hover + .quickpreview-tooltip-text-close {
    visibility: visible;
  }

  .quickpreview-tooltip-text-redirect {
    right: 30px;
    top: -20px;
  }

  .quickpreview-tooltip-text-close {
    right: 10px;
    top: -20px;
  }
}

.link-data-grid-value {
  color: $mainFieldsColor;
  text-decoration: underline !important;

  &:hover {
    color: $mainFieldsColor;
    text-decoration: none !important;
  }
}

.task-file {
  color: $linkColor;
  font-weight: bold;
  cursor: pointer;
}

.task-unread {
  font-weight: bold !important;
}

.side-popup-notification {
  border: $lightBottomBorder solid 5px !important;
}

.toolbar-actions {
  width: 2px !important;
}

.notification-close-popup-icon {
  cursor: pointer;
  background-image: url("/assets/css/images/platform/uncheck-icon@3x.png");
}

.task-file-download-link {
  cursor: pointer;
  color: $mainFieldsColor;
  font-weight: bold;
}

.table-controls-notification {
  display: table;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
}

.number-of-pages-setting {
  display: inline-block;

  label {
    position: relative;
    float: right;
    top: 17px;
    right: 164px;
  }
}

.select-items {
  float: right;
  position: relative;
  top: 10px;
  right: -30px;
}

.table-row-notification {
  cursor: pointer;
}

.task-click {
  background-color: rgba(111, 173, 51, 0.15);
}

.drop-down-sorting-container {
  width: 135px;
  height: 100%;
  object-fit: contain;
  box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);
  background-color: white;
  position: absolute;
  height: 72px;
  z-index: 1;
  left: -70px;
  margin-top: 10px;

  .drop-down-sorting-item {
    cursor: pointer;
    width: 100%;
    height: 36px;
    object-fit: contain;

    &:hover {
      background-color: rgba(91, 192, 235, 0.15);
    }
  }

  p {
    line-height: 36px !important;
    font-family: $regularFontFamily;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $textI;
  }
}

/*.inuse-sorting-container {
  display: inline;
  float: right;
  position: relative;
  top: 3px;
}*/

.drop-down-sorting-item img {
  margin-left: 15px;
  margin-right: 15px;
}

.sorting-th {
  padding-right: 10px !important;
}

.headline span {
  cursor: pointer;
}

.headline-span {
  display: block !important;
}

.containers .container-wrapper .container .box:hover {
  background-color: white !important;
}

.table-chart {

  tbody,
  thead {
    border: $boxBackground solid 1px;
  }
}

table {

  td,
  th {
    border: $boxBackground solid 1px;
  }
}

.table-chart {
  thead {
    background-color: $tableChartHeader;
  }

  th,
  td {
    font-family: 'asapregular';
  }

  th {
    font-size: 15px !important;
    padding: 20px 20px 20px 20px !important;
  }

  td {
    font-size: 12px !important;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.pagination-previous {
  display: none !important;
}

.table-chart thead tr:hover {
  background-color: $tableChartHeader !important;
}

.left-double-table-chart {
  margin-top: 0 !important;
  margin-right: 10px !important;
  height: 20px !important;
  width: 10px !important;
}

.right-double-table-chart {
  margin-left: 10px !important;
  height: 20px !important;
  width: 10px !important;
  margin-top: 0 !important;
}

.left-table-chart,
.right-table-chart {
  margin-top: 0 !important;
}

.grid-table .table th {
  font-weight: bold;
}

tbody tr td:last-child {
  padding: 0;
  text-align: center;
  margin: auto;
  padding-right: 0 !important;
}

.grid-table .table-controls .arrange .icon {
  position: relative !important;
  display: inline-block;
}

.arrange .icon{
  cursor: pointer;
}

input.sem-text-box {
  margin-bottom: 12px !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-infix {
  border-top: 0 !important;
}

p {
  font-family: $regularFontFamily;
}

.grid-fields {
  margin-top: 0 !important;
}

.grid-with-facets-collapsed {}

.sorting-container {
  display: inline;
  position: relative;
  right: 0;
  cursor: pointer;
}

.drop-down-sorting-container {
  width: 135px;
  height: 100%;
  object-fit: contain;
  box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);
  background-color: white;
  position: absolute;
  height: 72px;
  z-index: 1;
  left: -70px;
  margin-top: 10px;

  .drop-down-sorting-item {
    cursor: pointer;
    width: 100%;
    height: 36px;
    object-fit: contain;

    &:hover {
      background-color: rgba(91, 192, 235, 0.15);
    }
  }
}

.table-container .table-controls {

  .number-of-pages-setting {
    margin-top: 5px;

    label,
    .select-items {
      display: table-cell;
    }
  }
}

.drop-down-sorting-container p {
  line-height: 36px !important;
  font-family: $regularFontFamily;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $textI;
}

.inuse-sorting-container {
  vertical-align: top;
  position: absolute;
  right: 5px;
  top: 20px;
}

.drop-down-sorting-item img {
  margin-left: 15px;
  margin-right: 15px;
}

.grid-table-row {
  cursor: pointer;
}

.boolean-circle {
  float: left;
  width: 10px !important;
  height: 10px !important;
  border-radius: 10px;
  top: 4px;
  position: relative;
  margin-right: 10px;
}

.boolean-grid-data {
  text-align: left;
  width: 100%;
}

.true-circle {
  background-color: $statusActive;
}

.false-circle {
  background-color: $statusInActive;
}

.table-footer {

  .left,
  .left-double {
    padding-right: 25px !important;
  }
}

.info img {
  left: 70px;
  top: 40px;
  display: none;
}

.checkbox-bulk {
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  margin: 0 auto;
  background: $containerHolderColor;
  border: $underline solid 0.5px;
  border-radius: 2px;
  float: left;
  cursor: pointer;

  >input[type="checkbox"] {
    visibility: hidden;
  }
}

.checkbox-bulk-checked {
  background-image: url("/assets/css/images/platform/ikona-kljukica.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position-x: 2px;
  background-position-y: 3px;
}

.quick-preview-header-controls {
  width: 50%;
  display: inline;
  padding-top: 14px;
  padding-right: 13px;

  * {
    margin-right: 14px;
    display: inline-block;
    vertical-align: middle;
  }
}

.quick-preview-header-title {
  width: 50%;
  display: inline;

  label {
    position: absolute;
    left: 20px;
    top: 21px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: $underline;
    background-color: white !important;
  }
}

.quick-preview-header-controls .close-icon {
  cursor: pointer;
  vertical-align: middle;
  height: 16px;
}

.quick-preview-content {
  height: 40vh;
  overflow: auto;

  .mat-mdc-form-field-flex,.mat-mdc-form-field-infix{
    padding-top: 0px !important;
  }

  .mat-mdc-form-field-flex{
    display: block;
  }
  .mat-mdc-form-field{
    min-height: auto !important;
    height: 25px !important;
    display: block !important;
  }

  .SemDropDownSelectControl .mat-mdc-text-field-wrapper{
    height: 25px !important;
  }

  .mat-mdc-form-field-subscript-wrapper{
    display: none;
  }

  .mat-mdc-form-field-infix
  {
    display: block !important;
  }
  .mat-mdc-text-field-wrapper{
    padding: 0 !important;
  }
  
}

.quick-preview-footer {
  height: 56px;
  margin-bottom: 15px;
  margin-top: 20px;
  text-align: center;

  .accept {
    object-fit: contain;
    border-radius: 3px;
    background-color: transparent;
    border: $highlightColor solid 1px;
    font-family: 'asapregular';
    font-size: 12px;
    letter-spacing: 0.92px;
    color: $highlightColor;
    text-transform: uppercase;
    position: relative;
    cursor: pointer;
    margin-top: 10px;
    margin-right: 15px;
    padding: 6px;

    &::after {
      content: "";
      background-image: url("/assets/css/images/platform/icon-right-white@3x.png");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 12px;
      width: 12px;
      position: absolute;
      top: 0;
      right: 15px;
      bottom: 0;
      margin: auto;
    }
  }

  .decline {
    object-fit: contain;
    border-radius: 3px;
    border: $underline solid 1px;
    background-color: transparent;
    font-family: 'asapregular';
    font-size: 12px;
    letter-spacing: 0.92px;
    color: $underline;
    text-transform: uppercase;
    cursor: pointer;
    padding: 6px;
  }
}

.quick-preview-footer-right,
.quick-preview-footer-left {
  display: inline;
  width: 50%;
}

.quick-preview-header-controls-separator {
  height: 25px;
  border: $lightBottomBorder solid 1px;
}

.quick-preview-header-controls .zoom-in-icon {
  cursor: pointer;
}

.grid-additional-actions {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.arrange .list:hover+.tooltip-grid-columns {
  visibility: visible;
}

.actions-tooltip-text {
  visibility: hidden;
  width: 120px;
  color: $tooltipGridColumn;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  display: none;
  top: -5px;
  left: 15px;
}

.grid-form-action-tooltip-text {
  visibility: hidden;
  width: 120px;
  color: $tooltipGridColumn;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  display: block;
  top: -5px;
}

.ti-edit:hover .actions-tooltip-text {
  visibility: visible;
  display: block;
  left: -50px;
  top: -20px;
}

.data-grid-row-tooltip-text {
  right: 510px;
}

.grid-table-row:hover .data-grid-row-tooltip-text,
.checkbox-bulk:hover .tooltip-text-checkbox {
  visibility: visible;
}

.quick-preview-header-controls {

  .zoom-in-icon:hover+.quickpreview-tooltip-text-redirect,
  .close-icon:hover+.quickpreview-tooltip-text-close {
    visibility: visible;
  }
}

.link-data-grid-value {
  color: $mainFieldsColor;
  text-decoration: underline !important;

  &:hover {
    color: $mainFieldsColor;
    text-decoration: none !important;
  }
}


.search-button {
  height: 58px;
  padding-right: 10px;
  margin-left: 20px;
  border-radius: 5px;
  display: table;
}

.item-option {
  cursor: pointer;
}

.search-dd::after {
  top: 18px !important;
  right: 6px !important;
}

.right-span {
  height: 12px;
  font-family: $regularFontFamily;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $mainFieldsColor;
  float: left;
  position: relative;
  padding-top: 5px;
  left: 406px;
}

.search-button p {
  font-family: $regularFontFamily;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  line-height: 60px;
  text-align: left;
  padding-left: 18px;
  display: table-cell;
}

.global-search {
  display: table-cell;
  width: 17px;
  height: 14px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
  position: relative;
  left: 10px;
}

.search-input {
  display: table-cell;
  vertical-align: top !important;
  height: 40px !important;
}

.search-dd,
.selec {
  height: 38px !important;
}

.search-button {
  height: 38px !important;
  cursor: pointer;

  p {
    line-height: 38px !important;
  }
}

.search-dd p {
  line-height: 38px !important;
}

.search-fields-settings {
  width: 700px;
  height: 612px;
  background-color: white;
  object-fit: contain;
  border-top: 5px solid $matDialogContainerAfter;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  z-index: 100;

  .title-div {
    margin-bottom: 30px;
  }

  label {
    font-family: "asapregular" !important;
    display: block;
    font-size: 12px;
  }

  .pop-header {

    .left {
      min-width: 110px;
      height: 20px;
      border-radius: 1px;
      background-color: $sectionHolderBackground;
      background-image: none !important;
      margin: 17px 20px;
      text-align: left;
      display: inline-block;
      background-image: none !important;

      .label {
        font-family: $regularFontFamily;
        font-size: 11px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $textI;
        text-transform: none !important;
        margin: auto;
        padding: 0 0 0 5px;
      }

      img,
      .label {
        display: inline-block;
        vertical-align: middle;
      }

    }

    .right {
      display: inline-block;
      background-image: none !important;
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }
  }

  .info {
    input {
      padding-right: 25px;
      background: url("/assets/css/images/platform/shape.png") no-repeat 97%;
      background-size: 12px;
    }
  }
}

.search-control .wrapper {
  padding-bottom: 15px !important;
}

.info img {
  position: relative;
  height: 12px;
  width: 12px;
}

.info div {
  float: right;
  position: relative;
  top: -19px;
  right: 10px;
}

.drag-drop-content {
  display: table;

}

.added-search-fields ul {
  text-align: left;
}

.all-search-fields ul li,
.added-search-fields ul li {
  font-family: $regularFontFamily;
  font-size: 12px;
  font-weight: normal;
  height: 17px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.1px;
  padding-left: 9px;
  color: $mainFieldsColor;
  cursor: pointer;
  white-space: nowrap;
  width: fit-content;
}

.all-search-fields ul> :first-child,
.added-search-fields ul> :first-child {
  margin-top: 10px;
}

.all-search-fields ul> :last-child,
.added-search-fields ul> :last-child {
  margin-bottom: 10px;
}

.all-search-fields ul,
.added-search-fields ul {
  overflow: hidden;
  height: 100%;
}

.all-search-fields ul li,
.added-search-fields ul li {

  &.ng-star-inserted.active,
  &:hover {
    background-color: $underline;
    width: max-content;
  }
}

.search-control \: {
  &:-webkit-scrollbar {
    width: 27px;
  }

  &:-webkit-scrollbar-thumb {
    border: transparent solid 12px;
    background-clip: padding-box;
    background-color: rgba(0, 0, 0, 0.15);
  }

  &:-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &:-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

.sem-text-box {
  z-index: 0 !important;
}

.search-loader {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  z-index: 9999999;
  top: 0;
  left: 0;

  img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 100px;
  }
}

.facets-container {
  padding-bottom: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);
  background-color: white;
  vertical-align: top;

  >div {
    padding-top: 10px;
  }
}

.facets-main-title {
  width: 100%;
  height: 5%;
  display: none;
}

.string-facets {
  width: 100%;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  margin-top: 10px;

  label {
    font-family: $regularFontFamily;
    font-size: 13px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $generalParagraphTextColor;
  }
}

.facets-main-title label {
  height: 15px;
  font-family: $regularFontFamily;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  float: left;
  position: relative;
  top: 30px;
  margin-top: 10px;
  left: 19px;
}

.facet-values {
  margin-top: 10px;
}

.facet-value-item {
  margin-top: 5px;
  position: relative;
}

.facet-count {
  float: right;
  position: relative;
  right: 5px;
  font-family: $regularFontFamily;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $underline;
}

.facet-total-count {
  margin-left: 10px;
  font-family: $regularFontFamily;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: $underline;
}

.facet-value {
  font-family: $regularFontFamily;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $generalParagraphTextColor;
  position: relative;
  top: -1px;
  left: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  width: 45%;
}

.checkbox-facet {
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  margin: 0 auto;
  background: $containerHolderColor;
  border: $underline solid 0.5px;
  border-radius: 2px;
  float: left;
  cursor: pointer;

  >input[type="checkbox"] {
    visibility: hidden;
  }
}

.checkbox-facet-checked {
  background-image: url("/assets/css/images/platform/ikona-kljukica.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position-x: 2px;
  background-position-y: 3px;
}

.facet-label-tooltip {
  visibility: hidden;
  color: $tooltipGridColumn;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  display: block;
  padding-left: 5px;
  padding-right: 5px;
}

.facet-value:hover+.facet-label-tooltip {
  visibility: visible;
}

.collapsed-facets-container {}

.collapsed-facets-main-title,
.collapsed-facets-availible {
  display: none !important;
}

.facet-actions-collapse {
  right: 10px;
  position: relative;
  float: right;

  img {
    cursor: pointer;
  }
}

.facet-actions-expand img {
  cursor: pointer;
  margin-top: 10px;
}

.facet-tooltip-collapse,
.facet-tooltip-expand {  
  color: $tooltipGridColumn;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  display: block;
  padding-left: 5px;
  padding-right: 5px;
}

// facet-tooltip-expand is not hidden via ngIf because of UI design
.facet-tooltip-expand{
  visibility: visible !important;
}
.facet-tooltip-collapse{
  visibility: hidden;
}

.facet-actions-collapse img:hover+.facet-tooltip-collapse{
  visibility: visible !important;
}

.facet-tooltip-expand {  
  //position: relative;
  //top: -500px;
  margin-top:30px;
}

.SemDropDownSelectControl,
.SemComboBoxControl {

  mat-form-field {
    margin-top: 6px;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-infix {
    padding-top: 0;
    padding-left: 16px;
  }

  .classic-dropdown {
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-infix {
      padding-top: 10px;
    }
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-arrow {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
  margin: 0 4px;
  color: rgba(0, 0, 0, 0.54);
  right: 6px;
  bottom: 0;
  position: absolute;
  top: 4px;
  z-index: -1;
}

.autocomplete-span {
  input {
    border: 0 !important;
  }
}

sem-multiple-select {
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-flex {
    padding-top: 12px;
  }
}

.fields-main-label {
  font-family: $regularFontFamily;
  font-size: 20px !important;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: $mainFieldsColor;
  text-transform: uppercase;
  position: relative;
  margin-left: 20px;
}

one-to-many-section {

  .SemDropDownSelectControl mat-form-field,
  .SemComboBoxControl mat-form-field {}
}

.mat-form-field-disabled,.mat-form-field-disabled * {
  // removed !important here, if you need this override in project.scss
  color: $textI;  
}

.mat-radio-disabled {
  .mat-radio-label {
    cursor: not-allowed !important;
  }
}

.action-loader {
  height: auto !important;
}


.wrapper-without-datatools {
  padding-left: 5%;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.search-bar-multipleselect .mat-form-field-wrapper {
  border: none !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.search-bar-multipleselect .mat-form-field-flex {
  background-color: transparent !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.search-bar-multipleselect .mat-select-arrow {
  border: none !important;
}

.search-selector .mat-mdc-form-field {
  top: -10px;
}

input:hover+.button-tooltip-container .button-tooltip-text {
  visibility: visible;
}

/*td.bulk-checkbox{
  padding-left: 20px !important;
  padding-right: 20px !important;
  width:10px !important;
}

th.checkbox-bulk-table-head {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
*/

.additional-custom-data-item {
  display: inline-block;
  width: 30%;
  margin-right: 3%;
}

.additional-custom-data-item sem-drop-down-select {
  display: block;
}

.search-range-item {
  padding-right: 0px !important;

  >* {
    width: 100px !important;
  }

  .additional-custom-data-item .sem-drop-down-additional-custom-data-item mat-form-field {
    width: 100% !important;
  }

  >span {
    width: auto !important;
  }


  .o2m-additional-actions-tooltip {
    top: -50px;
    right: -20px;
  }

  .inner-manipulate-section:hover .o2m-additional-actions-tooltip {
    visibility: visible;

    input {
      padding: 10px !important;
    }
  }

  input.sem-text-box,
  .active-url-textbox {
    border-radius: 3px;
    border: solid 1px #ccd2d5;
    /*height: 40px !important;*/
    height: 40px;
    width: 100%;
    margin-top: 12px;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #313541;
    vertical-align: middle;
    margin-bottom: 22px;
    background-color: transparent;
    z-index: 99;
    cursor: pointer;
    display: block;
  }


}


.sem-query-builder {
  border-radius: 3px;
  border: solid 1px #e5eaed;
}

.remove-rule-icon {
  background-image: url("images/platform/icon-close@3x.png");
  width: 10px;
  height: 10px;
  background-size: cover;
}


.remove-ruleset-icon {
  background-image: url("images/platform/group-14@3x.png");
  width: 20px;
  height: 20px;
  background-size: cover;
  display: inline-block;
  position: relative;
  left: 20px;
  cursor: pointer;
}

.remove-rule-icon-container {
  padding-top: 10px;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  float: none !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.sem-drop-down-querybuilder .mat-mdc-form-field .mat-form-field-wrapper {
  height: 30.5px !important;
  padding-bottom: 0px !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.sem-drop-down-querybuilder .mat-form-field-infix span {
  padding-left: 10px;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.sem-drop-down-querybuilder .mat-form-field-infix input {
  position: relative;
  bottom: 7px;
  width: 80%;
}

.sem-drop-down-querybuilder .autocomplete-loader {
  top: -16px;
}

.querybuilder-icon {
  background-image: url(images/platform/qb-icon@3x.png);
  width: 20px;
  height: 10px;
  background-size: cover;
}

.search-control .wrapper .search-button {
  display: inline-block;
}

.search-control .wrapper .search-button-query-builder {
  display: inline-block;
  position: relative;
  top: -15px;
  height: 22px;
}

.search-button-query-builder {
  padding: 8px 15px;
  border-radius: 5px;
  border: solid 1px #ccd2d5;
  margin-top: 0px;
  margin-left: 25px;
}

.search-button-query-builder {
  font-family: $regularFontFamily;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #81898c;
  cursor: pointer;
}

.search-button-query-builder .querybuilder-icon {
  display: inline-block;
}

.search-button-query-builder p {
  display: inline-block;
  padding-left: 5px;
}

.search-querybuilder-popup {
  width: 900px !important;
  height: 602px !important;
  overflow: auto;
}

.search-querybuilder-popup .footer {
  margin-bottom: 10px;
  margin-top: 20px;
}

.search-querybuilder-popup .sem-query-builder {
  margin-left: 20px;
}

.search-querybuilder-popup .right-footer {
  top: 0px;
}

.rule-group-querybuilder {
  width: 100% !important;
}

.cancel-mid {
  margin-right: 20px;
}

.operator-minwidth {
  min-width: 120px;
}

.search-querybuilder-popup {


  .input-checkbox {
    height: 30px;
    width: 35px !important;
    position: relative;
    top: -2px;
  }


  .input-number,
  .input-datetime,
  .input-textfield {
    min-width: 180px;
  }

  .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
    opacity: 0 !important;
  }

  .mat-mdc-text-field-wrapper {
    height: 32px;
    padding: 0px 0px 0px 0px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: transparent !important;
  }

  .mat-mdc-form-field {
    background-color: transparent !important;
  }

  .mdc-line-ripple {
    display: none !important;
  }

  input {
    padding-left: 10px;
  }

  input:focus {
    border: none !important;
  }
}

.active-url-textbox-valueholder {
  color: blue;
  padding: 0 10px 0 16px;
  outline: none;
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
}

.active-url-textbox {
  display: table !important;
  border-radius: 3px;
    border: #ccd2d5 solid 1px;
    height: 40px;
    width: 100%;
    margin-top: 6px;
    font-family: $regularFontFamily;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #313541;
    vertical-align: middle;
    margin-bottom: 22px;
    background-color: transparent;
    z-index: 99;
    cursor: pointer;        
}


.quick-preview-container .SemMultiColumnControl {
  margin-bottom: 10px;
}

.SemTableDataControl .table-data-wrapper {
  padding-top: 50px;
  overflow: auto;
  height: calc(50vh - 65px);
  width: 100%;
}

.user .top-menu-item p {
  margin: 0 !important;
}


.top-expl-wrap {
  display: inline-table;
  width: 100%;
  table-layout: fixed;

  >div:first-of-type,
  >div:last-of-type {
    width: 195px;
    display: table-cell;
  }

  >div:nth-child(2) {
    width: 100%;
    display: table-cell;
  }

  >* {
    display: table-cell;
    vertical-align: top;
  }
}

.sec-title-btns {
  button {
    display: inline-block;
    width: 50%;
    height: 50px;
    border-radius: 0;
    background-color: rgba(229, 234, 237, 0.5);
    border: 0;
    font-family: $secRegularFontFamily;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
  }

  button:first-of-type {
    border-right: 1px solid #e5eaed;
  }
}

.selected-btn {
  background-color: white !important;
}

.a9s-annotationlayer {
  margin: auto;
  height: auto !important;
  width: auto !important;
  max-height: 80vh;
  right: 0;
  bottom: 0;
}

.SemTableChartControl .table-footer .left,
.SemTableChartControl .table-footer .right {
  height: 13px;
  width: 6px;
  position: relative;
  top: 10px;
}

.grid-table {
  .table-footer {

    .right, .left{
      height: 13px;
      width: 6px;
      position: relative;      
    }
  }
}

tabledatachart-table-footer {
  height: 55px;
  text-align: center;
}


.tabledatachart-table-footer {
  height: 55px;
  text-align: center;

  .left, .right, .right-double, .left-double {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 9px;
    width: 5px;
    display: inline-block;
    vertical-align: middle;
    margin-top: 21px;
    position: relative;
    top: 10px;
    cursor: pointer;
  }

  .right {
    background-image: url("images/platform/icon-arrow-right@3x.png");
  }

  .left {
    background-image: url("images/platform/icon-arrow-left@3x.png");
  }

  .right-double {
 
    background-image: url(images/platform/double-arrow-right@3x.png);
  }

  .left-double {
    
    background-image: url(images/platform/double-arrow-left@3x.png);
  }

  p {
    display: inline-block;
    font-family: $secRegularFontFamily;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $textI;
    padding-left: 25px;
    vertical-align: middle;
    padding-top: 21px;
    cursor: pointer;

    &:last-of-type {
      padding-right: 25px;
    }

    &.selected {
      color: $highlightColor;
    }
  }
}


.SemMultipleSelectControl {

  .mat-mdc-text-field-wrapper {
    height: 40px;
    margin-top: 17px;
  }

  .mat-mdc-text-field-wrapper {
    border: $boxContainerBackground solid 1px;
    border-radius: 3px;
  }    
}

.add:hover .fileupload-add{
    left:-45px;
}

.download:hover .fileupload-download {
  top: 35px;
  left: -30px;
}

.fileupload-download, .fileupload-add{
    cursor:pointer;
}

.input-wrapper .download{
    cursor:pointer;
}

.setdefault-img:hover .fileupload-set-as-default {
  top: 40px;
  left: -30px;
}

.cdk-overlay-pane {
  &.mat-tooltip-panel {
    .mat-tooltip {
      font-size: 15px;      
    }
  }
}

#modifyGridTableIcon:hover .tooltip-grid-columns {
  visibility: visible;
  top: 25px;
  z-index: 100;
  right: -60px;
}

.m2o-search-fields-container {
  display: flex;
  flex-wrap: wrap;
}


.m2o-dialog-footer-left-icon-page {
  display: inline-block;
  background-image: url("images/platform/icon-arrow-left@3x.png");
  height: 13px;
  width: 6px;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  top: 4px;
}

.notification-center-content{
  .sub-nav-menu{
    position: relative !important;
    top: 62px;
  }

  .menu-wrapper{
    padding-left:145px !important;
    width:60% !important;
  }
}

.facet-actions{
  text-align: center;
}

.mat-mdc-dialog-surface{
  overflow: hidden;
}

.custom-action-onclick{
  cursor:pointer;

  .action-title{
    font-weight: bold;
  }
}

.m2o-table-wrapper{
  overflow: auto;
}
