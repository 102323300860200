$highlightColor: #447695;
$loginPageButtonColor: #447695;

.under-img {
    margin-bottom: 80px !important;
}

.header-img {
    width: 125px !important;
    margin-top: 15px !important
}

.login button {
    background-color: $loginPageButtonColor !important;
}

.header-img{
    width: 73px !important;
}