.sidebar .item img {
  max-width: 20px;
}

.quick-preview-container .bound-label-container {
  padding: 30px;
}

.galis-top-multicolumn {
  margin-bottom: 30px;
  display: table;
  width: 100%;
  table-layout: fixed;

  input {
    padding: 0 10px 0 10px !important;
  }

  .clearfix {
    display: none;
  }

  .SemLayoutHolderControl:first-of-type {
    > * {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .SemColumn {
    display: table-cell;
    float: none !important;
    vertical-align: middle;
    width: auto !important;
    padding: 0 !important;
  }

  .SemColumn:nth-child(2) {
    text-align: center;
  }

  .SemColumn:nth-child(3) {
    .SemLayoutHolderControl {
      text-align: right;
    }
  }

  .SemTextBoxControl {
    margin-top: 0;
  }

  .form-group {
    text-align: left;
  }
}

.sidebar .item p {
  font-size: 15px;
}

.galis-button {
  object-fit: contain;
  border-radius: 3px;
  border: solid 1px $highlightColor;
  background-color: transparent;
  font-size: 15px;
  letter-spacing: 1.3px;
  color: $highlightColor;
  text-transform: uppercase;
  cursor: pointer;
  margin: 10px 0 0 15px;
  display: block;
}

/*.preview-image .actual-image {
    width: 250px !important;
    height: 250px !important;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
    background-position: center;
}

.side-input-form .afu-dragndrop-box {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 85% !important;
    height: 450px !important;
    position: relative;
    border: 0 !important;
    margin: auto;
}*/

.sem-hero-component sem-graph-pie div svg {
  margin-top: 15px;
}

.quartal-parameter-form {
  textarea {
    width: 90%;
  }

  .SemTextAreaControl {
    margin: 20px 0 20px 0;
  }
}

.popup-lbl {
  margin-bottom: 45px;
  font-family: $secRegularFontFamily;
}

.oks-button {
  object-fit: contain;
  border-radius: 3px;
  border: solid 1px $buttonColor;
  background-color: transparent;
  font-family: "asapregular";
  font-size: 15px;
  letter-spacing: 1.3px;
  color: $buttonColor;
  text-transform: uppercase;
  cursor: pointer;
}

header .wrapper .user {
  background-image: url(images/oks-organizations@3x.png);
}

.file-picker-container {
  padding-top: 10px;
  padding-bottom: 20px;
}

.sportgames-buttons {
  .SemButtonControl {
    margin-bottom: 10px;
  }
}

.oks-dashboard-layout {
  margin-bottom: 32px;
  margin-left: 143px;
  margin-right: 35px;
  height: auto;
}

.dashboard-hero-control .SemQuickActionsControl .qa-shortcut {
  cursor: pointer;
}

.dashboard-table-chart-oks {

  th {
    background-color: #cceeff !important;
  }

  td {
    font-size: 12px !important;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
  }
}

.allregistered-chart {
  padding-left: 20px;
}

.allregistered-hero-component {
  .highlight-section {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.first-sem-hero-dashboard {
  height: 450px !important;
}

.third-hero-charts-dashboard {
  .highlight-section {
    width: 100%;
    height: 100%;
    min-height: 430px;
  }

  .SemHeroHighlightLayout > div:last-of-type {
    position: absolute;
  }

  .SemHeroHighlightLayout {

    .GalisFormControl {
      width: 40% !important;
      display: inline-block;
      padding-top: 20px;

      .categorizationcount-chart {
        padding-left: 10px;
      }
    }
  }
}

.left-menu-logo-img {
  background-image: url(images/oks-logo@3x.png);
}

.img-holder-sub-sidebar {
  background-image: url(images/logo_mgts.png);
  height: 50px;
  background-size: 240px 40px;
  background-repeat: no-repeat;
}

.sub-sidebar {
  position: relative;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 40px;
}

.add-fields-sgames-panoge {
  margin-top: 10px;
}

.allregistered-hero-component {


  .highlight-section {
    width: 100%;
    height: 100%;
  }
}

header .wrapper .navodila-icon {
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(images/Help_icon@3x.png);
  background-size: 23px 23px;
  width: 58px;
  cursor: pointer;
  position: relative;
  display: table-cell;
  vertical-align: middle;
  height: 48px;
  padding-left: 10px;
  padding-right: 37px;
}

.search-button {
  border: 1px solid $buttonColor;
}

.search-button p {
  color: $buttonColor;
}

.global-search {
  background-image: url(images/shapegreen@3x.png) !important;
}

.grid-table .table-container table td div span {
  max-width: none !important;
}

.popup-inner-content .SemTextAreaControl {
  margin-top: -30px;
  margin-right: 30px;
}

.search-selector {
  display: none !important;
}

/*.parameter-dialog-container {
  max-height: 750px !important;

  .side-popup {
    max-height: 750px !important;
  }
}*/

.wide-popup {
  min-width: 450px;
}

.mat-form-field-disabled, .mat-form-field-disabled * {
  color: $disabledFieldColor !important;
}

.add-fields-sgames-panoge{
  mat-form-field{
    position: relative;
      top: -11px;
  }
}
