/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 22, 2019 */



@font-face {
    font-family: 'robotobold';
    src: url('roboto-bold-webfont.woff2') format('woff2'),
         url('roboto-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'robotomedium';
    src: url('roboto-medium-webfont.woff2') format('woff2'),
         url('roboto-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'robotoregular';
    src: url('roboto-regular-webfont.woff2') format('woff2'),
         url('roboto-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}