a {
  cursor: pointer;
}

.validation-summary-valid,
.new-content-form .fg {
  display: none;
}

.non-editable {
  background-color: $nonEditable !important;
}

html {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  line-height: 1;
  margin: 0;
  padding: 0;
  background-color: $bodyBackgroundColor;
  box-sizing: border-box;
  min-width: 1300px;
}

div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

blockquote {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  quotes: none;

  &:before,
  &:after {
    content: '';
    content: none;
  }
}

pre {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-decoration: none !important;

  &:hover {
    text-decoration: none !important;
  }

  &:visited {
    color: initial !important;
  }
}

abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

q {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  quotes: none;

  &:before,
  &:after {
    content: '';
    content: none;
  }
}

s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

ol,
ul {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  list-style: none;
}

li,
fieldset,
form,
label,
legend {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

table {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border-collapse: collapse;
  border-spacing: 0;

  a {
    color: black;
  }
}

caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
}

canvas {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

details {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
}

embed {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

figure,
figcaption,
footer {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
}

header {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
  transition: 0.3s all;
  height: 60px;
  width: auto;
  background-color: $secondaryColor;
  position: fixed;
  border-bottom: solid 2px $lightBottomBorder;
  right: 0;
  left: 280px;
  z-index: 9;
  min-width: 1000px;

  a {
    color: $breadCrumbsTextColor !important;

    &:link,
    &:visited,
    &:hover,
    &:active {
      color: $breadCrumbsTextColor !important;
    }
  }

  .wrapper {
    padding: 12px;
    right: 0;
    display: inline-block;
    float: right;

    .empty {
      width: 15px;
      height: 100%;
      display: table-cell;
      vertical-align: middle;
      height: 100%;
    }

    .platform .top-menu {
      display: none;
    }

    .user {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("images/platform/ikona-user@2x.png");
      background-size: 23px 23px;
      height: 100%;
      width: 58px;
      cursor: pointer;
      position: relative;
      display: table-cell;
      vertical-align: middle;
      height: 100%;

      .top-menu {
        width: 180px;
        box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);

        .top-menu-item {
          display: inline-table;
          table-layout: fixed;
          vertical-align: middle;

          .user-profile-icon-img {
            background-image: url("images/platform/icon-username@2x.png");
          }

          .img {
            width: 19px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            display: table-cell;
            vertical-align: middle;
            font-family: $secRegularFontFamily;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $textI;
          }

          .item-text {
            display: table-cell;
            vertical-align: middle;
            font-family: $secRegularFontFamily;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $textI;
          }
        }
      }
    }

    .language-picker {
      display: table-cell;
      vertical-align: middle;
      height: 100%;
      width: 85px;
      height: 36px !important;
      padding-left: 36px;
      border-left: solid 1px $lightBottomBorder;
      cursor: pointer;

      p {
        font-family: $secRegularFontFamily;
        font-size: 15px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $textI;
        display: inline-block;
        vertical-align: middle;
        margin: 0;

        &:after {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          background-image: url("images/platform/arrow-down-s@3x.png");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          margin-left: 10px;
          margin-top: 5px;
        }
      }

      .top-menu {
        width: 75px;
        box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);
        right: 40px;
        left: unset;
        top: 80%;

        .top-menu-item {
          display: inline-table;
          table-layout: fixed;

          .item-text {
            display: table-cell;
            vertical-align: middle;

            p {
              font-family: $secRegularFontFamily;
              font-size: 12px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: $textI;
              padding-left: 20px;

              &:after {
                display: none !important;
              }
            }
          }
        }
      }
    }
  }
}

hgroup,
menu,
nav {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
}

output,
ruby {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

section {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  display: block;
}

summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.user .top-menu-item {
  padding-left: 12px;

  p {
    padding-left: 16px;
    margin: 0 !important;
  }
}

.top-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: $selectPanelBackground;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  .top-menu-item {
    height: 45px;
    width: 100%;
    border-left: 4px solid transparent;

    &:hover {
      border-left-color: $highlightColor;
      background-color: $sectionTitleBackgroundColor;
    }
  }
}

.user-profile {
  background-image: url("images/platform/avatar@2x.png");
}

.system-settings {
  background-image: url("images/platform/tools@2x.png");
}

.logout {
  background-image: url("images/platform/forward@2x.png");
}

.content {
  transition: 0.2s all;
  display: table-cell;
  vertical-align: top;
  position: absolute;
  left: 180px;
}

.sidebar {
  display: table-cell;
  transition: 0.2s all;
  vertical-align: top;
  position: fixed;
  width: 250px;
  background-color: $primaryColor;
  box-shadow: -16px 0px 45px -4px rgba(0, 0, 0, 0.8), -12px 0 8px -4px rgba(0, 0, 0, 0.8);
  z-index: 10;
  height: 100%;
  overflow: hidden;

  * {
    transition: 0.2s all;
  }

  .first-of-type {
    margin-top: 16px !important;
  }

  .item {
    //margin-top: 10px;
    cursor: pointer;
    height: 40px;
    position: relative;
    width: 100%;
    border-left: 4px solid transparent;
    //margin-bottom: 10px;
    display: inline-table;
    text-align: center;

    &:hover {
      border-left: 4px solid $highlightColor;
      background-color: $sectionTitleBackgroundColor;
    }

    p {
      font-weight: bold;
      text-transform: uppercase;
      padding-left: 12px !important;
      width: 100%;
      vertical-align: middle;
      font-family: $mediumFontFamily;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1.5px;
      padding-left: 7px;
      color: $textI;
      padding-right: 5px !important;
      text-align: left;

      span {
        font-family: $mediumFontFamily;
      }
    }

    img {
      max-width: 42px;
      max-height: 42px;
      vertical-align: -webkit-baseline-middle;
    }

    .img {
      display: table-cell;
      vertical-align: middle;
    }

    &.selected,
    &.current {
      border-left: 4px solid $highlightColor;
      background-color: $sectionTitleBackgroundColor;
    }
  }

  .submenu {
    width: 100%;
    position: relative;
    transition: 0.1s all;

    p {
      padding-left: 28px;
      padding-bottom: 5px;
      font-family: $secRegularFontFamily;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-transform: none !important;
      color: $textI;
      cursor: pointer;

      &:first-of-type {
        padding-top: 10px;
      }

      &:hover {
        color: $highlightColor;
      }
    }

    .hover-overlay {
      position: absolute;
      height: 50px;
      width: 100%;
      border-left: 4px solid $highlightColor;
      background-color: $sectionTitleBackgroundColor;
      display: none;
      cursor: pointer;
    }
  }

  .dropdown:after {
    content: "";
    height: 10px;
    width: 10px;
    position: absolute;
    right: 35px;
    top: 15px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("images/platform/arrow-down-s@3x.png");
  }

  .open-dropdown:after {
    content: "";
    height: 10px;
    width: 10px;
    position: absolute;
    right: 35px;
    top: 18px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("images/platform/icon-dd-open@3x.png");
  }

  .logo {
    display: inline-table;
    transition: none !important;
    height: 60px;
    border-bottom: solid 2px $lightBottomBorder;
    width: 100%;

    .img {
      vertical-align: middle;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 100%;
      width: 23px;
    }

    p {
      display: table-cell;
      vertical-align: middle;
      font-family: $secRegularFontFamily;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 2.5px;
      padding-left: 12px;
      color: $textI;
      font-size: 22px;

      span {
        font-family: $secRegularFontFamily;
      }
    }

    a {
      color: $textI !important;

      &:visited {
        color: $textI !important;
      }
    }

    align-content:visited {
      color: $textI !important;
    }

    * {
      transition: none !important;
    }

    .closed {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
      cursor: pointer;
      margin: auto !important;
      width: 23px;
    }

    .opened {
      background-image: url("images/platform/ikona-wgp@3x.png");
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

.shadow {
  box-shadow: 1px 3px 20px 0px rgba(0, 0, 0, 0.1);
}

.main-content {
  width: 100%;
  height: 100%;
  overflow: hidden;
  table-layout: fixed;
  background-color: $mainContentBackground;
}

.easein .sidebar {
  width: 10%;
}

.inner {
  height: 100%;
  margin-top: 60px;
  margin-bottom: 30px;

  h1 {
    font-family: $secRegularFontFamily;
    font-size: 25px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 3px;
    color: $textI;
    text-transform: uppercase;
  }

  .inner-item-wrapper {
    height: 120px;
  }

  .module {
    width: 175px;
    margin-left: 3%;
    display: inline-block;
    vertical-align: top;
  }

  .sem-hero-component {
    height: 100%;
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);
    margin: auto;
    background-color: $selectPanelBackground;
  }

  .item-container {
    height: 100%;
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);
    margin: auto;
    background-color: $selectPanelBackground;
    display: inline-table;

    h1 {
      padding: 0 70px 0 38px;
      text-align: left;
      display: table-cell;
      vertical-align: middle;
      line-height: 115px;
    }

    .search-control {
      width: 82%;
      display: table-cell;
      display: table-cell;
      vertical-align: middle;
      line-height: 115px;

      .wrapper {
        display: inline-table;
        table-layout: fixed;
        width: 100%;
        position: relative;
        padding-bottom: 5px;

        input {
          vertical-align: middle;
          height: 60px;
          width: 100%;
          overflow: hidden;
          border: 1px solid $inputBackground !important;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          background: url("images/platform/search-icon-gold@2x.png") no-repeat scroll 22px 18px;
          background-size: 20px 20px;
          padding: 0 10px 0 60px;
          outline: none;
          font-family: $secRegularFontFamily;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $textI;
          box-sizing: border-box;
        }

        .search-selector {
          display: table-cell;
          vertical-align: middle;
          height: 60px;
          width: 171px;

          .dd-search {
            height: 58px;
            line-height: 60px;
            border: 1px solid $inputBackground;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border-left: 0 !important;
            margin-top: 3px;
            text-align: center;
            position: relative;
          }

          p {
            font-family: $secRegularFontFamily;
            font-size: 18px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $textI;
            line-height: 60px;
            text-align: left;
            padding-left: 18px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.containers {
  padding-top: 42px;
  text-align: center;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  h1 {
    text-align: left;
    padding-left: 38px;
    margin-bottom: 26px;
    color: $highlightColor !important;
  }

  .SemLastEditedData span {
    display: block;
  }

  .container-wrapper {
    display: inline-table;
    table-layout: fixed;
    width: 81%;

    .container {
      display: table-cell;

      .box {
        text-align: left;
        min-height: 110px;
        width: 150px;
        display: inline-block;
        border-left: 1px solid $boxContainerBackground;
        position: relative;
        vertical-align: top;
        margin-bottom: 20px;

        &:hover {
          background-color: $sectionTitleBackgroundColor;
        }

        img {
          -o-object-fit: contain;
          object-fit: contain;
          height: 25px;
          width: 25px;
          left: 16px;
          top: 12px;
          margin-left: 16px;
          margin-top: 16px;
        }

        p {
          font-family: $secRegularFontFamily;
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 1px;
          color: $textI;
          text-transform: uppercase;
          margin-top: 13px;
          margin-left: 16px;
          text-align: left;
          padding-right: 23px;
          margin-bottom: 16px;
        }
      }

      .side {
        text-align: left !important;
        display: inline-table;
        table-layout: fixed;
        width: 100%;

        .box {
          border-left: 1px solid $boxContainerBackgroundII !important;
          margin-left: 24px;
          display: table-cell;
        }
      }

      .info {
        display: table-cell;
        padding-left: 38px;
        vertical-align: middle;

        .headline {
          font-family: $secRegularFontFamily;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          font-size: 18px;
          color: $textI;
          padding-bottom: 5px;
          border-bottom: 1px solid $boxContainerBackground;
          min-height: 46px;
        }

        .underline {
          font-family: $secRegularFontFamily;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          font-size: 12px;
          color: $underline;
        }
      }
    }

    .container-holder {
      background-color: $containerHolderColor;
      box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);
      border-radius: 5px;
      position: relative;
      padding-bottom: 26px;

      .extend-menu {
        position: absolute;
        width: 18px;
        height: 5px;
        background-image: url("images/platform/ikona-tri-pikice@3x.png");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-size: contain !important;
        top: 25px;
        right: 25px;
      }

      a {
        display: inline-block;
      }
    }
  }
}

.quick-action {
  position: absolute;
  box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);
  height: 50px;
  left: 200px;
  top: 300px;
  border-radius: 5px;
  background-color: $selectPanelBackground;
  z-index: 11;
  display: none;

  * {
    box-sizing: border-box;
  }

  div {
    display: inline-block;
    vertical-align: top;
    height: 50px;
    width: 50px;
    border-top: 4px solid transparent;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    cursor: pointer;

    &:hover {
      background-color: $sectionTitleBackgroundColor;
      border-top: 4px solid $highlightColor;
    }
  }

  .qa-add {
    background-image: url("images/platform/ikona-plus@3x.png");
  }

  .qa-list {
    background-image: url("images/platform/ikona-seznam@3x.png");
  }

  .qa-search {
    background-image: url("images/platform/ikona-iskanje@3x.png");
  }

  .qa-favorite {
    background-image: url("images/platform/ikona-priljubljeni@3x.png");
  }

  a {
    background-image: none !important;
  }
}

.breadcrumbs {
  position: absolute;
  left: 35px;
  height: 100%;
  display: inline-table;
  table-layout: fixed;

  p {
    vertical-align: middle;
    display: table-cell;
    padding-right: 17px;
    font-family: $secRegularFontFamily;
    font-size: 20px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 2.5px;
    color: $textI;
    text-transform: uppercase;

    &:last-of-type {
      font-family: $secRegularFontFamily;
    }
  }
}

.sub-h {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.8), 0px 0 8px -4px rgba(0, 0, 0, 0.8);
}

.sub-nav-menu {
  position: fixed;
  height: 45px;
  width: 100%;
  background-color: $navigationHeaderBackgroundColor;
  border: solid 2px $lightBottomBorder;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  z-index: 8;

  .menu-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
    padding-left: 108px;

    .alert {
      position: fixed;
      right: 40px;
      top: 74px;
      bottom: 0;
      background-image: url("images/platform/caution-triangular-red@3x.png");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 24px;
      height: 20px;
      cursor: pointer;
    }

    .back {
      width: 85px !important;
      height: 100%;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("images/platform/arrow_left_menu@3x.png");
      background-size: 7px 12px;
      padding: 0 !important;
      position: relative;
      cursor: pointer;

      &:after {
        content: "";
        position: absolute;
        right: 0;
        height: 60%;
        top: 20%;
        width: 1px;
        background-color: $lightBottomBorder;
      }
    }

    a {
      height: 46px;
      display: inline-table;
      vertical-align: top;
      float: left;
      text-align: center;
      padding: 0 28px 0 28px !important;

      p {
        display: table-cell;
        vertical-align: middle;
        font-family: $secRegularFontFamily;
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1px;
        color: $textI;
        text-transform: uppercase;
      }
    }

    div {
      height: 45px;
      display: inline-table;
      vertical-align: top;
      float: left;
      text-align: center;

      p {
        display: table-cell;
        vertical-align: middle;
        font-family: $secRegularFontFamily;
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1px;
        color: $textI;
        text-transform: uppercase;
      }
    }

    button {
      height: 100%;
      display: inline-table;
      vertical-align: top;
      float: left;
      text-align: center;
      padding: 0 28px 0 28px !important;
      vertical-align: middle;
      font-family: $secRegularFontFamily;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: $textI;
      text-transform: uppercase;
      background: transparent;
      border: none !important;
      line-height: 45px;
    }
  }
}

.plus {
  background-image: url("images/platform/ikona-table-add@3x.png");
}

.search-24 {
  background-image: url("images/platform/filter24@3x.png");
}

.search-24-reset {
  background-image: url("images/platform/filter24-reset@3x.png");
}

.search {
  background-image: url("images/platform/ikona-iskanje-tools@3x.png");
}

.editor-btn {
  background-image: url("images/platform/edit-blue@3x.png");
}

.close-btn {
  padding-top: 14px;
}

.sub-content {
  padding-top: 32px;

  .inner-item-wrapper {
    height: auto !important;
  }

  .search-control {
    padding-top: 26px;
    vertical-align: top !important;
    text-align: left;
    line-height: initial !important;

    .search-item {
      margin-right: 0;
      margin-left: 0;
      display: inline-block;
      width: 33%;
      box-sizing: border-box;

      sem-drop-down-select {
        position: relative;
        left: 0px;

        .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
          opacity: 0 !important;
        }

        .mat-mdc-form-field-focus-overlay {
          background-color: transparent !important;
        }

        .mdc-line-ripple {
          display: none;
        }

        .mdc-text-field {
          padding: 0px !important;
          width: 100% !important;
        }
      }



      .dd-loader {
        height: 18px;
        width: 18px;
        top: 0;
      }

      .autocomplete-loader {
        top: 0;
      }

      br {
        display: none;
      }

      /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-appearance-legacy .mat-form-field-wrapper {
        padding-bottom: 0;
      }

      /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-appearance-legacy .mat-form-field-infix {
        padding: 0;
      }

      sem-drop-down-select .mat-mdc-text-field-wrapper {
        margin: 0;
        height: auto;
      }

      /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-wrapper {
        margin-bottom: 0;
        height: auto;
      }

      .mat-mdc-form-field {
        min-height: auto;
      }

      .sem-dynamic-field-content> {
        label {
          width: 25%;
          margin: 0 0 0 0 !important;
          padding-right: 10px;
        }

        div {
          margin: 0 0 0 0 !important;
        }
      }

      p {
        display: inline-block;
        vertical-align: top;
        font-family: $secRegularFontFamily;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.92;
        letter-spacing: 0.8px;
        color: $textI;
        text-transform: uppercase;
        margin-right: 16px;
        padding-top: 6px;
      }

      input {
        display: inline-block;
        vertical-align: middle;
        border: solid 1px $inputBackgroundII !important;
        border-radius: 5px;
        width: 100% !important;
        height: 25px;
        z-index: 1 !important;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
      }

      /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-infix {
        width: auto;
      }
    }

    .wrapper {
      width: 90% !important;
    }

    .dd-search {
      margin-top: 0 !important;
    }

    .search-selector {
      vertical-align: top !important;
    }
  }

  .sem-hero-component {
    margin-bottom: 32px;
    margin-left: 143px;
    margin-right: 35px;
    height: auto;
  }

  .item-container {
    margin-left: 143px;
    margin-right: 35px;
    height: auto;

    input {
      padding: 0 10px 0 16px;
      vertical-align: middle !important;
      outline: none;
    }

    .item-side {
      margin: 26px 60px 26px 17px;
      border-right: 1px solid $lightBottomBorder;
      text-align: left;
      padding-bottom: 40px;
      min-width: 170px;

      h2 {
        font-family: $secRegularFontFamily;
        font-size: 15px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $textI;
        margin-bottom: 13px;
      }

      .item-option {
        padding-top: 15px;
        display: table;
        table-layout: fixed;

        .img {
          width: 17px;
          height: 17px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          display: table-cell;
          vertical-align: middle;
        }

        p {
          display: table-cell;
          vertical-align: middle;
          font-family: $secRegularFontFamily;
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 1px;
          color: $highlightColor;
          padding-left: 8px;
        }
      }
    }
  }
}

.modules {
  width: 175px;
  height: 200px;
  padding-left: 17px;
  background-color: $selectPanelBackground;
  border-radius: 5px;

  .title {
    font-family: $secRegularFontFamily;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: $highlightColor;
    text-transform: uppercase;
    padding-top: 22px;
    padding-bottom: 15px;
    text-align: left;

    span {
      color: $modulesBackgroundColor;
    }
  }
}

.delete {
  background-image: url("images/platform/ikona-brisi@3x.png");
}

.grid-table {
  border-radius: 5px;
  margin-bottom: 10px;

  sem-image-viewer {
    min-height: 90px;
    display: block;
  }

  .title {
    font-family: $secRegularFontFamily;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: $highlightColor;
    text-transform: uppercase;
    padding-top: 22px;
    padding-bottom: 15px;
    text-align: left;
    padding-left: 42px;
    padding-top: 0;
  }

  .dd-container {
    text-align: left;
    height: 55px;
    display: inline-block;
    vertical-align: top;

    .dd-grid {
      position: relative;
      display: inline-block;
      margin-top: 15px;
      padding-right: 34px;
      padding-left: 14px;
      border: 1px solid $highlightColor;
      border-radius: 5px;
      height: 25px;
      vertical-align: middle;
      cursor: pointer;

      &:after {
        content: "";
        position: absolute;
        background-image: url("images/platform/dd-blue-icon@3x.png");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 12px;
        height: 7px;
        right: 16px;
        right: 11px;
        top: 9px;
      }

      &:first-of-type {
        margin: 15px 24px 0 32px;
      }

      p {
        font-family: $secRegularFontFamily;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.92;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $highlightColor;
        vertical-align: middle;
        line-height: 24px;
      }
    }
  }

  .table-controls {
    height: 25px;
    width: auto;
    margin-left: auto;
    margin-right: 0;
    text-align: right;
    display: inline-block;
    vertical-align: top;
    float: right;
    padding-right: 18px;

    .central {
      border-right: solid 1px $lightBottomBorder;
      border-left: solid 1px $lightBottomBorder;
      width: 57px;
      height: 100%;
      display: inline-block;
      text-align: center;
      width: 62px !important;
    }

    .arrange {
      height: 100%;
      display: inline-block;
      text-align: center;
    }

    .left-right {
      margin-right: 14px;
      width: 57px;
      height: 100%;
      display: inline-block;
      text-align: center;
    }

    .icon {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 15px;
      width: 15px;
      display: inline-block;
      margin-top: 4px;
      cursor: pointer;
      top: 5px;
    }

    .left {
      background-image: url("images/platform/blue-icon-left@3x.png");
      margin-right: 3px;
    }

    .right {
      background-image: url("images/platform/blue-icon-right@3x.png");
    }

    .centraled {
      background-image: url("images/platform/ikona-nastavitve@3x.png");
      height: 16px !important;
      width: 16px !important;
    }

    .list {
      background-image: url("images/platform/ikona-seznam-grid@3x.png");
      margin-right: 18px;
    }

    .cube {
      background-image: url("images/platform/ikona-cube@3x.png");
    }

    /*.settings{
      background-image: url("images/platform/ikona-nastavitve@3x.png");
    }*/
  }

  .one-cube {
    margin-left: 18px;
  }

  .table-manage {
    display: inline-block;
    text-align: right;
    height: 55px;

    >div {
      height: 100%;
      padding-left: 40px;
    }

    span {
      display: inline-block;
      font-family: $secRegularFontFamily;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: $underline;
    }

    * {
      vertical-align: middle;
    }

    p {
      font-family: $secRegularFontFamily;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: $underline;
    }
  }

  .table-container {
    height: 100%;
    width: 100%;
    background-color: $selectPanelBackground;
    border-radius: 5px;
    text-align: left;

    .controls {
      display: inline-table;
      height: 25px;
      width: 100%;

      .edit {
        background-image: url("images/platform/ikona-edit@3x.png");
      }

      .settings {
        background-image: url("images/platform/ikona-settings@3x.png");
        border-left: 1px solid $lightBottomBorder;
        border-right: 1px solid $lightBottomBorder;
      }

      a {
        height: 20px;
        width: 100% !important;
        display: block !important;
        display: table-cell;
        vertical-align: middle;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-size: 13px 13px;
        min-width: 13px;
        min-height: 13px;
        padding: 0 10px 0 10px !important;
      }

      >div {
        display: table-cell;
        vertical-align: middle;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-size: 13px 13px;
        min-width: 13px;
        min-height: 13px;
        padding: 0 10px 0 10px !important;
      }
    }

    table {
      height: 100%;
      width: 100%;
      border-collapse: collapse;

      th {
        font-family: $secRegularFontFamily;
        font-size: 13px;
        text-transform: uppercase;
        background-color: $tableHeaderBackgroundColor;
        font-weight: normal !important;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $textI;
        border-bottom: solid 1px $boxContainerBackground;
        border-right: solid 1px $boxContainerBackground;
        padding-left: 42px;
        vertical-align: middle;
        height: 50px;

        &:first-of-type {
          border-left: 0 !important;
        }

        &:last-of-type {
          border-right: 0 !important;
        }
      }

      td {
        font-family: $secRegularFontFamily;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $textI;
        position: relative;
        padding: 5px 0 5px 0;
        border-top: solid 1px $boxContainerBackground;
        border-bottom: solid 1px $boxContainerBackground;
        border-right: solid 1px $boxContainerBackground;
        padding-left: 42px;
        vertical-align: middle;
        height: 50px;
        padding-right: 42px;
        white-space: nowrap;

        &:first-of-type {
          border-left: 0 !important;
        }

        &:last-of-type {
          border-right: 0 !important;
          padding-left: 20px !important;
          padding-right: 20px !important;
          cursor: default !important;
        }

        div {
          span {
            overflow: hidden !important;
            text-overflow: ellipsis;
            max-width: 150px;
            display: block;
          }
        }
      }

      tr {
        &:hover>td {
          background-color: $sectionTitleBackgroundColor;

          &:last-of-type {
            background-color: transparent;
          }
        }

        &:nth-child(even) {
          background-color: $tableNBackgroundColor;
        }
      }

      &.users td:last-of-type {
        padding-left: 0 !important;
      }
    }
  }
}

.cb-container {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 0;
    height: 14px !important;
    width: 14px !important;
    margin: 0 !important;

    &:checked~.checkmark:after {
      display: block;
    }
  }

  .checkmark:after {
    left: 1px;
    top: 1px;
    width: 10px;
    height: 10px;
    background-image: url("images/platform/ikona-kljukica@3x.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  border: 1px solid $modulesBackgroundColor;
  border-radius: 2px;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

.side-wrapper {
  display: table;

  p {
    padding-right: 10px !important;
  }

  input {
    margin: 0 !important;
  }

  label {
    display: table !important;
    font-family: $secRegularFontFamily;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $generalParagraphTextColor;

    * {
      display: table-cell;
      vertical-align: middle;
    }
  }

  * {
    display: table-cell;
    vertical-align: middle;
  }
}

.sub .containers {
  width: 94%;
  display: inline-table;
  text-align: center;

  >div {
    display: table-cell;
  }
}

.table-footer {
  height: 55px;
  text-align: center;

  .right,
  .left,
  .right-double,
  .left-double {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 9px;
    width: 5px;
    display: inline-block;
    vertical-align: middle;
    margin-top: 21px;
    cursor: pointer;
  }

  .left {
    background-image: url("images/platform/icon-arrow-left@3x.png");
  }

  .left-double {
    width: 10px;
    height: 13px;
    background-image: url("images/platform/double-arrow-left@3x.png");
  }

  .right {
    background-image: url("images/platform/icon-arrow-right@3x.png");
  }

  .right-double {
    width: 10px;
    height: 13px;
    padding-left: 25px !important;
    background-image: url("images/platform/double-arrow-right@3x.png");
  }


  p {
    display: inline-block;
    font-family: $secRegularFontFamily;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $textI;
    padding-left: 25px;
    vertical-align: middle;
    padding-top: 21px;
    cursor: pointer;

    &:last-of-type {
      padding-right: 25px;
    }

    &.selected {
      color: $highlightColor;
    }
  }
}

.status {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
}

.status-indicator {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}

.st-active:after {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  background-color: $statusActive;
}

.st-idle:after {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  background-color: $statusIdle;
}

.st-inactive:after {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  background-color: $statusInActive;
}

.collapsed .item {
  width: 100% !important;
}

.nav-item {
  cursor: pointer;
  position: relative;
  display: block;

  &:hover {
    background-color: $sectionTitleBackgroundColor !important;

    >p {
      color: $highlightColor !important;
    }
  }
}

.navi-sub-menu {
  position: absolute;
  left: 0;
  top: 100%;
  background-color: $selectPanelBackground;
  text-align: left;
  padding: 0 !important;
  min-width: 150px;
  box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);
  display: none;

  p {
    letter-spacing: normal !important;
  }
}

.nav-sub-menu-item {
  width: 100%;
  height: 45px;
  display: table;
  table-layout: fixed;
  border-left: 4px solid transparent;
  position: relative;
  box-sizing: border-box;

  &:hover {
    border-left: 4px solid $highlightColor;
    background-color: $sectionTitleBackgroundColor;
  }

  p {
    width: 147px !important;
    display: table-cell;
    vertical-align: middle;
    font-family: $secRegularFontFamily;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $textI !important;
    text-transform: none !important;

    &:hover {
      color: $textI !important;
    }
  }

  .navi-sub-menu {
    left: 100%;
    top: 0;
    background-color: $selectPanelBackground;
    min-width: 120px !important;
    box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6) !important;

    .nav-sub-menu-item {
      border: 0 !important;
      background-color: $selectPanelBackground;
      width: 100%;

      p {
        width: initial !important;
      }

      &:hover {
        background-color: $sectionTitleBackgroundColor;
      }
    }
  }

  .icon {
    display: table-cell;
    vertical-align: middle;
    height: 45px;
    width: 45px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 18px 18px;
    background-image: url("images/platform/avatar@3x.png");
  }
}

.dropdown-indicator {
  content: "";
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 11px;
  width: 6px;
}

.nav-dropdown {
  &:after {
    content: "";
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 11px;
    width: 6px;
    background-image: url("images/platform/icon-arrow-right@3x.png");
  }

  &:hover:after {
    content: "";
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 11px;
    width: 6px;
    background-image: url("images/platform/icon-orange-right@3x.png");
    height: 12px;
    width: 7px;
  }
}

.main-input-form {
  display: table-cell;
  vertical-align: top;
  text-align: left;

  .input-wrapper {
    box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);
    background-color: $selectPanelBackground;
    border-radius: 5px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    vertical-align: top;
    padding-bottom: 90px;
    position: relative;

    .frm-section {
      margin-bottom: 10px !important;
      background-color: $sectionTitleBackgroundColor;
      color: $mainInputText;
      height: 28px;
      padding-top: 5px;
      padding-left: 6px;
    }

    .tab-button {
      background-color: $sectionTitleBackgroundColor;
    }
  }

  * {
    box-sizing: border-box;
  }

  .user-icon {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
  }

  .tab-menu {
    border-right: 2px solid $lightBottomBorder;
    text-align: left;
    display: table-cell;
    min-width: 180px;
    max-width: 180px;
    width: 180px;
    vertical-align: top;

    .tab {
      vertical-align: top;
      font-family: $secRegularFontFamily;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $textI;
      text-align: center;
      margin-right: -2px;
      border-right: solid 2px transparent;
      cursor: pointer;
      text-align: left;
      min-height: 35px;
      padding: 15px 8px 15px 30px;

      p:hover {
        opacity: 0.8;
      }

      &:hover,
      &.active {
        border-right: solid 2px $highlightColor;
        background-color: $sectionTitleBackgroundColor;
      }

      &:hover p:hover,
      &.active p:hover {
        opacity: 1;
      }
    }
  }

  .menu-input-wrapper {
    display: table;
    table-layout: fixed;
    margin-top: 41px;
    width: 100%;
  }

  .input-content {
    display: table-cell;
    width: 100%;
    padding-left: 25px;
    padding-right: 30px;
  }

  .section-title {
    font-family: $secRegularFontFamily;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $highlightColor !important;
    margin-top: 10px;
    margin-bottom: 20px;
    text-transform: uppercase;
    display: block;

    &:first-of-type {
      margin-top: 5px;
    }
  }

  .section-wrapper {
    position: relative;

    .additional {
      position: absolute;
      height: 100%;
      right: 0;
      top: 0;

      .checkmark {
        height: 20px;
        width: 20px;
        background-color: $highlightColor;
        border: none !important;
        right: 0;
        left: unset;
      }

      .cb-container .checkmark:after {
        left: 3px;
        top: 2px;
        width: 14px;
        height: 16px;
        border-radius: 4px;
        background-image: url(images/platform/cb-kljukica-bela@3x.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }

      label {
        font-family: $secRegularFontFamily;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $textI;
        padding-top: 2px;
        padding-right: 33px;
      }
    }
  }

  .input-control {
    width: 100%;
    position: relative;
    text-align: left;

    .title,
    label,
    p {
      font-family: $secRegularFontFamily;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $textI;
      position: relative;
    }

    th {
      font-family: $secRegularFontFamily;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $textI;
      position: relative;
      font-family: $secRegularFontFamily;
      padding-bottom: 10px;
    }

    .checkbox label {
      padding-left: 25px;
    }

    .required {
      padding-left: 8px;

      &:after {
        content: "*";
        color: $highlightColor;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    .limit {
      font-family: $secRegularFontFamily;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: $highlightColor;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;

      span {
        font-family: $secRegularFontFamily;
      }
    }

    .not-editable {
      border: 0 !important;
      border-radius: 3px;
      border: solid 1px $boxContainerBackground;
      height: 40px !important;
      width: 100%;
      margin-top: 6px;
      font-family: $secRegularFontFamily;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $textI;
      vertical-align: middle;
      margin-bottom: 22px;
      background-color: transparent !important;
      z-index: 99;
      cursor: pointer;
    }

    input {
      border-radius: 3px;
      border: solid 1px $boxContainerBackground;
      height: 40px !important;
      width: 100%;
      margin-top: 6px;
      font-family: $secRegularFontFamily;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $textI;
      vertical-align: middle;
      margin-bottom: 22px;
      background-color: transparent !important;
      z-index: 99;
      cursor: pointer;

      &:focus {
        border-color: $highlightColor;
        outline: 0 none;
      }
    }

    select {
      border-radius: 3px;
      border: solid 1px $boxContainerBackground;
      height: 40px !important;
      width: 100%;
      margin-top: 6px;
      font-family: $secRegularFontFamily;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $textI;
      vertical-align: middle;
      margin-bottom: 22px;
      background-color: transparent !important;
      z-index: 99;
      cursor: pointer;
    }

    .upload-wrapper {
      border-radius: 3px;
      border: dashed 2px $boxContainerBackground;
      width: 100%;
      margin-top: 12px;
      font-family: $secRegularFontFamily;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $textI;
      vertical-align: middle;
      margin-bottom: 22px;
      background-color: transparent !important;
      z-index: 99;
      cursor: pointer;

      input {
        margin-top: 0 !important;
      }
    }
  }

  .side-input-container {
    width: 50%;
    display: inline-block;
    vertical-align: top;

    &:first-of-type {
      padding-right: 12.5px;
    }

    &:last-of-type {
      padding-left: 12.5px;
    }
  }
}

.input-validation-error {
  border: 1px solid $highlightColor !important;
  background-image: url("images/platform/ikona-napaka@3x.png");
  background-position: 95% 50%;
  background-repeat: no-repeat;
  background-size: 22px 18px;
}

.side-input-form {
  display: table-cell;
  vertical-align: top;
  width: 25%;
  min-width: 300px;
  max-width: 400px;
  padding-left: 36px;

  .input-wrapper {
    box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);
    background-color: $selectPanelBackground;
    border-radius: 5px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    vertical-align: top;
    padding-bottom: 23px;
    position: relative;
    margin-bottom: 32px;
  }

  .frm-section {
    background-color: $highlightColor;
  }

  .files {
    border-collapse: collapse;
    text-align: left;
    width: 100%;

    th {
      padding-left: 17px;
      padding-right: 20px;
      height: 30px;
      vertical-align: middle;
      font-family: $secRegularFontFamily;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $textI;
      border-right: 1px solid $lightBottomBorder;

      &:first-of-type {
        padding-left: 57px;
      }

      &:last-of-type {
        border-right: 0 !important;
      }
    }

    td {
      padding-left: 17px;
      padding-right: 20px;
      height: 30px;
      vertical-align: middle;
      font-family: $secRegularFontFamily;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $textI;
      position: relative;
      border-right: 1px solid $lightBottomBorder;

      &:first-of-type {
        padding-left: 57px;
      }

      &:last-of-type {
        border-right: 0 !important;
      }
    }

    tr {
      &:first-of-type:hover {
        background-color: transparent !important;
      }

      &:hover {
        background-color: $sectionTitleBackgroundColor;
      }
    }
  }
}

.edit-form-title-wrapper {
  padding-left: 30px;
  padding-top: 32px;
}

.input-form-title {
  font-family: $secRegularFontFamily;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: $textI;
  padding-left: 16px;
}

.btn-add-nc {
  margin-left: 2.5%;

  a {
    font-family: $secRegularFontFamily;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $highlightColor !important;
    margin-top: 10px;
    margin-bottom: 20px;
    text-transform: uppercase;
    display: block;
  }
}

.additional-user-rights {
  padding: 0 !important;
}

.table-add {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("images/platform/ikona-table-add@3x.png");
  height: 20px;
  width: 20px;
  display: block;
}

.input-table-wrapper {
  padding-right: 15%;
  min-width: 540px;
}

.table-bottom {
  height: 20px;
  margin-top: 16px;
  position: relative;
  margin-bottom: 32px;
}

.contact-info {
  margin-bottom: 0 !important;
}

.table-bottom-options {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 100% !important;
  box-sizing: border-box;

  .edit {
    display: inline-block;
    padding: 0 12px 0 12px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    background-image: url("images/platform/edit-blue@3x.png");
  }

  .duplicate {
    display: inline-block;
    padding: 0 12px 0 12px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    background-image: url("images/platform/duplicate@3x.png");
  }

  .delete {
    display: inline-block;
    padding: 0 12px 0 12px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    background-image: url("images/platform/trash-blue@3x.png");
  }

  .separator {
    width: 1px;
    height: 100%;
    background-color: $lightBottomBorder;
    margin-left: 5px;
    margin-right: 5px;
    display: inline-block;
  }
}

.input-table {
  border-radius: 3px;
  border: solid 1px $boxContainerBackground;

  th {
    font-family: $secRegularFontFamily;
    font-weight: 500;
    padding-right: 12px;
    vertical-align: top !important;
  }

  td {
    font-family: $secRegularFontFamily;
  }

  .table-dropdown {
    text-align: left;
    position: relative;
    border-radius: 3px;
    border: solid 1px $boxContainerBackground;
    cursor: pointer;
    height: 20px;
    width: 77px;

    p {
      font-family: $secRegularFontFamily;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $textI;
      line-height: 20px;
      padding-left: 4px;
    }

    &:after {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      content: "";
      position: absolute;
      right: 7px;
      top: 0;
      bottom: 0;
      margin: auto;
      background-image: url("images/platform/dd-table-down@3x.png");
      width: 7px;
      height: 4px;
    }
  }

  .reduce {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  table {
    border-collapse: collapse;
    margin: 8px 0 8px 0;
    padding: 0 8px 0 8px;
    width: 100%;

    th,
    td {
      border-right: 1px solid $boxContainerBackground;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $textI;
      font-size: 12px;
      padding-left: 8px;
      height: 30px;
      vertical-align: middle;
    }

    th:last-of-type,
    td:last-of-type {
      border: 0 !important;
      padding-right: 5%;
    }

    tr {
      &:first-of-type:hover {
        background-color: transparent;
      }

      &:hover {
        background-color: $sectionTitleBackgroundColor;
      }
    }
  }
}

.side-info-table {
  .edit-form-title-wrapper {
    padding-top: 16px !important;
    padding-left: 25px !important;
    margin-bottom: 12.5px;
  }

  .input-form-title {
    text-transform: uppercase;
    padding-left: 0 !important;
  }

  .img-options {
    height: 42px;
    border-top: 1px solid $lightBottomBorder;
    border-bottom: 1px solid $lightBottomBorder;
    padding: 7.5px 0 7.5px 0;
    text-align: center;
    margin-bottom: 23px;

    input {
      cursor: pointer;
    }

    >div {
      border-right: 1px solid $lightBottomBorder;
      height: 100%;
      width: 45px;
      display: inline-block;
      position: relative;

      &:last-of-type {
        border-right: 0 !important;
      }

      >div {
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 18px;
        width: 18px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        cursor: pointer;
      }
    }
  }

  .main-img {
    width: 140px;
    height: 175px;
    margin: auto;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .img-list {
    text-align: center;
    margin: auto;
    margin-top: 23px;
    position: relative;

    .left,
    .right {
      position: absolute;
      top: 48px;
    }

    .left {
      width: 8px !important;
      height: 15px !important;
      background-position: center !important;
      background-size: contain !important;
      background-repeat: no-repeat;
      margin: auto;
      vertical-align: middle;
      width: 9px;
      display: inline-block;
      margin-top: -33px;
      margin-right: 15px !important;
      left: 0;
    }

    .right {
      width: 8px !important;
      height: 15px !important;
      background-position: center !important;
      background-size: contain !important;
      background-repeat: no-repeat;
      margin: auto;
      vertical-align: middle;
      width: 9px;
      display: inline-block;
      margin-top: -33px;
      margin-left: 7px !important;
      right: 0;
    }

    div>div,
    .left,
    .right {
      height: 45px;
      width: 45px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      display: inline-block;
      margin-right: 8px;
      background-size: cover;
      cursor: pointer;
    }
  }
}

.icon>div {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 17px;
  width: 17px;
  position: absolute;
  left: 25px;
  top: 0;
  bottom: 0;
  margin: auto;
  background-image: url("images/platform/general-file-icon@3x.png");
}

.xml>div {
  background-image: url("images/platform/xml-copy@3x.png") !important;
}

.xlsx>div,
.xls>div {
  background-image: url("images/platform/xls-copy@3x.png") !important;
}

.doc>div {
  background-image: url("images/platform/doc-copy@3x.png") !important;
}

.pdf>div {
  background-image: url("images/platform/pdf-copy@3x.png") !important;
}

.search {
  background-image: url("images/platform/zoom-in-copy@3x.png");
}

.download {
  background-image: url("images/platform/download-copy@3x.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 22px;
}

.set-as-default-full {
  background-image: url("images/platform/star-full@3x.png");
}

.set-as-default-empty {
  background-image: url("images/platform/star-empty@3x.png");
}

.delete {
  background-image: url("images/platform/trash-copy@3x.png");
}

.add {
  background-image: url("images/platform/ikona-table-add@2x.png");

  input {
    padding: 0 !important;
    opacity: 0;
    width: 50px;
  }
}

.left {
  background-image: url("images/platform/icon-arrow-left@3x.png");
}

.right {
  background-image: url("images/platform/icon-arrow-right@3x.png");
}

.dropdown-control {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  background-size: 12px 6px !important;
  background-position: 97% center !important;
  background-repeat: no-repeat !important;
  background-image: url("images/platform/dd-table-down@3x.png") !important;
  padding-right: 15px !important;
  cursor: pointer !important;
}

.dropdown-control-container {
  box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);
  border-radius: 3px;
  background-color: white !important;
  text-align: left;
  position: absolute;
  width: 100%;
  top: 80%;
  z-index: 7;
  display: none;

  p {
    font-family: $secRegularFontFamily;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $textI;
    height: 37px;
    width: 100%;
    cursor: pointer;
    line-height: 37px;
    padding-left: 12px;

    &:hover {
      background-color: $sectionTitleBackgroundColor;
    }
  }
}

.search-input {
  background: none !important;
}

.overlay {
  background-color: rgba(49, 53, 65, 0.25);
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 11;
  display: none;

  .warning-overlay,
  .notification-overlay {
    width: 350px;
    height: 175px;
    display: none;
  }

  .confirmation-overlay {
    width: 350px;
    height: 270px;
  }

  .overlay-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    border-radius: 5px;
    background-color: $selectPanelBackground;
    box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);

    .inner-overlay-container {
      position: relative;

      .close {
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 13px;
        width: 13px;
        position: absolute;
        right: 28px;
        top: 20px;
        background-image: url("images/platform/icon-close@3x.png");
        cursor: pointer;
      }

      h1 {
        font-family: $secRegularFontFamily;
        font-size: 18px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1px;
        color: $textI;
        padding: 15px 0 0 30px;
        text-transform: uppercase;
        margin-bottom: 15px;
      }

      .overlay-content-container {
        position: relative;
        padding-top: 17px;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: $lightBottomBorder;
        }
      }

      .warning .confirm {
        color: $statusInActive;
      }

      .notification .confirm {
        color: $statusIdle;
      }

      .confirmation .confirm {
        color: $statusActive;
      }

      .icon {
        display: inline-block;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 40px;
        width: 40px;
        margin-left: 27px;
        margin-right: 30px;
        vertical-align: top;

        a {
          font-family: $secRegularFontFamily;
          font-size: 13px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          display: block;
          cursor: pointer;
        }

        p {
          font-family: $secRegularFontFamily;
          font-size: 13px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          display: block;
          cursor: pointer;
          color: $textI;

          &:first-of-type {
            max-width: 235px;
          }
        }

        .confirm {
          font-family: $secRegularFontFamily;
          font-size: 13px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 1px;
          text-transform: uppercase;
        }

        .cancel {
          font-family: $secRegularFontFamily;
          font-size: 13px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: $underline;
        }
      }

      .side-content {
        display: inline-block;

        a {
          font-family: $secRegularFontFamily;
          font-size: 13px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          display: block;
          cursor: pointer;
        }

        p {
          font-family: $secRegularFontFamily;
          font-size: 13px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          display: block;
          cursor: pointer;
          color: $textI;

          &:first-of-type {
            max-width: 235px;
          }
        }

        .confirm {
          font-family: $secRegularFontFamily;
          font-size: 13px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 1px;
          text-transform: uppercase;
        }

        .cancel {
          font-family: $secRegularFontFamily;
          font-size: 13px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: $underline;
        }
      }

      .icon-warning {
        background-image: url("images/platform/warning-icon@3x.png");
      }

      .icon-notification {
        background-image: url("images/platform/notification-icon@3x.png");
      }

      .icon-confirmation {
        background-image: url("images/platform/confirmation-icon@3x.png");
      }

      .btn-container {
        margin-top: 32px;

        .btn {
          display: inline-block;

          &:first-of-type {
            margin-right: 41px;
          }
        }
      }
    }
  }

  input {
    border-radius: 2px;
    border: solid 1px $lightBottomBorder;
    display: block;
    width: 197px;
    height: 30px;
    padding-left: 10px;
    font-family: $secRegularFontFamily;
    color: $underline;
    outline: none;

    &:first-of-type {
      margin-top: 17px;
      margin-bottom: 8px;
    }
  }

  &::-webkit-input-placeholder {
    color: $underline !important;
  }

  &:-ms-input-placeholder {
    color: $underline !important;
  }

  &::placeholder,
  &::-ms-input-placeholder {
    color: $underline !important;
  }
}

.alerts {
  width: 100%;
  height: auto;
  text-align: left;
  z-index: 7;
  display: none;
  min-height: 80px;

  p {
    vertical-align: middle;
    font-family: $secRegularFontFamily;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $selectPanelBackground;
    height: 80px;
    line-height: 90px;
    display: inline-block;
    max-width: 70%;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .icon {
    vertical-align: middle;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 130px;
    background-size: 24px 20px;
    display: table-cell;
    background-image: url("images/platform/caution-triangular@3x.png");
    vertical-align: top;
  }

  .close {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 15px;
    width: 15px;
    background-image: url("images/platform/close-white@3x.png");
    position: fixed;
    right: 45px;
    top: 98px;
    cursor: pointer;
    opacity: 1 !important;
  }
}

.validation-summary-errors {
  vertical-align: middle;
  padding-bottom: 10px;

  ul li {
    font-family: $secRegularFontFamily;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $selectPanelBackground;
    padding-bottom: 5px;
  }
}

.cmds,
.special-cmds {
  text-align: center;
  padding-left: 0 !important;
}

.ti-icon {
  margin: auto;
  height: 15px;
  width: 15px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: inline-block;
  padding-right: 20px;
}

.ti-settings {
  background-image: url("images/platform/ikona-settings@3x.png");
  margin-right: 18px;
}

.ti-trash {
  background-image: url("images/platform/ikona-brisi@3x.png");
}

.ti-edit {
  background-image: url("images/platform/ikona-edit@3x.png");
}

.ti-redirect {
  background-image: url("images/platform/icon-collapse@3x.png");
}

.layout-info {
  display: none;
}

.panel-group {
  padding-left: 42px;
  padding-left: 0 !important;
}

.container-rb {
  display: inline-block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 10px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked~.checkmark-rb {
      background-color: $highlightColor;
      border: 1px solid $highlightColor;

      &:after {
        display: block;
      }
    }
  }

  span.circle {
    position: absolute;
    height: 8px;
    width: 8px;
    background-color: $selectPanelBackground;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    border-radius: 50%;
  }

  .checkmark-rb {
    position: absolute;
    top: 20px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid $boxContainerBackground;
    border-radius: 50%;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  &:hover input~.checkmark-rb {
    border: 1px solid $highlightColor;
  }
}

.addi-controls-wrapper,
.table-files {
  margin-top: 12px;
}

.wrapper form {
  width: 100%;
}

.alert-danger {
  background-color: $statusInActive;
  display: inline-table !important;
}

.alert-warning {
  background-color: $alertWarning;
  display: inline-table !important;
}

.alert-success {
  background-color: $statusActive;
  display: inline-table !important;
}

.upload-wrapper {
  width: 100%;
  position: relative;
  height: 100%;

  input {
    opacity: 0;
  }

  .checkmark-upload {
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("images/platform/download-copy@3x.png");
    height: 18px;
    width: 18px;
  }
}

.collapsed {
  .sidebar {
    width: 108px;
  }

  .content {
    left: 0px;
    width: auto;
  }

  .logo img {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    margin-top: 19px !important;
    display: block !important;
  }

  header {
    left: 108px !important;
    padding-left: 0;
  }

  .item-wrapper .item p,
  .logo p {
    display: none;
  }

  .main-node-item {
    border: 0;
  }
}

.side-input-form {
  .upload {
    box-sizing: border-box;
    padding: 0 23px
  }

  .main-img {
    display: none;
  }

  .afu-dragndrop-box {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    aspect-ratio: 1/1;
    position: relative;
    border: 0 !important;
    margin: auto;

    .icon {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("images/platform/img-upload@3x.png");
      position: absolute;
      top: -15%;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      height: 22px;
      width: 22px;
    }

    p {
      position: absolute;
      top: 55%;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      vertical-align: middle;
      font-family: $regularFontFamily;
      font-size: 12px;
      font-weight: normal !important;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.8px;
      color: $underline !important;
      text-align: center;
    }
  }

  .up-controls {
    text-align: center;
    margin-top: 24px;

    >* {
      display: inline-block;
      vertical-align: middle;
      font-family: $regularFontFamily;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.8px;
      color: $underline;
    }

    a {
      border: 1px solid $lightBottomBorder;
      text-transform: uppercase;
      height: 25px;
      padding: 0 10px 0 10px;
      line-height: 25px;
      margin-left: 8px;
      border-radius: 3px;
    }
  }

  .up-editable {
    a {
      color: $highlightColor !important;
      border: 1px solid $highlightColor !important;
    }

    .upload-window {
      background-image: url("images/platform/upload-content-blue@3x.png");
    }
  }
}

.disabled .search,
.up-editable .search {
  background-image: url("images/platform/zoom-in-grayed@3x.png") !important;
}

.disabled .download,
.up-editable .download {
  background-image: url("images/platform/download@3x.png") !important;
}

.disabled .delete,
.up-editable .delete {
  background-image: url("images/platform/trash-grayed@3x.png") !important;
}

.upload-wrapper {
  overflow: hidden !important;
  padding: 0 !important;

  span {
    padding-left: 0 !important;
  }

  .dz-message>span:first-of-type {
    padding-left: 45px;
  }

  .bolded {
    color: $highlightColor;
    text-decoration: underline;
  }

  .img-upload {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(images/platform/download-copy@3x.png);
    height: 18px;
    width: 18px;
    position: absolute;
    left: 18px;
    top: 13px;
  }

  #tpl,
  .dz-message {
    display: inline-block;
    vertical-align: top;
  }

  #tpl .dz-details {
    padding-left: 46px !important;
  }

  .dz-image {
    height: auto !important;
    width: auto !important;
  }

  #tpl .dz-preview {
    padding-bottom: 10px !important;
    padding-left: 0 !important;
  }
}

.dropzone {
  min-height: unset !important;
  height: auto !important;

  a {
    height: 100%;
    width: 100%;
    display: block;
  }

  .dz-preview {
    min-height: unset !important;
    width: 100%;
    margin: unset !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 13px;
    padding-bottom: 0px !important;

    .dz-details {
      position: static !important;
      padding: 0 !important;
    }
  }

  >.dz-preview {
    padding-left: 46px !important;
  }
}

.dz-preview>div {
  display: inline !important;
}

.main-input-form .input-control .upload-wrapper {
  padding: 0 0 0 30px;
}

.dz-default {
  display: none !important;
}

.dropzone {
  .dz-preview .dz-progress {
    display: block !important;
    position: static !important;
    width: 100% !important;
    height: 4px !important;
    margin-top: auto !important;
    margin-left: 0;
  }

  .remove,
  .delete {
    background-image: url("images/platform/ikona-brisi@3x.png") !important;
    height: 14px !important;
    width: 12px !important;
  }

  .close {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    background-image: url("images/platform/icon-close-gray@3x.png");
    top: 15px;
    right: 15px;
    height: 10px;
    width: 10px;
    cursor: pointer;
  }
}

#tpl {
  width: 100%;

  .dz-size,
  .dz-filename,
  .dz-error-message {
    display: none !important;
  }
}

.set-visible {
  display: block !important;
}

.dz-progress-val {
  margin-top: 20px;
  display: none;
  height: 4px;
  background-color: $lightBottomBorder;
  width: 100%;

  >div {
    width: 0;
    height: 100%;
    background-color: $highlightColor;
  }
}

.dz-details {
  text-align: left !important;

  .dz-size {
    margin-bottom: 0px !important;

    span {
      font-family: $italicFontFamily;
      font-size: 12px !important;
      font-weight: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $underline !important;
    }
  }

  span:nth-child(2) {
    padding: 0 !important;
  }
}

.dz-filename span,
.dz-details {
  font-size: 14px !important;
}

.finished {
  border: solid 2px $boxContainerBackground !important;

  .dz-size {
    margin-bottom: 20px !important;
  }
}

.dropzone .dz-preview .dz-details .dz-filename:hover span {
  border: 0 !important;
}

.dz-percentage {
  margin-left: 5px !important;
}

.dz-success-mark {
  display: none !important;
}

.dz-drag-hover {
  border: 2px dashed $highlightColor !important;
}

.dropzone img {
  display: none !important;
}

.alert-warning {
  background-color: $statusIdle;
  display: inline-table !important;
}

button {
  outline: none !important;
}

.dropzone .dz-preview .dz-error-message {
  position: static;
  background-color: transparent;
  font-family: $secRegularFontFamily;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $textI;
  background: none !important;
  opacity: 1;
  padding-left: 0 !important;
  color: $statusInActive;
}

.multiple {
  border: 0 !important;

  .dz-clickable {
    padding-left: 0 !important;
  }

  .img-upload {
    top: 17px !important;
  }

  #tpl {
    padding-left: 30px;
    border: dashed 2px $boxContainerBackground;
  }
}

.uploaded-files {
  display: none;
  border-radius: 3px;
  border: solid 1px $boxContainerBackground;
  margin-top: 20px;

  .dz-preview {
    padding-left: 46px !important;
    padding-top: 0px;

    &:first-of-type {
      margin-top: 20px !important;
    }

    &:last-of-type {
      padding-bottom: 0px !important;
    }
  }
}

.file-icon {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("images/platform/download-copy@3x.png");
  position: absolute;
  height: 18px;
  width: 18px;
  left: 16px;
}

.cshtml {
  background-image: url("images/platform/c-sharp-icon.png") !important;
}

\:-webkit-input-placeholder {
  color: $textI;
}

\:-ms-input-placeholder {
  color: $textI;
  color: $textI;
}

\::placeholder,
::-ms-input-placeholder {
  color: $textI;
}

.validationMessages,
.ib-invalid {
  display: none;
}

.input-holder {
  input {
    margin-bottom: 12px !important;
  }
}

.ng-select .ng-select-container {
  color: $selectControlText;
  width: 100%;
  height: 40px;
  border: 1px solid;
}


.ng-select.ng-select-single .ng-select-container {
  height: 40px !important;
}

.SemTextAreaControl {
  position: relative;
}

.form-group>label {
  font-size: 12px;
  font-family: $secRegularFontFamily;
}

.ta-counter p {
  font-size: 12px;
  font-family: $secRegularFontFamily;
  color: $highlightColor;

  span {
    font-family: $secRegularFontFamily;
  }
}

.modules-list {
  .inner-item-wrapper .item-container {
    width: 100% !important;
  }

  h1 {
    display: block !important;
    line-height: 80px !important;
    margin: 0 !important;
  }

  .item-container {
    padding: 0 !important;
    margin-bottom: 30px;
  }
}

.wg-m-circle {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  display: block;
}

.installed {
  background-color: lightgreen;
}

.ninstalled {
  background-color: lightgray;
}

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-value-text {
  font-size: 14px;
  font-family: $regularFontFamily;
}

.mat-tab-group {
  display: table !important;
}

.mat-tab-header {
  display: table-cell !important;
  vertical-align: top !important;
}

.mat-tab-body-wrapper {
  display: table-cell !important;
  vertical-align: top !important;
  width: 100%;
  padding-left: 25px;
}

.mat-tab-labels {
  display: block !important;
}

.mat-ink-bar {
  display: none !important;
}

.mat-tab-header,
.mat-tab-nav-bar {
  border-bottom: 0 !important;
}

.mat-tab-label.mat-tab-label-active {
  border-right: 2px solid $highlightColor;
  background-color: $sectionTitleBackgroundColor;
  color: $highlightColor !important;
}

.mat-tab-header:before {
  content: "";
  background-color: $lightBottomBorder;
  width: 2px;
  height: 100%;
  right: 0;
  position: absolute;
}


.mat-tab-label {
  width: 100% !important;
  max-width: 340px;
  justify-content: flex-start !important;
  opacity: 1 !important;
  height: 35px !important;
  padding-right: 8px !important;
  margin-bottom: 6px !important;
  color: rgba(49, 53, 65, 0.8) !important;
}

.mat-tab-link {
  color: rgba(49, 53, 65, 0.8) !important;
}

.mat-tab-label .mat-tab-label-content {
  text-align: left !important;
  font-family: $secRegularFontFamily;
  white-space: normal !important;
}

.SectionTitleControl p {
  font-family: $secRegularFontFamily;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $highlightColor;
  text-transform: uppercase;
}

.mat-tab-body-wrapper .FormSectionTitle {
  display: block !important;
  width: 100% !important;
  padding-left: 0px !important;
  margin-top: 10px;

  &:first-child {
    margin-top: 0 !important;
  }
}

.clearfix {
  clear: both;
}

.up-controls>p {
  margin-top: 6px;
}

.mat-radio-group {
  width: 100% !important;
  display: block !important;
  margin-top: 6px !important;
  margin-bottom: 22px !important;
}

.mat-radio-label {
  margin-right: 40px !important;
}

.mat-radio-label-content {
  font-size: 12px !important;
  font-family: $regularFontFamily;
}

.mat-radio-button.mat-accent {
  .mat-radio-inner-circle {
    background-color: $highlightColor !important;
  }

  &.mat-radio-checked .mat-radio-outer-circle {
    border-color: $highlightColor !important;
  }
}

.sem-date-picker {
  background: url("images/platform/icon-datepicker@3x.png") right no-repeat;
  background-size: 18px 20px;
  background-origin: content-box;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-underline {
  display: none !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-wrapper {
  border: solid 1px $boxContainerBackground !important;
  height: 40px;
  border-radius: 3px !important;
}

.mat-tab-body-content {
  overflow: hidden !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-legacy .mat-form-field-infix {
  margin: 0 !important;
  border-top: 0 !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-arrow-wrapper {
  display: block !important;
}

.SemRadioButtonControl {
  height: 89px;
}

.container-one {
  padding-bottom: 11px !important;

  h1 {
    text-align: center !important;
    padding-left: 0 !important;
    margin-bottom: 10px !important;
    height: 81px !important;
  }
}

.sem-many-to-one {
  display: block;
  text-align: right;
}

.right-sub-section-holder {
  display: inline-table;
  text-align: right;
  padding: 5px;
  background-color: $sectionHolderBackground;
  border-radius: 1px;
  cursor: pointer;

  * {
    display: table-cell;
  }

  .icon-link-data {
    background-image: url("images/platform/link-existing@3x.png");
    height: 13px;
    width: 14px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 6px;
  }

  p {
    font-size: 11px !important;
    color: $textI;
    padding-left: 5px;
    font-family: $secRegularFontFamily;
    vertical-align: middle;
  }
}

.mat-dialog-container {
  padding: 0px !important;
  position: relative !important;
  overflow-x: hidden !important;
  box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.5);
  overflow: hidden !important;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    height: 4px;
    width: 100%;
    left: 0;
    background-color: $matDialogContainerAfter;
  }

  h1,
  h2 {
    font-family: $secRegularFontFamily;
    font-size: 20px;
    color: black !important;
    font-weight: 500;
    text-transform: uppercase;
    margin: 30px 0 0 0;
    letter-spacing: 1.5px !important;
  }

  h1 {
    margin: 30px 0 20px 0;
  }

  h2 {
    font-size: 15px !important;
    text-transform: none !important;

    &:last-of-type {
      margin-top: 40px !important;
    }
  }
}

.linked-data-dialog {
  padding: 20px 20px 0 20px;

  .drag-scroll-content {
    overflow: hidden !important;
  }

  drag-scroll {
    padding-top: 20px;
  }

  .table-footer p {
    padding-top: 0;
  }

  .table-footer .left {
    margin-top: 0;
    position: absolute;
    top: 21px;
    padding: 0 !important;
  }

  .right-sub-section-holder {
    float: none !important;
  }

  table {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  th,
  td {
    font-family: $secRegularFontFamily;
    color: $underline;
    font-size: 11px;
    padding-left: 10px;
    height: 41px;
    vertical-align: middle;
    text-align: left;
    padding-right: 25px;
    background-color: transparent !important;
  }

  th:first-of-type,
  td:first-of-type {
    min-width: 60px;
  }

  th:nth-child(2),
  td:nth-child(2) {
    min-width: 70px;
  }

  th:nth-child(3) {
    min-width: 90px;
  }

  td {
    color: $textI;
    font-family: $secRegularFontFamily;

    &:nth-child(3) {
      min-width: 90px;
    }
  }

  tr {

    th:last-of-type,
    td:last-of-type {
      border: 0 !important;
      background-color: none !important;
    }

    th:nth-child(3),
    td:nth-child(3) {
      border-right: 0 !important;
    }

    &:first-of-type {
      background-color: none !important;
    }

    td:first-of-type {
      border-left: 0 !important;
    }

    &:hover td {

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        background-color: $sectionHolderBackground;
      }
    }

    td:last-of-type>div {
      height: 23px;
      width: 58px;
      margin: auto;
      vertical-align: middle;
      display: none;
      border-radius: 3px;
      border: 1px solid $matDialogContainerAfter;
      text-align: center;
      cursor: pointer;
      margin-left: 10px;

      * {
        display: inline-block;
        vertical-align: middle;
      }

      >div {
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("images/platform/link-icon@3x.png");
        width: 18px;
        height: 11px;
        margin-top: 6px;
      }

      p {
        font-size: 11px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1px;
        color: $matDialogContainerAfter;
        font-family: $secRegularFontFamily;
        text-transform: uppercase;
        margin: auto !important;
        padding-top: 4px;
        padding-left: 5px;
      }
    }

    &:hover td:last-of-type>div {
      display: block;
    }
  }

  tr:last-of-type {
    td {
      border-bottom: 0 !important;
    }

    .table-footer .right {
      padding-left: 0 !important;
    }
  }

  .close {
    height: 10px;
    width: 10px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 20px;
    right: 20px;
    background-image: url("images/platform/close-icon@3x.png");
    opacity: 1 !important;
    cursor: pointer;
  }
}

.dialog-input-holder {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 3px;
  padding-top: 31px;

  a {
    padding-left: 18px;
    height: 25px;
    width: 25px;
    background-image: url("images/platform/link-search@3x.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  \: {
    &:-webkit-input-placeholder {
      font-family: $secRegularFontFamily;
      font-size: 11px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: $dialogInputPlaceholder;
      padding-left: 26px;
    }

    &:-moz-placeholder {
      color: pink;
    }
  }

  :-ms-input-placeholder,
  :-moz-placeholder {
    color: pink;
  }
}

table {
  border-collapse: collapse;

  td,
  th {
    border: 1px solid $boxContainerBackground;
  }

  tr {
    &:first-child th {
      border-top: 0;
    }

    &:last-child td {
      border-bottom: 0;
    }

    td:first-child,
    th:first-child {
      border-left: 0;
    }

    td:last-child,
    th:last-child {
      border-right: 0;
    }
  }
}

.dialog-loader {
  position: absolute !important;
}

.dataIsLinked .icon-link-data {
  background-image: url("images/platform/link-icon@3x.png") !important;
}

.removeData {
  background-color: transparent !important;

  .icon-link-data {
    background-image: url("images/platform/linked-trash@3x.png") !important;
    height: 10px;
    width: 12px;
    margin-right: 8px !important;
  }
}

.dialog-footer {
  text-align: left !important;
  position: relative;
  padding-left: 25px;

  .right,
  .left,
  .right-double,
  .left-double {
    margin-top: 0px !important;
  }

  p {
    margin: 0 !important;
    padding-left: 9px;
    font-size: 11px !important;

    &:last-of-type {
      padding-right: 9px;
    }

    &.selected {
      color: $matDialogContainerAfter !important;
    }
  }
}

.title-holder {
  height: 62px;
  width: auto;
  display: inline-block;
}

.breadcrumb {
  display: inline-block;
  vertical-align: top;
  margin: 18px 0 0 35px;

  div {
    display: inline-block;

    span,
    a {
      font-family: $secRegularFontFamily;
      font-size: 12px;
    }

    span {
      padding: 0 6px 0 6px;
      color: $lightBottomBorder;
    }

    a {
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 2.5px;
      color: $underline;
      text-transform: uppercase;

      &:hover {
        color: $highlightColor;
      }
    }

    &:last-of-type {
      span {
        font-size: 20px;
        color: $textI;
      }

      a {
        font-size: 20px;
        color: $textI;
        font-family: $secRegularFontFamily;
      }
    }

    &:first-of-type span {
      display: none;
    }
  }
}

.icon-collapse-condense {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 15px;
  width: 14px;
  background-image: url("images/platform/icon-collapse@3x.png");
  position: absolute;
  right: 25px;
  top: 22px;
  cursor: pointer;
}

.main-content-holder {
  .expand {
    display: none;
    height: 100%;
    border-right: 1px solid $lightBottomBorder;
    width: 15px;
    padding: 11px 7px 8px 8px;
    vertical-align: top;

    div {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 18px;
      background-image: url("images/platform/icon-expand@3x.png");
    }
  }

  .collapsed {
    width: 118px;

    .icon-collapse-condense {
      display: none;
    }

    .side-info-table {
      display: inline-block;
    }

    .input-wrapper {
      padding-bottom: 0 !important;
      height: 95px;
      overflow: hidden;
      margin-bottom: 0 !important;
    }

    .expand {
      display: inline-block;
      cursor: pointer;
    }

    p {
      font-size: 12px;
    }

    .img-options,
    .upload {
      display: none;
    }

    .edit-form-title-wrapper {
      padding: 13px 0 0 10px !important;
    }

    sem-file-upload:last-of-type .input-wrapper {
      box-shadow: none !important;
      background-color: transparent !important;
      margin-top: -66px;
      margin-left: 32px;
      text-align: left;

      .icon-collapse-condense,
      .expand {
        display: none !important;
      }
    }
  }
}

.report-hc img {
  width: 916px;
  margin-top: 20px;
}

.report-menu-wrapper {
  margin-top: 0 !important;
}

.global-search {
  background-image: url(images/platform/shape@3x.png);
}

.afu-upload-btn {
  display: none !important;
}

.upload-img {
  background-image: url("images/platform/upload-content@3x.png") !important;
  background-size: contain !important;
}

.main-img {
  padding: 0px 46px 0px 46px;
}

.img-wrapper {
  display: inline-block;
}

.selected-row {
  background-color: $sectionTitleBackgroundColor;
}

.file-upload-footer {
  p {
    margin-top: 8px;
  }

  a {
    background-color: $sectionTitleBackgroundColor;
    color: $mainInputText;
  }
}

.droparea-hovered {
  background-image: url("images/platform/upload-content-blue@3x.png") !important;
}

.confirmation-wrapper {
  background-color: $statusInActive;
  height: 50px;
  width: 100%;
  margin-top: -22px;
  position: absolute;
  z-index: 10;

  .confirmation-row {
    height: 25px;
    width: 100%;
    margin-top: 12.5px;
    display: inline-table;
    table-layout: fixed;

    p {
      font-family: $mediumFontFamily;
      font-size: 12px;
      color: $selectPanelBackground;
      padding-left: 10px;
    }

    * {
      display: table-cell;
      vertical-align: middle;
    }

    .confirmation-delete {
      width: 60px;
      border-radius: 3px;
      border: 1px solid $selectPanelBackground;
      color: $selectPanelBackground;
      font-family: $regularFontFamily;
      font-size: 12px;
      text-transform: uppercase;
      cursor: pointer;
      text-align: center;
    }

    .confirmation-close {
      background-image: url("images/platform/icon-close-hard@3x.png");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-size: 11px;
      padding: 0 15px 0 15px !important;
      width: 6px;
      cursor: pointer;
    }
  }
}

.repeater-section {
  border: 1px solid $lightBottomBorder;
  border-radius: 5px;
  position: relative;
  margin-bottom: 15px;
  padding: 10px !important;

  >div {
    // changed to inline-block, solves issue when you have tooltip (ask mr. nino) display: inline;
    display: inline-block;
  }
}

.manipulate-section {
  //position: absolute;
  float: none;
  margin-bottom: 5px;
  z-index: 7;
  top: 5px;
  right: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  .repeater-icon {
    background-image: url("images/platform/ic-add-circle@3x.png") !important;
    height: 15px;
    width: 15px;
  }
}

.inner-manipulate-section {
  /*
  display: block !important;
  margin-left: auto;
  margin-right: 0;
  background-image: url("images/platform/dropdown-actions@3x.png");
  height: 19px;
  width: 100%;
  top: 12px;
  right: 12px;
  cursor: pointer;
  background-size: contain;
  background-position: right !important;
  */

  top: 10px;
  right: 12px;
  cursor: pointer;
  background-size: contain;
  background-position: right !important;
  background-image: url("images/platform/dropdown-actions@3x.png");
  height: 19px;
  width: 19px;
  position: absolute;
}

.semRepeaterColumn {
  width: 50%;
  box-sizing: border-box;
  padding: 0px 12.5px 0px 0px;
  display: inline-block;
  vertical-align: top;
}

.repeater-text {
  color: $textI !important;
  font-family: $boldFontFamily;
  text-transform: uppercase !important;
}

.repeater-content-section {
  display: inline;

  .form-group {
    height: 90px;
  }
}

.specific-tb {
  padding-right: 0 !important;
}

.specific-span {
  padding-right: 5px !important;
}

.nav-item {
  .navi-sub-menu {
    display: none !important;
  }

  &:hover>.navi-sub-menu {
    display: inline-table !important;

    &:hover> {
      .nav-sub-menu-item {
        display: inline-table !important;

        &:hover>.navi-sub-menu {
          display: inline-table !important;
        }
      }

      .navi-sub-menu {
        display: inline-table !important;
      }
    }
  }

  >.navi-sub-menu>.nav-sub-menu-item {
    display: inline-table !important;

    a {
      padding: 0 10px 0 15px !important;
      text-align: left !important;

      p {
        text-align: left !important;
      }
    }
  }
}

.mat-icon-button {
  display: none !important;
}

.SemColumn {
  min-height: 67px;
}

.switcher {
  display: inline;
}

textarea {
  resize: vertical !important;
}

.confirmation-dialog {
  border-radius: 5px;
  background-color: $selectPanelBackground;

  hr {
    margin: 0;
    border: 1px solid $lightBottomBorder;
  }

  h1 {
    font-family: $secRegularFontFamily;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: $textI;
    padding: 16px 0 15px 30px;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    text-transform: uppercase;
  }

  .icon {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("images/platform/warning-icon@3x.png");
    height: 40px;
    width: 39px;
    margin: 20px 0 0 30px;
    display: inline-block;
  }

  p.text {
    display: inline-block;
    font-family: $secRegularFontFamily;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $textI;
    padding-left: 30px;
    padding-top: 22px;
    padding-bottom: 20px;
    vertical-align: top;
    max-width: 210px;
  }

  .button-holder {
    text-align: center;
    padding-bottom: 15px !important;

    a {
      display: inline-block;
      margin: auto;
      font-family: $secRegularFontFamily;
      font-size: 13px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1px;

      &:first-of-type {
        padding-right: 25px;
        color: $statusInActive;
        font-weight: 500;
      }

      &:last-of-type {
        padding-left: 25px;
        color: $underline;
      }
    }
  }
}

.mandatory-field {
  display: block !important;
  vertical-align: middle;
  font-family: $mediumFontFamily;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $selectPanelBackground;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 35px;
  }
}

.is-invalid {
  border: 1px solid $statusInActive !important;
}

mat-form-field.is-invalid {
  border: solid 1px transparent !important;

  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    border: 1px solid $statusInActive !important;
  }
}

.error-message {
  color: $statusInActive !important;
  position: absolute;
  bottom: -15px;
  font-size: 12px;
  font-family: $regularFontFamily;
  font-style: italic;
}

.SemDropDownSelectControl .error-message {
  bottom: 9px !important;
}

.form-group {
  position: relative;
}

.SemRepeatingControl {
  min-height: 50px;
  position: relative !important;
  padding-top: 40px;
}

.repeater-cb {
  vertical-align: top !important;
}

.transfer-section {
  background-image: none !important;
  right: 75px !important;
}

.sem-dropdown {
  position: absolute !important;
  top: 33px !important;
  right: 10px !important;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);
  background-color: white !important;
  min-width: 150px;
}

.dd-section {
  position: static !important;
  background-image: none !important;
  min-height: 37px;
  width: 100%;
  background-color: none !important;

  p {
    font-family: $regularFontFamily;
    font-size: 14px !important;
    font-weight: normal !important;
    font-style: normal !important;
    font-stretch: normal !important;
    line-height: 37px !important;
    letter-spacing: normal !important;
    padding-left: 12px !important;
    color: $textI !important;
    text-align: left !important;
    text-transform: none !important;
    margin: 0 !important;
  }
}

.sem-dropdown>div {
  background-color: white !important;
  width: 100% !important;
  cursor: pointer;

  &:hover {
    background-color: rgba(26, 128, 182, 0.15) !important;
  }
}

.repeater-error {
  bottom: 8px !important;
}


.search-mto-item {
  /*display: inline-block !important;
  margin-right: 10px;
  vertical-align: top !important;*/
  width: 33%;
  box-sizing: border-box;
  padding: 10px;

  .switcher input[type="text"],
  .switcher input[type="number"],
  .mat-datepicker-input {
    width: 100% !important;
  }

  input {
    width: 100% !important;
  }

  label {
    display: inline-block;
    font-family: $mediumFontFamily;
    font-size: 15px;
  }

  sem-date-picker {
    display: inline-block !important;

    br {
      display: none !important;
    }

    &:first-of-type {
      margin-right: 10px !important;
    }
  }
}

.dialog-field input {
  width: 120px !important;
  height: 25px !important;
  outline: none !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  font-size: 14px !important;
}

.mto-image>div {
  margin: 5px 0 5px 0;
}

.grid-container {
  display: flex !important;
  flex-direction: row !important;
  width: 100% !important;
}

.header-col,
.div-table-col {
  float: left;
  margin-right: 5px;
}

.manip-sec-drop {
  background-image: url(images/platform/dropdown-actions.png);
  height: 17px !important;
  width: 17px;
  position: relative;
  top: 22px;
}

.sem-dropdown2 {
  position: relative;
  top: 11px !important;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);
  background-color: white !important;
  min-width: 150px;
  cursor: pointer;
}

.div-table-col {

  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper,
  input.sem-text-box {
    margin-bottom: 0px !important;
  }
}

.header-col label {
  margin-left: 2px;
}

.rTable {
  display: table;
}

.rTableRow {
  display: table-row;
}

.rTableHeading {
  display: table-header-group;
}

.rTableBody {
  display: table-row-group;
}

.rTableFoot {
  display: table-footer-group;
}

.rTableCell,
.rTableHead {
  display: table-cell;
}

.group-repeater-content {
  input {
    border: none !important;
    border-radius: 0px !important;
    margin: 0px !important;
    background-color: none !important;
    padding: 0 0 0 0 !important;
  }

  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    border: none !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }

  mat-form-field {
    height: 20px !important;
    min-height: 20px !important;
  }

  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-infix {
    padding-left: 0px !important;
  }
}

.sub-content .item-container .group-repeater-content input {
  padding: 0 0 0 0 !important;
}

.group-repeater-container .manipulate-section {
  right: -15px !important;
  position: relative !important;
  top: 10px !important;
}

.inlined-control {
  display: inline-block !important;
  width: 16.66%;
  vertical-align: top;

  label {
    display: inline-block !important;
    white-space: nowrap;
  }

  .semRepeaterColumn {
    width: 100% !important;
  }
}

.notification-search {
  text-align: left;
  margin: auto;
  padding-bottom: 41px;
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);
  background-color: $selectPanelBackground;
  padding-left: 36px;
  margin-left: 145px;

  * {
    display: inline-block;
    margin-top: 40px;
  }

  p {
    margin-right: 20px;
    font-size: 15px;
    text-transform: uppercase;

    &:first-of-type {
      text-transform: none !important;
    }

    &.options {
      text-transform: uppercase;
      color: $highlightColor;
    }

    &.archive {
      font-family: $secRegularFontFamily;
      text-transform: uppercase;
      color: $underline;
      margin-left: 20px;
    }
  }

  .separator {
    margin-right: 20px;
    height: 25px;
    width: 2px;
    background-color: $lightBottomBorder;
    position: absolute;
    top: 107px;
  }

  input {
    margin-right: 35px;
    width: 200px;
    height: 36px;
    object-fit: contain;
    border-radius: 5px;
    border: solid 1px $boxContainerBackground;
    outline: none !important;
    padding-left: 17px;
  }

  .search-dd {
    background-position: right 15px center;
    background-repeat: no-repeat;
    background-size: 12px 6px;
    background-image: url("images/platform/arrow-down-s@3x.png");
  }
}

.results-wrapper {
  width: 91% !important;
  text-align: left;
}

\::placeholder,
:-ms-input-placeholder,
::-ms-input-placeholder {
  font-size: 15px !important;
}

.transp {
  background-color: transparent !important;
}

.under-not {
  padding-left: 145px;
  text-align: left;
  margin: auto;
}

.not-wrapper {
  margin-top: 100px;
  padding-right: 40px;
}

.select-all {
  margin-bottom: 35px;
  padding-right: 100px;
  display: inline-block;
  vertical-align: top;

  * {
    display: inline-block;
    vertical-align: top;
  }

  p {
    font-family: $secRegularFontFamily;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $underline;
    padding-top: 2px;
    padding-left: 15px;
  }

  input:after {
    background-color: $selectAllInputAfter;
  }
}

.container-wrapper {
  .task-att-counter {
    display: inline-block;
    font-family: $secRegularFontFamily;
    font-size: 14px;
    color: $underline;
    background-image: url("images/platform/attachment-icon@3x.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: 11px;
    width: 37px;

    &:after {
      position: absolute;
    }
  }

  table {
    tr {
      border: 0;

      td {
        &:first-child {
          font-size: 17px !important;
          color: $textI;
          border-right: 2px solid $inputBackgroundII !important;
        }

        &:nth-child(2),
        &:nth-child(3) {
          font-family: $secRegularFontFamily;
          font-size: 15px;
          color: $underline;
          border-right: 2px solid $inputBackgroundII !important;
          white-space: nowrap;
        }

        &:last-child {
          font-family: $secRegularFontFamily;
          font-size: 14px;
          color: $highlightColor;

          p {
            margin-right: 18px !important;
            display: inline;
          }
        }
      }
    }

    td {
      border: 0;
    }

    th {
      border: 0;
      border-right: 2px solid $inputBackgroundII !important;
    }

    td {
      border-bottom: 2px solid $inputBackgroundII !important;
      padding: 10px 10px 10px 0;
      position: relative;

      * {
        display: inline-block;
        vertical-align: middle !important;
      }

      input {
        position: absolute;
        margin-top: 7px;
      }

      p {
        margin-left: 45px;
        margin-right: 45px;
        margin-top: 10px;
      }
    }

    tr:hover {
      background-color: $selectPanelBackground;
    }
  }
}

.sem-noti-check-box {
  width: 20px;
  height: 20px;
  object-fit: contain;
  border-radius: 4px;
  border: solid 1px $boxContainerBackground;
  background-color: $selectPanelBackground;
  margin-right: 5px;
  cursor: pointer;
  font-size: 14px;
  width: 20px;
  height: 20px;
  position: relative;
  border: solid 1px $boxContainerBackground;

  &:after {
    position: absolute;
    width: 14px;
    height: 16px;
    padding-left: 4px;
    padding-top: 2px;
    top: 0;
    content: " ";
    background-color: $selectPanelBackground;
    color: black;
    display: inline-block;
    visibility: visible;
    border-radius: 3px;
    border: solid 1px $boxContainerBackground;
  }

  &:checked:after {
    content: "";
    font-size: 12px;
    background-color: $highlightColor;
    background-image: url("images/platform/cb-kljukica-bela@3x.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 12px;
    border-color: $highlightColor !important;
    outline: none !important;
  }
}

.parameter-dialog-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.side-popup-notification {
  position: absolute;
  right: 35px;
}

.side-popup {
  background-color: $selectPanelBackground;
  border-radius: 5px;
  border: solid 1px $lightBottomBorder;
  z-index: 10;
  margin: auto;
  overflow: auto;
  max-width: 80%;
  max-height: 80vh;
  /* Set the maximum height to 80% of the viewport height */
  overflow-y: auto;
  /* Add vertical scrollbar if the content overflows */
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  h1 {
    line-height: normal;
  }

  .repeater-section,
  .form-switch-buttons {
    display: none !important;
  }
}

.top-bar {
  height: 67px;
  position: relative;
  border-bottom: solid 1px $lightBottomBorder;

  p {
    margin: 24px 0 0 35px;
    font-family: $secRegularFontFamily;
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $underline;
  }

  * {
    display: inline-block;
    vertical-align: middle;
  }
}

.toolbar-actions {
  height: 25px;
  position: absolute;
  right: 35px;
  width: 186px;
  top: 0;
  bottom: 0;
  margin: auto;

  * {
    display: inline-block;
  }

  div {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 15px;
    width: 15px;
    display: inline-block;
    vertical-align: middle;
  }

  .separator {
    width: 2px;
    height: 25px;
    background-color: $lightBottomBorder;
    margin: 0 20px 0 20px;
  }
}

.popup-inner-content {
  padding: 50px 35px 25px 35px;

  .sem-text-box {
    padding-left: 15px !important;
  }

  h1 {
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
    font-family: $secRegularFontFamily;
    font-size: 30px;
    color: $textI;
  }

  .underline {
    font-family: $secRegularFontFamily;
    font-size: 14px;
    letter-spacing: 0.4px;
    color: $underline;
  }

  .text {
    font-family: $secRegularFontFamily;
    font-size: 15px;
    line-height: 1.4;
    color: $textI;
    margin: 30px 0 30px 0;
  }

  textarea {
    width: 100%;
    border-radius: 5px;
    border: solid 1px $lightBottomBorder;
    padding: 10px 17px 10px 17px;
    font-family: $secRegularFontFamily;
    font-size: 15px;
    line-height: 1.4;
    color: $underline;
    margin-top: 65px;
    outline: none;
    min-height: 105px;

    &::-webkit-input-placeholder,
    &:-moz-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &::placeholder {
      color: $underline;
    }
  }
}

.sub-side-content {
  margin-bottom: 10px;

  * {
    display: inline-block;
    vertical-align: middle;
  }

  .attachment {
    height: 20px;
    width: 20px;
    border-radius: 5px;
    background-color: rgba(26, 128, 182, 0.15);
    text-align: center;

    .img {
      background-image: url("images/platform/attachment-blue@3x.png");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 11px;
      width: 11px;
    }
  }

  p {
    font-family: $secRegularFontFamily;
    font-size: 15px;
    color: $underline;
    padding-left: 15px;
    margin: auto !important;
  }
}

.SemDropDownSelectControl .mat-mdc-form-field-flex {
  padding-top: 3px !important;
}

.btn-group-param-popup {
  margin-top: 0px !important;
  margin-bottom: 0px !important;

  .accept {
    width: 95px !important;
    padding-right: 0px !important;

    &:after {
      display: none !important;
    }
  }
}

.parameter-dialog-container {

  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    padding-bottom: 0px !important;
  }

  .SemTextBoxControl input {
    width: 95% !important;
  }

  .btn-group .accept {
    width: 120px !important;
  }

  .popup-inner-content {}
}

.btn-group {
  margin-top: 60px;
  text-align: center;
  margin-bottom: 35px;

  .accept {
    width: 120px;
    height: 35px;
    object-fit: contain;
    border-radius: 3px;
    background-color: $highlightColor;
    margin-right: 12.5px;
    border: 0;
    font-family: $secRegularFontFamily;
    font-size: 15px;
    letter-spacing: 1.3px;
    color: $selectPanelBackground;
    text-transform: uppercase;
    position: relative;
    padding-right: 25px;
    cursor: pointer;
  }

  .decline {
    height: 35px;
    object-fit: contain;
    border-radius: 3px;
    border: solid 1px $highlightColor;
    margin-left: 12.5px;
    background-color: transparent;
    font-family: $secRegularFontFamily;
    font-size: 15px;
    letter-spacing: 1.3px;
    color: $highlightColor;
    text-transform: uppercase;
    cursor: pointer;
  }

  .accept:after {
    content: "";
    background-image: url("images/platform/icon-right-white@3x.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 12px;
    width: 12px;
    position: absolute;
    top: 0;
    right: 8px;
    bottom: 0;
    margin: auto;
  }

  * {
    display: inline-block;
    margin: auto;
  }

  .dd {
    position: absolute;
    top: 40px;
    left: 0;
    text-align: left;
    border-radius: 3px;
    box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);
    background-color: $selectPanelBackground;

    p {
      font-family: $secRegularFontFamily;
      font-size: 14px;
      color: $textI;
      text-transform: none;
      height: 37px;
      width: 100%;
      padding: 10px 12px 0 12px;
      white-space: nowrap;

      &:hover {
        background-color: rgba(26, 128, 182, 0.15);
      }
    }
  }
}

.nav-line {
  display: inline-block;
  padding-top: 1px;

  * {
    display: inline-block;
  }

  button {
    padding: 0 12px 0 12px;
    height: 25px;
    border: 0;
    font-family: $secRegularFontFamily;
    font-size: 12px;
    letter-spacing: 1px;
    cursor: pointer;

    &:first-of-type {
      margin-left: 125px;
      margin-right: 32px;
      border-radius: 3px;
      background-color: $highlightColor;
      text-transform: uppercase;
      color: $selectPanelBackground;
    }

    &:nth-of-type(2) {
      border-radius: 3px;
      border: solid 1px $highlightColor;
      text-transform: uppercase;
      color: $highlightColor;
      background-color: transparent;
    }
  }

  .applied-filters {
    font-family: $secRegularFontFamily;
    font-size: 17px;
    color: $underline;
    padding-top: 2px;
    padding-right: 50px;
  }

  .separator {
    margin: 0 17px 4px 17px;
    vertical-align: middle;
  }

  .inlined {
    p {
      font-family: $secRegularFontFamily;
      font-size: 17px;
      color: $dialogInputPlaceholder;
    }

    .separator {
      width: 2px;
      height: 25px;
      background-color: $lightBottomBorder;
      margin: 0 17px 0 17px;
    }
  }
}

.table-header {
  font-family: $secRegularFontFamily;
  font-size: 20px;
  letter-spacing: 2.5px;
  color: $tableHeader;
  margin: 0 !important;
  padding: 0 !important;
  margin-bottom: 20px !important;
}

.file-holder {
  margin-bottom: 30px;
}

.dynamic-loader {
  position: absolute !important;
  top: 0;
  left: 0;
}

.SemLastEditedData {
  display: inline-block;
  width: 50%;
}

.SemQuickActions {
  .container {
    display: block !important;
    text-align: left;
    cursor: default;

    a {
      cursor: default;
    }

    img {
      cursor: pointer;
    }
  }
}

.SemSearchControl {

  .dd-search p,
  .search-button p {
    font-size: 14px !important;
  }
}

.SemSearchBar {
  .inner-item-wrapper {
    padding: 0 !important;
    margin-bottom: 32px;
  }
}

.small img {
  height: 31px !important;
  width: 30px !important;
}

.medium img {
  height: 62px !important;
  width: 60px !important;
}

.large img {
  height: 124px !important;
  width: 120px !important;
}

.headline-span {
  position: relative;
  display: inline-block !important;
  vertical-align: top !important;

  p {
    font-family: $secRegularFontFamily;
    font-size: 15px;
    color: $underline;
    margin-bottom: 12px;
  }

  a {
    font-family: $secRegularFontFamily;
    font-size: 17px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $textI;
  }
}

.side-info {
  display: inline-block !important;
  vertical-align: top !important;
}

.last-edited-data .container-holder {
  padding-right: 90px;
}

.side-info img {
  width: 12px;
  height: 13px;
  object-fit: contain;
}

.last-edited-data .headline {
  margin-top: 20px;
  position: relative;
  float: none !important;

  .side-info {
    position: absolute;
    top: 0;
    right: 0;

    p,
    span {
      font-family: $secRegularFontFamily;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
    }

    p {
      color: $highlightColor;
    }

    span {
      color: $underline;
    }
  }
}

.dashboard-wrapper {
  .SemLayoutHolderControl {
    background-color: $containerHolderColor;
    box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);
    border-radius: 5px;
  }

  .container-holder {
    background-color: transparent !important;
    box-shadow: none !important;
    border-radius: 0px;
  }
}

.SemLayoutHolderControl .SemLastEditedData {
  margin-bottom: 50px !important;

  &:not(:first-child):not(:last-child) {
    border-right: 1px solid $boxContainerBackground;
  }

  &:last-of-type {
    padding-left: 52px !important;
  }
}

.SemLastEditedData .container-holder {
  padding-bottom: 0 !important;
}

.header-title {
  display: block;
  font-family: $secRegularFontFamily;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 26px;
  letter-spacing: 1.9px;
  color: $headerTitle;
  width: 81%;
  margin: auto;
  position: absolute;
  left: 0;
  top: 17px;
  right: 0;

  span {
    font-family: $secRegularFontFamily;
  }
}

.SemLastEditedData {
  min-height: 480px;

  .info div {
    left: 0 !important;
  }
}

.mat-calendar-next-button,
.mat-calendar-previous-button {
  display: block !important;
}

.SemCheckBoxControl label.container {
  padding-bottom: 10px;
  margin-bottom: 40px;
}

input {

  &.full-btn,
  &.light-btn {
    position: absolute;
    right: 0;
    margin-top: 20px;
    text-transform: uppercase;
    border-radius: 3px;
    text-align: center !important;
    padding: 0 10px 0 10px !important;
  }
}

.full-btn {
  background-color: $highlightColor;
  color: $selectPanelBackground;
  width: 100px;
  height: 35px;
  border: none !important;
}

.light-btn {
  border: 1px solid $highlightColor;
  margin-right: 125px;
  color: $highlightColor;
  width: 100px;
  height: 35px;
  background-color: transparent !important;
}

.inline-margin {
  margin-top: 12px !important;
}

.tab-button {
  padding: 0 10px 0 11px !important;
  cursor: pointer;
}

sem-drop-down-select .binding-prop-name {
  bottom: 5px !important;
}

.binding-prop-name {
  font-weight: bold !important;
  position: absolute !important;
  bottom: -15px !important;
  left: 0 !important;
  color: red !important;
  font-family: $secMediumFontFamily;
  padding: 0 !important;
  display: none !important;
}

.grid-table .table-container {
  min-height: 115px !important;
}

/*.sidebar .tooltip-text,
table .tooltip-text,
.table-controls .tooltip-text,
.side-input-form .tooltip-text,
.one-to-many-section-wrapper .tooltip-text,
.sem-one-to-many-c .tooltip-text {
  display: none !important;
}*/

.tooltip-text {
  visibility: hidden;
  width: 120px;
  box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);
  color: #0f0f0f;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  font-size: 15px;
  font-family: "asapregular";
  display: block;
  background-color: white;
}

.listing-grid>.manipulate-section {
  margin-top: 20px !important;
}

.search-by-all {
  // why did we did hide this?  display: none !important;
  /*  display:inline-block;
*/
}

#flash-messages {
  margin-bottom: 0px !important;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 9999;
  border-radius: 0px;
  left: 0;
  height: 90px;
}

.custom-header-menu-container {
  width: 58px;
}

header {
  width: auto !important;
  padding-left: 10px;

  .wrapper {
    vertical-align: middle !important;
    padding: 6px 6px !important;
  }
}

.form-action-evals {
  display: table-cell;
  vertical-align: middle;

  p {
    font-family: $mediumFontFamily;
  }
}

.confirmation-dialog p.text {
  padding-right: 30px !important;
}

.pen-q-a,
.pen-h-t {
  display: none !important;
}

.pen-h-le {
  width: 100% !important;
  border: none !important;
}

.o2mSection .leave-section {
  display: block !important;
}

sem-data-grid .table-footer .left,
.table-footer .right {
  position: static !important;
  padding: 0 !important;
}

.bottom-action {
  position: absolute;
  bottom: 5px;
  right: 5px;

  .manipulate-section {
    min-width: 82px !important;
    top: -30px !important;
  }
}

.dd-delete {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
  width: 12px;
  height: 12px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(images/platform/icon-close@3x.png);
  cursor: pointer;
  display: none;
  background-color: $selectPanelBackground;
}

mat-form-field:hover .dd-delete {
  display: block;
}

input {
  box-sizing: border-box !important;
}

.mat-calendar-next-button,
.mat-calendar-previous-button {
  display: block !important;
}

.SemCheckBoxControl {
  margin-top: 10px;
}

input {

  &.full-btn,
  &.light-btn {
    position: absolute;
    right: 0;
    margin-top: 20px;
    text-transform: uppercase;
    border-radius: 3px;
    text-align: center !important;
    padding: 0 10px 0 10px !important;
  }
}

.full-btn {
  background-color: $highlightColor;
  color: $selectPanelBackground;
  width: 100px;
  height: 35px;
  border: none !important;
}

.light-btn {
  border: 1px solid $highlightColor;
  margin-right: 125px;
  color: $highlightColor;
  width: 100px;
  height: 35px;
  background-color: transparent !important;
}

.dd-loader {
  position: absolute;
  top: 2px;
  right: 5px;
  bottom: 0;
  margin: auto;
  height: 25px;
  z-index: 99;
  width: 25px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border: none !important;
  background-image: url(images/platform/control-loader.gif);
}

.autocomplete-loader {
  top: -9px;
}

.semRepeaterColumn .dd-loader {
  bottom: 10px !important;
  right: 10px !important;
}

.dialog-footer {
  bottom: 0 !important;
  left: 0px;
  right: 0px;

  .right {
    padding: 0 !important;
  }
}

.dialog-input-holder {
  display: inline-block;
}

.mat-dialog-container h2:last-of-type {
  margin-top: 0px !important;
  margin-bottom: 12px !important;
}

.table-footer {
  .left {
    left: -3px;
    top: 0;
  }

  .right {
    right: -3px;
    top: 0;
  }
}

drag-scroll {
  height: 30px;
  margin: 0 15px;
}

.dialog-footer p:first-child {
  padding-left: 0 !important;
}

.drag-scroll-content {
  padding-left: 3px;
}

.table-footer {

  .left,
  .right {
    height: 13px;
    width: 6px;
    position: absolute;
    top: 1px;
  }
}

.dialog-loader {
  position: absolute !important;
  height: 100% !important;
  width: 100% !important;
}

.results-found {
  margin-bottom: 24px;
}

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-disabled .mat-select-value {
  color: $textI;
}

.form-group {
  font-size: 12px;
  font-family: $secRegularFontFamily;
}

.mat-tab-body.mat-tab-body-active {
  min-height: 30px;
}

.sem-dropdown {
  z-index: 1;
}

.o2mSection {
  margin-top: 0px;
}

.mat-tab-label .mat-tab-label-content {
  white-space: pre !important;
}

sem-report-generator {
  display: block !important;
}

.sem-report-container {
  margin: 12px 0 22px 0 !important;
  display: inline-table;

  br {
    display: none !important;
  }

  * {
    display: table-cell;
    vertical-align: top;
  }

  div {
    height: 25px;
    border-radius: 3px;
    border: solid 1px $boxContainerBackground;
    background-color: rgba(204, 210, 213, 0.1);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right 10px top 4px;
    background-size: 14px 14px;
    min-width: 110px;
    cursor: pointer;
  }

  .generated {
    background-image: url("images/platform/tick@3x.png");
  }

  .not-generated {
    background-image: url("images/platform/add-doc@3x.png");
    background-size: 11px !important;
    background-position: right 10px top 4px;
  }

  p,
  input {
    font-family: $secMediumFontFamily;
    font-size: 12px !important;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: $dialogInputPlaceholder;
    margin-bottom: 0;
    padding-top: 3px;
    padding-left: 15px;
  }

  p {
    padding-top: 3.5px !important;
  }

  span {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("images/platform/link-icon@3x.png");
    width: 25px;
    height: 25px;
    border-radius: 3px;
    border: solid 1px $highlightColor;
    background-size: 13.1px;
    cursor: pointer;
  }

  input {
    width: 120px !important;
    height: 25px !important;
    border-radius: 3px;
    border: solid 1px $boxContainerBackground;
    margin-left: 15px;
    margin-top: 0 !important;
    background-image: url("images/platform/calendar-blue@3x.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right 10px top 4px;
    background-size: 14px;
    margin-bottom: 0 !important;
    margin-right: 15px;
  }

  \::-webkit-input-placeholder,
  ::-moz-placeholder,
  :-ms-input-placeholder,
  :-moz-placeholder {
    font-size: 12px !important;
  }
}

sem-one-to-many-container {
  display: block;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;

  .actual-image {
    width: 100% !important;
  }

  a {
    top: 0;
    left: 0;
  }
}

.sem-one-to-many-c {
  >* {
    display: none;
  }

  sem-one-to-many-container {
    display: block !important;
  }
}

.Sem-Separator {

  height: 1px;
  margin-top: 5px;
  margin-bottom: 25px;
  position: relative;

  >div {
    position: absolute;
    background-color: $boxContainerBackground;
    height: 1px;
    width: 100%;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.sem-report-gen {
  min-height: 86px !important;
}

.autocomplete-span:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
  margin: 0 4px;
  color: rgba(0, 0, 0, 0.54);
  right: 6px;
  bottom: 0;
  position: absolute;
  top: 14px;
}

.tab-button {
  width: 100px;
  height: 35px;
  object-fit: contain;
  border-radius: 3px;
  border: solid 1px $highlightColor;
  font-family: $secRegularFontFamily;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.25px;
  color: $highlightColor;
  background-color: transparent;
  right: 0;
  margin-top: 20px;
}

.tab-skip {
  background-color: aqua !important;
}

.frm-hide {
  display: none;
}

one-to-many-section .frm-hide {
  display: block !important;
}

one-to-many-section {
  sem-many-to-one {
    padding-right: 25px;
  }
}

.parameter-dialog-container .o2m-content {
  display: inline !important;
}

.autocomplete-input {
  font-family: $regularFontFamily;
  font-size: 14px !important;
  vertical-align: middle !important;
  height: 40px;
  padding: 0 !important;
  margin-top: -4px !important;
}

.SemComboBoxControl {
  label {
    display: block;
    width: 100%;
  }

  sem-multiple-select mat-form-field {
    width: 100%;
  }
}

.one-to-many-grid {
  display: table;

  .one-to-many-section-wrapper {
    display: table-row;
  }

  .repeater-section {
    border: 0px !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    padding: 0px !important;
  }

  div {
    &:nth-child(n+1) one-to-many-section .o2mSection .inner-manipulate-section {
      position: relative !important;
      top: 58px !important;
    }

    &:nth-child(n+3) one-to-many-section .o2mSection .label-text {
      display: none !important;
    }
  }

  one-to-many-section .o2mSection .form-group>span {
    display: none !important;
  }
}

.label-text label {
  font-size: 12px !important;
  padding-bottom: 10px !important;
}

div.SemLabelControl.label-text {
  padding-bottom: 10px !important;
}

.one-to-many-grid {
  div:nth-child(n+3) one-to-many-section .o2mSection {
    .inner-manipulate-section {
      position: relative !important;
      top: 28px !important;
    }

    .SemDateBoxControl {
      position: relative;
      top: -13px;
    }

    .one-to-many-grid-header {
      display: none !important;
    }
  }

  .SemTextBoxControl input {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .SemDateBoxControl {
    position: relative;
    top: -13px;

    input {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
  }

  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
  }

  .SemMultiColumnControl .SemColumn {
    min-height: 0px !important;
  }
}

.one-to-many-grid-header {
  padding-bottom: 15px;

  label {
    font-size: 12px !important;
  }

  .SemColumn {
    min-height: 0px !important;
  }
}

.tab-button {
  width: 175px;
  float: right;
  margin-right: 15px;

  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

.linked-data-dialog tr td:last-of-type>div {
  width: 150px !important;
}

.confirmation-dialog .icon {
  background-image: url("images/platform/caution-triangular-red@3x.png") !important;
}

.notification-search {
  display: none !important;
}

.popup-inner-content span {}

.mat-tab-header {
  max-width: 215px;
  width: 215px;
}

.popup-inner-content .SectionTitleControl p {
  padding-top: 15px;
}

.relatives-grid {
  width: 95% !important;
}

.one-to-many-inline {

  .one-to-many-section-wrapper,
  .repeater-section {
    display: inline-block;
  }

  .inner-manipulate-section {
    position: absolute;
    top: 30px !important;
    right: 3px !important;
  }
}

.SemMultiColumnControl {

  .SemComboBoxControl .label-text,
  sem-date-picker br {
    display: none !important;
  }
}

.number-value {
  text-align: center;
  margin-top: 16px;
}

.centered-items>div {
  &:first-child {
    width: 50% !important;
  }

  &:nth-child(2) {
    width: 24% !important;
  }

  &:nth-child(3) {
    width: 24% !important;
    padding-top: 13px !important;
  }
}

.mat-tab-label {
  display: none;

  &[aria-label|="parent"] {
    display: inline-flex !important;
  }
}

.grid-action-container {
  position: absolute;
  bottom: -15px;
  right: 0;
  padding: 22px;

  span {
    margin: 3px;
    cursor: pointer;
  }
}

.ng-select {
  margin-top: 10px;
}

.mat-mdc-form-field {
  width: 100%;
  min-height: 40px;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-underline {
  display: none !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
.mat-primary .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
  color: $matOptionColor !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-value-text {
  font-size: 14px;
  font-family: $regularFontFamily;
}

.mat-tab-header-pagination {
  display: none !important;
}

.gen-dialog-holder {
  min-width: 575px;
  min-height: 375px;
  padding: 20px;

  h1 {
    font-family: $regularFontFamily;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2px;
    color: $mainFieldsColor;
    text-transform: uppercase;
    margin: 25px 0 30px 0;
  }

  h2 {
    font-family: $regularFontFamily;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    color: $mainFieldsColor;
  }

  .form-group label {
    padding-right: 30px;
  }

  input {
    height: 25px !important;
    padding-left: 10px;
    outline: none !important;
    margin: 5px 0 0 0 !important;
  }

  button {
    right: 57px;
    bottom: 28px;
    object-fit: contain;
    border-radius: 5px;
    border: solid 1px $matDialogContainerAfter;
    background-color: transparent;
    font-family: $regularFontFamily;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.92;
    letter-spacing: 1px;
    color: $matDialogContainerAfter;
    padding: 7px 15px;
    text-transform: uppercase;
    margin-left: auto;
    float: right;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-right: 5px;
  }

  .form-group {
    display: block !important;
  }

  br,
  mat-datepicker-toggle,
  mat-datepicker {
    display: none !important;
  }

  .label-holder,
  .control-holder {
    display: inline-block;
  }

  .label-holder {
    width: 230px;
  }

  sem-date-picker input {
    height: 27px !important;
  }

  .control-holder {
    min-width: 350px;
    margin-right: 15px;
  }

  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-infix {
    height: 29px !important;
  }

  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    height: auto !important;
    margin: 5px 0 0 0 !important;
  }

  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-value-text {
    display: block;
    height: 29px;
    padding-top: 6px;
    padding-left: 10px !important;
  }

  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-placeholder {
    display: block;
    height: 29px;
    padding-top: 6px;
    padding-left: 10px !important;
    padding-top: 9px;
  }

  sem-drop-down-select mat-form-field {
    width: 103% !important;
  }
}

.grid-table-row .ti-trash {
  display: none !important;
}

.centered-items {

  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  input,
  .mat-form-field-wrapper,
  .SemDropDownSelectControl {
    margin-top: 0 !important;
  }
}

.linked-data-dialog table tbody tr td:first-child {
  border: 1px solid $boxContainerBackground;
}

.gen-dialog-holder h1 {
  font-family: $secRegularFontFamily;
}

.ti-med1 {
  background-image: url("images/platform/med1.png");
}

.ti-med2 {
  background-image: url("images/platform/med2.png");
}

.ti-med3 {
  background-image: url("images/platform/med3.png");
}

.ti-med4 {
  background-image: url("images/platform/med4.png");
}

.ti-med5 {
  background-image: url("images/platform/med5.png");
}

.ti-med6 {
  background-image: url("images/platform/med6.png");
}

.icon-grid {
  height: 35px !important;
  width: 35px !important;
}

.SemLayoutHolderControl .SemLastEditedData {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.grid-inline-span {
  margin-top: 16px !important;
  display: inline-block;
}

.peni-wrapper .frm-hide {
  display: block !important;
}

.grid-view-listing {
  font-family: $secRegularFontFamily;

  table thead td {
    font-family: $secRegularFontFamily;
    text-align: center;
    font-size: 12px !important;
    padding: 10px;
  }

  .FormSectionTitle {
    margin-bottom: 10px !important;
  }

  table td {
    border: 1px solid $boxContainerBackground !important;
    padding: 10px !important;
    font-size: 12px !important;
  }
}

.peni-qa {
  .container-one {
    display: inline-table;
    width: 100%;
    padding-top: 35px !important;
    padding-left: 0 !important;

    .box {
      text-align: center !important;
      border: 0 !important;

      p {
        text-align: center !important;
        margin: 0 !important;
        padding: 0 !important;
      }

      img {
        text-align: center !important;
        margin: 0 !important;
        padding: 0 !important;
        margin-bottom: 10px !important;
      }
    }
  }

  h1 {
    display: table-caption !important;
    margin-left: 38px !important;
  }

  * {
    text-align: center;
  }

  a {
    display: table-cell !important;
  }
}

.peni-wrapper .SemMultiColumnControl .SemComboBoxControl .label-text {
  display: block !important;
}

.ti-add-pen {
  background-image: url("images/platform/ikona-plus-tools@3x.png");
}

.listing-grid .manipulate-section {
  position: initial !important;
}

.moj-notes {
  margin: 20px 0 40px 0;
  border: 1px solid black;
  display: inline-block;
  padding: 12px;
  font-family: $secRegularFontFamily;
  font-size: 12px !important;

  label {
    line-height: 16px !important;
  }
}

.ti-printer {
  background-image: url("images/platform/print.png");
}

.listing-grid {
  padding-top: 0px !important;

  .inner-manipulate-section {
    position: absolute !important;
  }
}

.ti-accept {
  background-image: url("images/platform/ikona-workflows@3x.png");
}

.listing-manipulate-section {
  //margin-top: 19px !important;
}

.listing-main-manipulate-section .listing-manipulate-section {
  margin-top: -5px !important;
}

.task-not-wrapper {
  padding: 0;
  margin-left: 143px;
  margin-top: 0;
  margin-right: 35px;

  .under-not {
    padding-left: 0 !important;
  }

  table th {
    padding-top: 0 !important;
    font-weight: bold !important;
    font-family: $secMediumFontFamily;
  }

  .containers {
    padding-top: 0 !important;
  }
}

.up-wrapper {
  padding: 25px 25px 25px 0;
  min-height: 421px;
  min-width: 600px;

  h1 {
    text-transform: none !important;
    margin-bottom: 30px !important;
    margin-top: 0px !important;
  }

  p {
    font-size: 12px;
    font-family: $secRegularFontFamily;
    padding: 3px;
  }

  .mat-tab-label {
    display: flex !important;
  }

  .form-group {
    margin-right: 40px;
  }

  .action-button {
    position: absolute;
    right: 46px;
    bottom: 30px;
    font-family: $secRegularFontFamily;
    background-color: transparent;
    border: 1px solid lightgray;
    border-radius: 3px;
    padding: 6px;
    cursor: pointer;
  }

  input {
    padding: 0 10px 0 10px !important;
  }

  .p-status-message {
    text-align: right;
    padding-right: 20px;
    font-size: 15px;
    color: $errorFontColor;
    margin-bottom: 30px;
  }
}

.top-data-inline {
  display: inline-table;
  margin-bottom: 30px !important;

  .data-text {
    display: table-cell;
    vertical-align: middle;
    padding-left: 10px;
  }

  .img-initials {
    display: inline-table;
    height: 60px;
    width: 60px;
    border: 1px solid $boxContainerBackground;
    border-radius: 50%;
    position: relative;

    p {
      display: table-cell;
      vertical-align: middle;
      margin: auto;
      text-align: center;
      font-size: 22px;
    }
  }
}

.tab-content-actions {
  text-align: right;
  margin-top: 30px;

  button {
    margin-right: 20px;
    font-family: $secRegularFontFamily;
    background-color: transparent;
    border: 1px solid lightgray;
    border-radius: 3px;
    padding: 6px;
    cursor: pointer;
  }
}

.up-buttons {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
  text-align: right;
  padding-right: 20px;

  a {
    font-family: $secRegularFontFamily;
    font-size: 12px !important;
    background-color: transparent;
    border: 1px solid lightgray !important;
    border-radius: 3px;
    padding: 6px;
    cursor: pointer;
    font-size: 12px;
    outline: none !important;
    box-shadow: none !important;

    &:focus,
    &:focus-visible {
      font-family: $secRegularFontFamily;
      font-size: 12px !important;
      background-color: transparent;
      border: 1px solid lightgray !important;
      border-radius: 3px;
      padding: 6px;
      cursor: pointer;
      font-size: 12px;
      outline: none !important;
      box-shadow: none !important;
    }
  }
}

.reminder-row {
  background-color: $reminderRedColor;
}

.reminder-row-ii {
  background-color: $reminderGreen !important;
}

.mat-mdc-select-panel {
  margin-top: 42px;
  background-color: $selectPanelBackground;
  border: 1px solid $boxContainerBackground;
  margin-left: -1px;
}

/*TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version.*/
.mat-autocomplete-panel {
  background-color: $selectPanelBackground;
  border-radius: 4px;
  margin-left: -1px;
  border: 1px solid $boxContainerBackground;

  .mat-mdc-option {
    line-height: 36px;
    height: 36px;
  }

  /*TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
  .mat-option-text {
    font-size: 12px;
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
mat-option:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.node-container {
  display: table-cell;
  vertical-align: middle;
}


.read-only-btn,
.redirect-pdcis {
  object-fit: contain;
  border-radius: 3px;
  border: solid 1px $redirectBtns;
  font-family: asapregular !important;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.25px;
  color: $redirectBtns;
  padding: 8px !important;
  position: absolute;

  top: -85px;

  background-color: $tableHeaderBackgroundColor;
}

.read-only-btn {
  right: 0;
}

.redirect-pdcis {
  right: 120px;
}

.reminder-row {
  background-color: yellow;
}

.reminder-row-ii {
  background-color: $reminderGreen;
}

.mat-tooltip {
  color: $selectPanelBackground;
  background-color: gray;
}

.preloader-img {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100px;
  height: 100px;
}


.sem-data-grid-th {
  padding-right: 42px;

  p,
  .sorting-container {
    display: table-cell;
    vertical-align: middle;
  }

  p {
    white-space: nowrap;
  }

  .sorting-container {
    padding: 0 0 0 20px;
  }

}

th.checkbox-bulk-table-head {
  padding-left: 42px;
  padding-right: 42px;
}

.listing-grid-wrapper {
  margin-left: 143px;
  margin-right: 35px;
  margin-top: 36px;

  br {
    display: none;
  }

  .SemLayoutHolderControl {
    display: table;
    table-layout: fixed;
    width: 100%;

    .SemFacetsControl {
      padding-right: 36px;
    }

    >.GalisFormControl {
      vertical-align: top;
      display: table-cell;
    }

    >.GalisFormControl:first-child {
      width: 250px;
    }

    .hide-facets {
      display: none;
    }

    .retract-facets {
      width: 80px !important;

      .SemFacetsControl {
        border-radius: 5px;
        padding-right: 36px;
      }
    }
  }
}

.hide-facets {
  display: none;
}

.control-loader {
  height: 80px;
  width: 80px;
  padding: 40px 20px 20px 20px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

app-sem-dynamic-control {
  .GalisFormControl {
    display: block !important;
    width: auto !important;
  }
}

.quick-preview-container {

  .quick-preview-header {
    .zoom-in-icon {
      height: 17px;
    }
  }

  .bound-label-container {
    text-align: center;

    .bound-label-value {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: center;
      color: #313541;
    }
  }

  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-value-text {
    font-size: 13px;
  }

  .dd-delete {
    top: 5px;
  }

  .dd-loader {
    height: 18px;
    top: -4px;
  }

  .SemLabelControl {
    padding-top: 16px !important;
  }

  .SemDropDownSelectControl,
  .SemComboBoxControl {
    margin-top: 0 !important;
  }

  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }

  .SemDropDownSelectControl mat-form-field,
  .SemComboBoxControl mat-form-field {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  textarea {
    box-sizing: border-box;
    display: block;
    position: relative;
    font-size: 13px !important;
    padding: 6px 10px 10px 13px !important;
    min-height: 100px;
    margin-top: 0px !important;
  }

  .ta-counter {
    position: absolute;
    right: -30px;
    top: 0;
  }

  input {
    padding-left: 13px;
    font-size: 13px !important;
  }

  .SemDropDownSelectControl .classic-dropdown .mat-mdc-form-field-infix,
  .SemComboBoxControl .classic-dropdown .mat-mdc-form-field-infix {
    padding-top: 3px !important;
  }

  mat-form-field {
    margin-bottom: 0px;
  }

  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper,
  input,
  .mat-mdc-form-field-wrapper,
  input {
    height: 25px !important;
  }

  sem-text-area {
    display: block;
    position: relative;
  }

  .SemMultiColumnControl {
    display: table;
    width: 100%;

    label {
      text-transform: uppercase;
      color: $underline;
      font-size: 10px;
    }

    .SemColumn:first-of-type {
      width: 30% !important;
      padding: 0px 0px 0px 25px !important;
      text-align: left !important;
    }

    .SemColumn:nth-of-type(2) {
      width: 70% !important;
      padding: 0 40px 0 10px !important;
    }
  }

  .SemDropDownSelectControl .mat-mdc-form-field-infix,
  .SemComboBoxControl .mat-mdc-form-field-infix {
    padding-left: 13px !important;
  }

  .SemColumn {
    float: none !important;
    width: auto !important;
    display: table-cell !important;
  }
}

.quick-preview-header-controls {
  text-align: right;
}

.scrollable-wrapper {
  height: 30px;
}

table th {
  position: sticky;
  top: -1px;
  z-index: 1;
  opacity: 1;
  background-color: $inputBackgroundII !important;
}

.pagination-container {
  border-top: solid 1px #ccd2d5;
}

.table-wrapper {
  overflow: auto;
  height: calc(50vh - 65px);

  /*transform: rotate(180deg);

  table{
    transform: rotate(180deg);
  }*/

}

.facets-container {
  overflow: auto;
  height: calc(50vh + 30px);
}


/*::-webkit-scrollbar-track
{
    -webkit-box-shadow: none;
    border-radius: 10px;
    background-color: transparent;
    margin:2px;
    padding: 2px;
}

::-webkit-scrollbar
{
    width: 8px;
    height: 8px;
    background-color: transparent;
    margin: 2px;
    padding: 2px;
}

::-webkit-scrollbar-thumb
{  
  border: 4px solid rgba(0, 0, 0, 0);
    -webkit-box-shadow: none;
    padding: 2px;
    background-color: rgba(0,0,0,0.1);
}*/
/*
::-webkit-scrollbar {
  width: 14px;
  height: 14px !important;
}

::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #AAAAAA;
}

::-webkit-scrollbar-track {
}*/


.table-wrapper {
  .ps--active-x {
    border-top: solid 1px #ccd2d5;
  }
}

app-root {
  display: block;
  position: relative;
}

.SemFormTabsControl {
  margin-left: -25px;
}

.cdk-global-scrollblock {
  position: static !important;
}

.main-content-holder {
  box-shadow: none !important;
  border-radius: unset;
  background-color: transparent !important;
  table-layout: fixed;
  padding: 0;
  margin-top: 40px !important;
}

.dialog-title-heading {

  padding: 0;
  margin: 20px 0 20px 0 !important;

}

.tree-search-wrapper {
  margin-top: 30px;

  label {
    font-size: 15px;
    font-family: $mediumFontFamily;
    padding-right: 5px;
    display: block;
  }

  input {
    border-radius: 3px;
    border: #ccd2d5 solid 1px;
    height: 25px;
    margin-top: 6px;
    font-family: $regularFontFamily;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $mainInputText;
    vertical-align: middle;
    margin-bottom: 22px;
    background-color: transparent;
    z-index: 99;
    cursor: pointer;
  }
}

mat-tree {
  padding-right: 10px;

  li {
    border-bottom: 1px solid $lightBottomBorder;
  }
}

.listing-grid .listing-manipulate-section {
  margin-top: 27px;
}


.dash>.sub-content {
  padding: 0;
}


.pie-chart-container {
  display: block;
  width: 100%;
  height: 100%;
}


//HERO COMPONENT
.sem-hero-component {
  padding: 38px;

  >* {
    box-sizing: border-box;
  }

  >div:first-of-type {
    border-right: 5px solid white;
  }

  >div:last-of-type {
    border-left: 2.5px solid white;
  }

  .highlight-section,
  .grid-section {
    display: inline-block;
    width: 50%;
    vertical-align: top;
    height: 405px;
  }

  .grid-section {
    text-align: left;
  }

  .chart-legend .legend-title {
    display: none;
  }

  .line-series path {
    stroke-width: 3.5px;
  }

  .highlight-section {

    background-color: $heroContainerBackground;

    .GalisFormControl,
    .SemHeroHighlightLayout,
    .SemLastAddedRecords,
    sem-last-added-records,
    .last-added-background {
      height: 100%;
      width: 100%;
    }

    sem-last-added-records {

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 10;
      }

      sem-image-viewer {

        .actual-image {
          width: 100% !important;
          height: 100% !important;
          position: absolute;
          background-size: cover;
        }

      }
    }

  }

  .SemHeroGridLayout {
    >* {
      display: inline-block;
      width: 50%;
      overflow: hidden;
      background-color: #f0f0f0;
      vertical-align: top;
      height: 50%;
      box-sizing: border-box;
    }

    >*:nth-child(1),
    >*:nth-child(3) {
      border-right: 5px solid white;
    }

    >*:nth-child(1),
    >*:nth-child(2) {
      border-bottom: 5px solid white;
    }
  }

  .grid-section-item,
  .grid-section-item>.GalisFormControl,
  .SemHeroGridLayout {
    height: 100%;
  }

  sem-graph-pie div svg {
    margin-top: 35px;
  }

  ngx-charts-line-chart {
    display: block;
    margin: 0 0 0 25px;
  }
}

.chart-info p,
.directional-graph-container p {
  font-family: $regularFontFamily;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1px;
  color: $textI;
  text-transform: uppercase;
}

.chart-info {
  display: block;
  text-align: left;
  margin: 34px 0 15px 34px;

  p {
    font-family: $regularFontFamily;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 1px;
    color: $textI;
    text-transform: uppercase;
  }

  p:last-of-type {
    font-family: $boldFontFamily;
  }
}

.SemQuickActions {
  position: relative;

  .container {
    padding-left: 50px;
    padding-right: 15px;
    margin-top: 34px;
    position: static;
  }

  .action-title {
    font-family: $regularFontFamily;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 1px;
    color: $textI;
    text-transform: uppercase;

    span {
      font-family: $boldFontFamily;
      display: block;
    }
  }

  .value {
    font-family: $regularFontFamily;
    font-size: 50px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $heroComponentValueColor;
    margin-top: 26px;
  }

  .action-type {
    font-family: $mediumFontFamily;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 1px;
    margin: 34px 0 10px 0;
  }

  .qa-shortcut {
    .action-title {
      font-family: $boldFontFamily;
    }
  }

  img {
    position: absolute;
    bottom: 55px;
    left: 50px;
    width: 34px;
    height: 11px;
    background-size: contain;
  }
}

.last-added-background {
  display: block;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  .bottom-left-ribbon {
    position: absolute;
    left: 0;
    bottom: 33px;
    width: 218px;
    padding: 11px 23px 11px 18px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: rgba(255, 255, 255, 0.85);
    text-align: left;

    p:first-of-type {
      font-family: $regularFontFamily;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $heroComponentValueColor;
      margin-bottom: 3px;
    }

    p:last-of-type {
      font-family: $mediumFontFamily;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: $textI;
      text-transform: uppercase;
    }
  }
}

.node-img {
  text-align: right;
  width: 17px;
  padding-left: 20px;
}


.left-menu-node-icon {
  height: 21px;
  width: 17px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.grid-item:hover {
  background-color: $sectionTitleBackgroundColor;
}

.grid-item {
  padding: 11px 0px;

  sem-image-viewer {
    position: relative;
  }

  cursor: pointer;
  justify-content: center;
  align-items: stretch;
  flex: 0 0 16%;
  border-left: 2px solid $boxContainerBackgroundII;
  border-bottom: 2px solid $boxContainerBackgroundII;
  justify-content: center;
  min-width: 170px;
  text-align: center;
  padding-bottom: 11px;

  p {
    display: block;
    font-size: 12px;
    font-family: $regularFontFamily;
    color: $textI;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 10px;
    min-height: 16px;
  }

  p:nth-of-type(1) {
    padding-bottom: 8px;
    text-transform: uppercase;
    font-family: $mediumFontFamily;
    padding-top: 13px;
    font-weight: 600;
  }

  p:nth-of-type(2) {
    padding-bottom: 8px;
  }

  .actual-image {
    width: 150px !important;
    height: 100px !important;
  }

  p:nth-of-type(3) {
    color: $underline;
  }
}

.grid-item-large {
  min-width: 33%;
  aspect-ratio: 1;
  position: relative;

  sem-image-viewer {
    position: inherit;
    width: 80%;
    height: 80%;
    text-align: center;
    margin: auto;
  }

  .actual-image,
  .sem-image-container {
    width: 100% !important;
    height: 100% !important;
  }
}

.grid-item:first-of-type,
.grid-item:nth-child(5n+1) {
  //border-left: 0;
}

.grid-view {
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  padding: 22px 22px 22px 24px;
  overflow: hidden;
  position: relative;

  .border-hide {
    width: 2px;
    background-color: white;
    position: absolute;
    height: 100%;
  }
}

.assign-content {
  display: table-cell;
  vertical-align: top;
  padding: 70px 0 0 90px;

  .prop-assigner {
    margin-bottom: 10px;
  }

  .assign,
  .remove-assign {
    height: 15px;
    width: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
  }

  .assign {
    background-image: url("images/platform/double-arrow-right@3x.png");
    margin-left: -20px;
  }

  .remove-assign {
    background-image: url("images/platform/remove-section@3x.png");
    vertical-align: top;
    padding-left: 20px;
  }

  * {
    display: inline-block;
    vertical-align: middle;
  }
}

.grid-view-settings {
  .left-footer span {}
}

.quick-preview-container {
  .actual-image {
    margin-bottom: 15px;
    margin-top: 15px;
  }
}

.logo-holder {
  p {
    display: inline-block;
  }
}

.open {
  display: inline-block;
  margin-left: 35px;
}

.span-bold {
  font-weight: 600;

  >span {
    font-weight: normal;
  }
}

.edit-btn {
  position: absolute;
  top: 32px;
  right: 30px;
  padding: 5px;
  background-color: transparent;
  border: 1px solid $highlightColor;
  border-radius: 3px;
  font-size: 12px;
  font-family: $regularFontFamily;
  cursor: pointer;
}

.SemTablePresentation {
  table-layout: fixed;
  width: 80%;
  text-align: left;
  padding: 15px 0 30px 30px;

  h1 {
    padding-left: 0;
    margin: 15px 0 15px 0;
    text-align: left;
  }

  table {
    text-align: left;
    margin-top: 15px;
  }

  th,
  td {
    padding: 8px;
    font-family: $regularFontFamily;
    border-top: 0 !important;
    font-size: 13px;
  }

  th {
    border: 0 !important;
    font-weight: 600;
    background-color: transparent !important;
  }
}

.table-presentation-wrapper {
  max-height: 300px;
  overflow: auto;
}

.table-presentation,
sem-graph-pie {
  .action-loader {
    margin: auto;
    height: 50px !important;
  }
}

sem-graph-pie {
  position: relative;

  .action-loader {
    margin-left: 30px;
  }
}

.inline-edit {
  table {
    width: 100%;
  }
}

.cancel-div {
  cursor: not-allowed !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after,
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: $textI;
}

.child-node-padding {
  margin-left: 20px;
}

path.link {
  stroke: #666 !important;
  stroke-width: 1.5px !important;
  fill: none;
}

circle {
  fill: #ccc;
  stroke: #fff;
  stroke-width: 1.5px;
}

text {
  fill: #000;
  font: 10px sans-serif;
  pointer-events: none;
}

.graph-wrapper {}

.directional-graph-container {
  margin-bottom: 32px;
  margin-left: 143px;
  margin-right: 35px;
  height: auto;
  padding: 38px;

  border-radius: 5px;
  box-shadow: 0 2px 6px 0 rgb(176 186 197 / 60%);
  background-color: white;
}

.directional-graph-container p {
  margin-bottom: 30px;
}

.left-menu-wrapper {
  position: relative;
  height: 100vh;
  /* Ensures that the wrapper takes up the full height of the viewport */
}

.left-menu-nodes {
  overflow: auto;
  overflow-x: hidden;
  height: calc(100% - 150px);
  /* Subtract the height of the sub-sidebar */
  padding-top: 8px;

  .item-wrapper:last-of-type {
    padding-bottom: 100px;
  }
}

.grid-o2m {
  height: 40vh;
  overflow-x: hidden;
  overflow-y: auto;

  .grid-item .actual-image {
    height: 150px !important;
  }

  .grid-item {
    min-width: 190px;
  }
}

.SemTextBoxControl,
.SemDropDownSelectControl,
.SemTextAreaControl,
.SemDateBoxControl,
.SemTimePickerControl {
  margin-top: 10px;
}

.SemDropDownSelectControl {
  margin-bottom: 10px;
}

.manipulate-section-wrapper {
  .manipulate-section {
    float: none;
    margin: 10px 0 0 0;
  }
}

sem-one-to-many-container {
  .one-to-many-section-wrapper:last-of-type {
    .repeater-section {
      margin-bottom: 0;
    }
  }
}

input.sem-time-picker {
  border-radius: 3px;
  border: solid 1px #ccd2d5;
  /*height: 40px !important;*/
  height: 40px;
  width: 100%;
  margin-top: 6px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313541;
  vertical-align: middle;
  margin-bottom: 12px;
  background-color: transparent;
  z-index: 99;
  cursor: pointer;
  display: block;
}

sem-time-picker {
  display: block !important;
  left: -5px !important;
}

.readonly-table-layout {
  thead td {
    font-weight: bold !important;
  }

  th,
  td {
    text-align: left !important;
  }
}

.inline-edit {
  table tbody tr {
    position: relative;
  }

  .search-redirect-a {

    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.notifications {
  .number-of-pages-setting {
    display: block;
    height: 45px;
  }

  .drop-down-sorting-icon {
    margin-left: 15px;
  }
}

.table-controls-notification {
  border-radius: 5px;
}

.underline_span {
  text-decoration: underline;
  color: $highlightColor;
}

.sem-dynamic-field-content div>.dynamic-checkbox {
  width: 23px;
  height: 23px;
}

.sem-dynamic-field-content .sem-dynamic-checkbox {
  vertical-align: middle;
}

.sem-dynamic-field-content .dynamic-checkbox-image-false {
  width: 25px;
  background-size: contain;
}

.sem-dynamic-field-content .dynamic-checkbox-image-true {
  width: 14px;
  background-size: contain;
}

.table-footer .active {
  color: $highlightColor;
  font-weight: bold;
}

.facets-container {
  .tooltip-text {
    display: inline-block !important;
    background-color: white;
    z-index: 10;
    font-family: $regularFontFamily;
    font-size: 15px;
    border: 1px solid lightgray;
  }

  .facet-label-tooltip {
    top: 35px;
    left: 0px;
  }

  .facet-tooltip-collapse {
    top: 25px;
    left: -120px;
  }
}

.o2m-fg {
  margin-bottom: 6px;
}

sem-dynamic-search-field .dd-delete {
  width: 12px !important;
  top: 8px !important;
}

.ps-content {
  padding-bottom: 30px;
}

.statistics-search {}

.statistics-fields {

  .col-values-wrapper {
    display: inline-table;
    width: 100%;

    >* {
      display: table-cell;
      float: none !important;
      vertical-align: top;
    }

    >div:first-of-type {
      width: 20% !important;
      border-right: 1px solid lightgray;
    }

    >div:nth-of-type(2) {
      width: 80% !important;
      padding-left: 50px !important;
    }

    >div:last-of-type {
      display: none;
    }
  }

}

.statistics-wrapper {

  .frm-section {
    padding-left: 15px !important;
    margin-top: 10px;
  }

  .SemLabelControl,
  .bound-label-container {
    padding: 15px 0 15px 0;
  }

  label,
  .bound-label-value {
    font-size: 12px;
    font-family: $secRegularFontFamily;
  }

  .SemColumn {
    min-height: auto !important;
    //padding: 10px 0 10px 0 !important;
  }

  display: inline-table;
  width: 100%;

  >* {
    display: table-cell;
  }

  >div:first-of-type {
    width: 25% !important;
  }

  >div:nth-of-type(2) {
    width: 75% !important;
  }

  >div:last-of-type {
    display: none;
  }
}

.col-values-section {
  >.SemLayoutHolderControl>.GalisFormControl {
    border-bottom: 1px solid lightgray;
  }
}

.col-title-section {
  padding-left: 15px;
}

.st-btn {
  background-color: $sectionTitleBackgroundColor;
  width: 120px;
  padding: 5px !important;
  font-size: 12px;
  font-family: $secRegularFontFamily;
  border: none; // 1px solid lightgray;
  border-radius: 3px;
  margin-bottom: 10px;
  cursor: pointer;
}

.user-profile-popup {
  .icon-link-data {
    background-image: url('images/platform/user_profile_img@3x.png');
  }

  .task-enabled-checkbox input {
    cursor: pointer;
  }

  a,
  button {
    border-color: $popupColor !important;
    font-family: $secRegularFontFamily !important;
    color: $popupColor !important;
    font-weight: normal !important;
    text-transform: uppercase !important;
    font-size: 11px !important;
  }

  .profile-page-dialog-header {

    .right-sub-section-holder {
      padding: 3px;
      margin-left: 20px;
      display: inline-block;
    }

    .icon-close-profilepage {
      background-image: url('images/platform/icon-close.png');
      height: 15px;
      width: 15px;
      background-size: contain;
      cursor: pointer;
      display: inline-block;
      position: absolute;
      right: 20px;
    }

  }



  .dialog-title-heading {
    font-family: $secRegularFontFamily;
    margin: 25px 0 30px 0 !important;
    text-transform: uppercase !important;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2px;
  }

  .popup-title-wrapper {
    padding-left: 20px;

    .up-buttons {
      padding: 0 !important;
    }

    >* {
      width: 50%;
      display: inline-block !important;
    }
  }

  .tab-content-actions {

    button {
      margin-right: 0;
    }
  }

  .profile-popup-section .mat-tab-label {
    padding-right: 45px !important;
  }

  .profile-popup-section .form-group {
    margin: 0 !important;
  }
}

.confirmation-error {

  background-color: rgb(227, 76, 76, 0.07) !important;

  padding: 33px;

  >* {
    display: block !important;
    height: auto !important;
  }

  p {
    font-family: $secRegularFontFamily;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #484342;
    font-weight: 600;
    height: auto !important;
    margin-bottom: 16px;
  }

  .icon {
    height: 20px !important;
    width: 20px;
    vertical-align: top !important;
  }

  .side-con {
    padding-left: 34px;
  }

  button {
    background-color: transparent;
    padding: 6px 19px;
    text-transform: uppercase;
    font-family: $secRegularFontFamily;
    font-size: 13px;
    border-radius: 3px;
    border: 1px solid $statusInActive;
    margin-right: 12px;
    color: $statusInActive;
  }

  border-left: 4px solid $statusInActive !important;
}


.confirmation {

  background-color: rgb(236, 239, 241) !important;

  padding: 33px;

  >* {
    display: block !important;
    height: auto !important;
  }

  p {
    font-family: $secRegularFontFamily;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #484342;
    font-weight: 600;
    height: auto !important;
    margin-bottom: 16px;
  }

  .icon {
    height: 20px !important;
    width: 20px;
    vertical-align: top !important;
  }

  .side-con {
    padding-left: 34px;
  }

  button {
    background-color: transparent;
    padding: 6px 19px;
    text-transform: uppercase;
    font-family: $secRegularFontFamily;
    font-size: 13px;
    border-radius: 3px;
    border: 1px solid #447695;
    margin-right: 12px;
    color: #447695;
    cursor: pointer;
  }

  .button {
    background-color: transparent;
    padding: 6px 19px;
    text-transform: uppercase;
    font-family: $secRegularFontFamily;
    font-size: 13px;
    border-radius: 3px;
    border: 1px solid #447695;
    margin-right: 12px;
    color: #447695;
    cursor: pointer;
  }

  border-left: 4px solid #447695 !important;
}

.chat-wrapper {
  width: calc(100% - 104px);
  text-align: center;
  margin-left: 60px;


  .media-container {
    padding-bottom: 0 !important;

    .inline-imgs {
      div {
        vertical-align: bottom;
      }
    }
  }

  .inline-imgs {

    ul {
      display: inline-block;
      padding-bottom: 15px;

      li {
        display: inline-block;
        height: 84px;
        width: 115px;
        margin-right: 6px;

        .pretty-inv {
          color: #000 !important;
          padding-top: 10px;
          font-size: 12px;
          text-align: center;
          font-family: 'robotoregular';
        }

      }

    }
  }

  .affirmation {
    p {
      height: 80px;
      font-family: $secRegularFontFamily;
      vertical-align: middle;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $textI;
      line-height: 80px;
      padding-left: 34px;
    }
  }

  .redirect {
    border-left: 0 !important;
    background-color: white !important;

    .button {
      border-color: $textI !important;
      color: $textI;
    }

    button {
      border-color: $textI !important;
      color: $textI;
    }
  }

  input,
  input:focus {
    outline: 0 !important;
    -webkit-appearance: none;
  }

  input[type=text]:focus {
    border: 0px !important;
  }



  .liner-inline {
    >* {
      display: inline-block;
      vertical-align: middle;
    }
  }

  >img {
    margin-top: 100px;
    height: 50px;
    width: 50px;
  }

  h1 {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2.5px;
    color: $textI;
    margin: 16px 0 16px 0;
  }

  h2 {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: $textI;
    font-family: $secRegularFontFamily;
    width: 620px;
    margin: auto;
    margin-bottom: 36px;
  }

  .liner-block {
    box-sizing: border-box;
    margin: 0 37px 0 37px;
    width: 100%;
    //border: 1px solid $highlightColor;
    box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);
    border-radius: 5px;
    text-align: left;
    background-color: white;
    position: relative;
    min-height: 80px;
    display: inline-table;
    margin-bottom: 16px;

    >* {
      vertical-align: middle;
      display: inline-block;
      height: 80px;
    }

    >img:first-of-type {
      height: 20px;
      width: 20px;
      margin-left: 33px;
    }

    >img:last-of-type {
      position: absolute;
      height: 24px;
      width: 24px;
      right: 33px;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    input,
    textarea {
      font-size: 15px;
      font-family: $secRegularFontFamily;
      color: $textI;
      margin-left: 34px;
      border: 0;
      outline: none;
      width: 80%;
    }

    textarea:focus,
    input:focus {
      outline: none;
    }
  }


  //alen-css


  .chat-usergpt-inout {
    padding-bottom: 100px;
  }

  .chat-user-input {
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    padding-left: 120px;
    padding-right: 90px;
  }

  .server-response-item {}

  .user-input-item {
    position: relative;

    img {
      position: absolute;
      right: auto !important;
      left: 34px;
    }

    p {
      font-family: $secRegularFontFamily;
      vertical-align: middle;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $textI;
      padding-top: 34px;
      padding-left: 90px;
      padding-right: 35px;
    }
  }

  .chat-loader {
    text-align: left;
    padding: 20px;
    padding-left: 40px;

    img {
      width: 36px;
    }
  }
}

.parameter-izpis {

  margin-bottom: 2px !important;
}

.content {
  width: auto;
}

.annotation-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.preview-wrap {
  >div {
    height: 100%;
    width: 100%;
    display: block;
  }
}

.img-list-item {
  min-height: 115px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.preview-holder {
  box-sizing: border-box;
  padding: 10px 20px;

  p {
    font-size: 12px;
    font-weight: 600;
    font-family: $secRegularFontFamily;
    padding-top: 10px;
  }
}

.thumb-wrapper {
  .selected {
    background-color: rgba(26, 128, 182, 0.15);
  }
}

.thumb-wrapper {
  height: 100%;
  width: 100%;
  overflow: auto;
  padding-bottom: 50px;
  padding-top: 10px;

  p {
    font-family: $secRegularFontFamily;
    vertical-align: middle;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $textI;
    font-weight: 600;
  }
}


.ps-content {
  padding-bottom: 130px;
}

/*.content {
  width: 100% !important;
}*/

pdf-viewer {
  width: 100%;
  height: 100%;
}

.one-btn {
  button {
    width: 100% !important;
    border: 0;
    text-align: left;
    padding-left: 18px;
  }
}

.grid-view-listing table td {
  position: relative;
}

.child-node-padding {
  padding-left: 15px;
}


.control-help {
  display: inline-block;
  background-image: url('images/platform/help-icon.png');
  background-size: 15px 15px;
  background-repeat: no-repeat;
  float: right;
  width: 15px;
  height: 15px;
  background-position: center;
  cursor: pointer;
  margin-right: 3px;
}

.control-help:hover+.control-help-text {
  visibility: visible;
  position: absolute;
  right: 0px;
  max-width: 500px;
  width: auto;
  padding-left: 5px;
  margin-top: 30px;
  padding-right: 5px;
}

.control-help-text {
  font-weight: bold;
}

.chat-navigation {
  background-image: url('images/platform/chat-navigation@3x.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 23px 23px;
  width: 58px;
  cursor: pointer;
  position: relative;
  display: table-cell;
  vertical-align: middle;
  height: 48px;
  padding-left: 10px;
  padding-right: 37px;
}

.ngx-pe-img {
  max-height: 65vh !important;
}

.grid-view-oneline {
  .grid-item-large {
    min-width: 100%;
    aspect-ratio: 2;
  }
}

.listing-loader {
  width: 100%;
  height: 150px;

  img {
    padding: 0;
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.disabled-field,
.disabled-field-dd,
.mat-select-disabled span {
  cursor: not-allowed !important;
  //color: $textI !important;
  color: $disabledFieldColor !important;
}



.gallery-item {

  max-width: 200px;
  vertical-align: top;

  sem-image-viewer {
    display: block;
    height: 200px;
    width: 200px;
  }

  display: inline-block;
  border: 0 !important;
  padding: 0 5px 10px 5px;

  .actual-image {
    width: 200px !important;
    height: 200px !important;
    background-size: cover !important;
  }
}

.gallery-title {
  font-family: $secRegularFontFamily;
  color: $textI;
  font-size: 24px;
  text-transform: uppercase;
  margin-top: 50px;
  margin-bottom: 20px;
  letter-spacing: 2px;
  font-weight: 600;
}

.yesno-question-label {
  font-family: $secRegularFontFamily;
}

.mat-mdc-text-field-wrapper {
  width: 100%;
  background-color: transparent !important;
  display: block;
}

.SemDropDownSelectControl {
  .mat-mdc-text-field-wrapper {
    height: 40px;
  }

  .mat-mdc-text-field-wrapper {
    border: $boxContainerBackground solid 1px;
    border-radius: 3px;
  }
}

.mdc-text-field--filled {
  --mdc-filled-text-field-active-indicator-height: 0 !important;
}

.mat-mdc-form-field-infix {
  padding: 5px 0 0 0 !important;
}

.mat-mdc-select-min-line {
  font-size: 14px !important;
}

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
}

mat-datepicker-toggle {
  display: none;
}

.mat-mdc-dialog-surface {
  overflow: hidden;
}

/*
.loader {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  z-index: 9999999;

  img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 100px;
  }
}*/

.loader-mp-wrapper {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9999999;
}

.loader-opacity {
  background-color: rgba(0, 0, 0, 0.4);
}

.sided-50 {
  .loader {
    top: auto;
    right: auto;
    left: 50px;
    bottom: auto;
  }
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  animation: rotate 1s linear infinite;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 1000;
}

.loader::before,
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #FFF;
  animation: prixClipFix 2s linear infinite;
}

.loader::after {
  inset: 8px;
  transform: rotate3d(90, 90, 0, 180deg);
  border-color: $loaderColor;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
  }

  75%,
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
  }
}

.ng-scroll-content {
  display: block !important;
}

.all-search-fields .ng-scroll-content {
  padding-bottom: 30px;
}

.userprofile-center-content {
  margin-top: 60px;
  font-family: $regularFontFamily;

  .user-subscription {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
  }

  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border: 1px solid #ccc;
  }

  .checkbox-container:hover input~.checkmark {
    background-color: #ddd;
  }

  .checkbox-container input:checked~.checkmark {
    background-color: #2196F3;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .checkbox-container input:checked~.checkmark:after {
    display: block;
  }

  .checkbox-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }

  .user-data-container {
    margin-left: 143px;
    margin-right: 35px;
    height: auto;
  }

  .user-data-info {

    .data-label,
    .usergroup-label-info {
      font-weight: bold;
      margin-bottom: 15px;
      font-size: 20px;
    }

    .usergroup-label-info {
      display: inline-block;
      margin-right: 10px;
    }

    .group-label {
      font-size: 20px;
    }

    .data-value-text {
      font-weight: 500;
    }
  }

  .user-data-header-label-container {
    margin-top: 20px;
    margin-bottom: 20px;

    .user-data-header-label-info {
      font-weight: bold;
      font-size: 25px;
      font-family: $regularFontFamily;
    }

    .user-data-header-label-value {
      font-size: 20px;
      font-family: $regularFontFamily;
    }

  }

  .user-subscription {
    .task-subscription-label {
      font-size: 20px;
      font-family: $regularFontFamily;
      margin-right: 10px;
    }
  }
}

.half-screen-form {
  position: absolute;
  left: 144px;
  width: 68%;
  background-color: white;
  z-index: 999;
  right: 30px;
  top: 56vh;
  height: 40vh;
  overflow: auto;
}

.half-screening {
  display: block !important;
  height: 40vh;
  overflow: auto;
}

.popuptask {
  a {
    color: #0000FF !important;
    cursor: pointer;
  }
}

.version-p {
  color: black;
  position: absolute;
  bottom: 5px;
  left: 5px;
  right: 0;
  font-size: 12px;
  margin: auto;
  top: auto;
}

.version-dd-val {
  border-top: 1px solid #ccd2d5;

  p {
    padding-left: 5px !important;
    font-weight: bold;
    font-size: 12px !important;
  }

  border-left: 0 !important;
}

.version-dd-val:hover {
  background-color: transparent !important;
  border-left: 0 !important;
}

.split-s-form {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  width: 100%;

  background-color: #f8f8f8;
  z-index: 9;

  .main-input-form {
    display: block;
  }

}

.split-holder {
  margin-left: 323px;
  background-color: white;
  margin-right: 35px;
  margin-top: 21px;
  padding-top: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
  box-shadow: 0 2px 6px 0 rgba(176, 186, 197, 0.6);
  border-radius: 5px;
  margin-bottom: 150px;

  >div {
    margin-left: 25px;
  }

  .sem-one-to-many-c sem-one-to-many-container {
    display: block !important;
  }

  .sem-one-to-many-c>* {
    display: none !important;
  }





  .SectionTitleControl p {
    font-family: "asapregular";
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000;
    text-transform: uppercase;
  }

  .frm-section {
    margin-bottom: 10px !important;
    background-color: rgba(204, 146, 47, 0.15);
    color: #000;
    height: 28px;
    padding-top: 5px;
    padding-left: 6px;
  }


  .galis-top-multicolumn .SemLayoutHolderControl:first-of-type>* {
    display: inline-block !important;
  }




}

.split-header {
  padding-left: 323px;
  background-color: white;
  height: 56px;
  position: relative;

  .close {
    position: absolute;
    right: 33px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 15px;
    width: 15px;
    cursor: pointer;
  }

  a {
    color: $textI !important;
  }

  border-top: solid 2px #e7ebee;
  border-bottom: solid 2px #e7ebee;

  .breadcrumb {
    margin-left: 0;
  }

  .ver-separator {
    width: 2px;
    height: 25px;
    margin-left: 25px;
    background-color: #e5eaed;
    display: inline-table;
    vertical-align: top;
    margin-top: 10px;
  }
}

.collapsed .content .split-s-form .split-holder {
  margin-left: 143px;
}

.collapsed .content .split-s-form .split-header {
  padding-left: 143px;
}

.split-sub-header {
  height: 46px;
  border-top: 0;

  ng-scrollbar {
    top: -35px;
    left: 220px;
  }

  .ng-scroll-viewport {
    width: 100%; //calc(100vh - 10px) !important;
  }

  .s-liner {
    display: inline-table;
    height: 100%;
    margin-bottom: 21px;
    cursor: pointer;

    * {
      display: table-cell;
      vertical-align: middle;
    }

    p {
      text-transform: uppercase;
      font-size: 11px;
      padding-left: 10px;
    }

    div {
      height: 100%;
      width: 15px;
      background-image: url(images/platform/s-icon-open@3x.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  .ng-scroll-content {
    display: inline-block !important;
  }
}

.splitted-view {
  padding-bottom: 50vh;
}

.ld-table {
  margin-left: 25px;

  * {
    border: 0;
  }

  tr {
    border-bottom: 1px solid #ccc;

    p {
      padding-bottom: 8px;
    }
  }
}

.s-items {
  display: inline-block;
  padding-left: 20px;
  vertical-align: top;
  height: 48px;
  white-space: nowrap;
  padding-right: 180px;

  p {
    text-transform: uppercase;
    font-size: 11px;
  }

  >* {
    display: inline-table;
    background-image: none;
    height: 45px;
    padding-right: 25px;

    >* {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .img-close {
    background-image: url('../../assets/css/images/platform/icon-close-gray@3x.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 10px;
    width: 10px;
    cursor: pointer;
  }

  input {
    border-radius: 5px;
    border: solid 1px #ccd2d5;
    background-color: transparent;
    font-size: 11px;
    height: 25px;
    margin-top: 10px;
    padding-left: 12px;
    margin-left: 16px;
    margin-right: 13px;
  }

  img {
    height: 10px;
  }
}

sem-text-box {
  position: relative;

  .inline-search-wrapper {
    position: absolute;
    z-index: 99;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
  }
}

sem-text-box {
  display: block;
}

.in-toggle {
  display: inline-block;
  vertical-align: top;
  margin-top: 13px;
  margin-left: 22px;
}

sem-switch {
  display: inline-block;
  vertical-align: middle;
  margin-top: 14px;
  cursor: pointer;

  img {
    width: 30px;
  }

  >div {
    * {
      display: inline-block;
      font-size: 11px;
      vertical-align: middle;
      padding: 2px;
    }
  }
}

.s-type {
  display: inline-block;
  vertical-align: middle;
  padding-top: 10px;
  margin-left: 20px;
  cursor: pointer;

  * {
    display: inline-block;
    vertical-align: middle;
  }

  p {
    font-size: 11px;
    padding-left: 3px;
  }

  img {
    height: 15px;
  }
}

.s-header-action {
  position: absolute;
  right: 30px;
  top: 19px;
  bottom: 0;
  margin: auto;
  left: auto;

  .ver-separator{
    margin-top: -4px;
    margin-right: 25px;
  }

  img{
    height: 18px;
    cursor: pointer;
  }
}

.view-settings-dialog{

  box-shadow: none !important;
  width:800px !important;
  height: 700px !important;
  // overriding tabs css for view-settings-dialog

  .mat-tab-group {
    display: flex !important;    
  }

  .mat-tab-header{
    display: flex !important;
    max-width: none; /* or a specific value */
    width: auto;
  }

  .mat-tab-labels{
    display:flex !important;
  }

  .mat-tab-body-wrapper{
    padding-top:20px;
  }

  .view-settings-validation-error-message{
    text-align: center;
    color: red;
    font-family: $boldFontFamily;
  }
  
  .footer{    
    .left-footer{
      p{
        padding-top: 6px;
      }
    }
  }
  

  .basic-view-settings{
    label {
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      text-transform: none !important;
      font-size: 12px;
      font-family:$regularFontFamily;
    }

    input[type="text"]{
      width:90% !important;
    }
  }

  .mat-tab-label.mat-tab-label-active {
    border-bottom: 2px solid $highlightColor;
    border-right: 0px solid !important;
    background-color: transparent !important;
    color: $mainFieldsColor !important;
  }

  .mat-tab-label{
    border-bottom: solid 2px #e5eaed;
    font-weight: bold;
    color: $mainFieldsColor !important;
  }
}
